import { Injectable, ViewContainerRef } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';

@Injectable()
export class DialogService {

  constructor(private dialog: MatDialog) { }

  public confirm(title: string, message: SafeHtml, viewContainerRef: ViewContainerRef, confirmBtnLabel = 'OK'): Observable<boolean> {
    let dialogRef: MatDialogRef<ConfirmDialogComponent>;
    const config = new MatDialogConfig();
    config.viewContainerRef = viewContainerRef;

    dialogRef = this.dialog.open(ConfirmDialogComponent, config);

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.confirmBtnLabel = confirmBtnLabel;
    dialogRef.componentInstance.cancelMessage = false;

    return dialogRef.afterClosed();
  }

  public show(title: string, message: SafeHtml, viewContainerRef: ViewContainerRef, confirmBtnLabel = 'OK'): Observable<boolean> {
    let dialogRef: MatDialogRef<ConfirmDialogComponent>;
    const config = new MatDialogConfig();
    config.viewContainerRef = viewContainerRef;

    dialogRef = this.dialog.open(ConfirmDialogComponent, config);

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.confirmBtnLabel = confirmBtnLabel;
    dialogRef.componentInstance.cancelMessage = false;
    dialogRef.componentInstance.showCancelButton = false

    return dialogRef.afterClosed();
  }

  public cancel(title: string, message: SafeHtml, viewContainerRef: ViewContainerRef): Observable<boolean> {
    let dialogRef: MatDialogRef<ConfirmDialogComponent>;
    const config = new MatDialogConfig();
    config.viewContainerRef = viewContainerRef;

    dialogRef = this.dialog.open(ConfirmDialogComponent, config);

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.cancelMessage = true;

    return dialogRef.afterClosed();
  }
}
