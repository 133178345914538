import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Session } from '../models/session';
import { GetSessionData } from '../models/lambda-functions/get-session-data';
import { SessionResults } from '../models/session-results';

@Injectable()
export class SessionService {
  constructor (private apiService: ApiService) {}

  getSessionDataForThing(clientId: string): Promise<SessionResults> {
    const getSessionData = new GetSessionData({
      clientId: clientId,
      limit: 1001
    });
    return this.apiService.invokeAsPromise(getSessionData)
      .then(response => response.body);
  }

  async getNextSessionDataForThing(clientId: string, lastEvaluatedKey: string): Promise<SessionResults> {
    const getSessionData = new GetSessionData({
      clientId: clientId,
      limit: 1001,
      lastEvaluatedKey: lastEvaluatedKey
    });
    //console.log("lastEvaluatedKey="+JSON.stringify(lastEvaluatedKey));
    return this.apiService.invokeAsPromise(getSessionData)
      .then(response => response.body);
  }
}
