import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth-guard.service';
import { RoleGuard } from './services/role-guard.service';
import { RouteMap } from './route-map';
import { LoginComponent } from './views/login/login.component';
import { DevicesComponent } from './views/devices/devices.component';
import { UsersComponent } from './views/users/users.component';
import { SignupComponent } from './views/signup/signup.component';
import { PendingApprovalComponent } from './views/pending-approval/pending-approval.component';
import { UserComponent } from './views/user/user.component';
import { FirmwareComponent } from './views/firmware/firmware.component';
import { LogoutComponent } from './components/logout/logout.component';
import { EventsComponent } from './views/events/events.component';
import { DeviceComponent } from './views/device/device.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { AdminComponent } from './views/admin/admin.component';
import { SSOCallbackComponent } from './components/sso-callback/sso-callback.component';
import { SSOAuthingCallbackComponent } from './components/sso-callback/sso-authing-callback.component';

const routes: Routes = [
  { path: '', redirectTo: RouteMap.login.relative, pathMatch: 'full' },
  { path: RouteMap.login.relative, component: LoginComponent },
  { path: RouteMap.devices.relative, component: DevicesComponent, canActivate: [AuthGuard] },
  { path: RouteMap.users.relative, component: UsersComponent, canActivate: [AuthGuard] },
  { path: RouteMap.logout.relative, component: LogoutComponent },
  { path: RouteMap.pendingApproval.relative, component: PendingApprovalComponent },
  { path: RouteMap.signup.relative, component: SignupComponent },
  { path: RouteMap.user.relative, component: UserComponent, canActivate: [AuthGuard] },
  { path: RouteMap.firmware.relative, component: FirmwareComponent,
    canActivate: [AuthGuard, RoleGuard], data: { requiredRoles: ['DEVELOPER'] } },
  { path: RouteMap.events.relative, component: EventsComponent },
  { path: RouteMap.eventsForClient.relative, component: EventsComponent },
  { path: RouteMap.device.relative, component: DeviceComponent },
  { path: RouteMap.resetPassword.relative, component: ResetPasswordComponent },
  { path: RouteMap.admin.relative, component: AdminComponent, canActivate: [AuthGuard, RoleGuard], data: { requiredRoles: ['DEVELOPER'] } },
  { path: RouteMap.ssoAuthing.relative, component: SSOAuthingCallbackComponent },
  { path: RouteMap.sso.relative, component: SSOCallbackComponent }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
