import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatSerial'
})
export class FormatSerialPipe implements PipeTransform {

  transform(serial: string): string {
    return serial.split('-')[2];
  }

}
