import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class NotificationService {
  constructor(private snackBar: MatSnackBar) { }

  show(message: string, action = 'OK') {
    this.snackBar.open(message, action, {
      duration: 30000  // 3000
    });
  }

  /**
   * Add a message to a viewable notification list.
   */
  private addToNotificationList(message: string) {

  }

}
