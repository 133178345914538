import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ApiHttpService {
    constructor(
        private http:HttpClient
    ){

    }
    public async get(url: string, options?: any) {
        const ret = this.http.get(url, options).toPromise();
        return ret;
    }

    public post(url: string, data: any, options?: any) {
        return this.http.post(url, data, options).toPromise();
    }

    public put(url: string, data: any, options?: any) {
        return this.http.post(url, options).toPromise();
    }

    public delete(url: string, options?: any) {
        return this.http.delete(url, options).toPromise();
    }
}