import {Component, Input, OnChanges, OnInit, ViewEncapsulation} from '@angular/core';
import {Device} from '../../models/device';
//import {MatTableDataSource} from '@angular/material';
import {Event} from '../../models/event';
import {EventService} from '../../services/event.service';
import { AlertService} from '../../services/alert.service';
import { NotificationService} from '../../services/notification.service';
import { DatePipe } from '@angular/common';
import { Article } from '../../models/article';
import { AlertsApiService } from '../../core/services/endpoints/v3/alerts-endpoints.service';
import {MatTableDataSource} from '@angular/material/table/';

export interface AlertTableRow {
  id: string;
  timestamp: string;
  state: string;
  severity: string;
  message: string;
  articleId: number;
  articleUrl: string;
}

@Component({
  selector: 'app-alerts-table',
  templateUrl: './alerts-table.component.html',
  styleUrls: ['./alerts-table.component.css'],
  //encapsulation: ViewEncapsulation.None
})
export class AlertsTableComponent implements OnInit, OnChanges {
  @Input() device: Device;
  dataSource: MatTableDataSource<AlertTableRow> = new MatTableDataSource([]);
  // tslint:disable-next-line:max-line-length
  displayedColumns: string[] = ['timestamp', 'id', 'severity', 'title', 'msgPublic', 'msgPrivate', 'state', 'laction', 'raction', 'suppress', 'article'];
  query = '';
  alertIds = [];
  tempDataHolder = [];
  tempDataHolderPostTransform = [];
  eventsHolder = [];
  isLoadingEvents = false;
  suppressionDurations = [{display: '1 Day', value: 86400},
    {display: '7 Days', value: 604800},
    {display: '14 Days', value: 1209600},
    {display: 'Forever', value: -1}];
  suppressionDurationsSuppressed = [{display: 'Clear Suppression', value: 0}];

  constructor(private eventService: EventService,
              private alertService: AlertService,
              private alertsApiService: AlertsApiService,
              private notificationService: NotificationService,
              private datePipe: DatePipe) {}

  async ngOnInit() {
    try {
      this.dataSource.data = [];
      if (this.device && this.device.deviceType) {
        const alerts = await this.alertsApiService.getAlerts(this.device.clientId);
        const currentAlerts = [];
        for (const currentAlert of alerts) {
          const alertData = this.device.shadow.alerts[currentAlert.eventId];
          const eventInstance = await this.eventService.loadEvent(currentAlert.eventId, alertData);
          const articles = [];
          let alertBody = {
            id: currentAlert.eventId,
            timestamp: currentAlert.incidentTimestamp,
            state: currentAlert.status.meta[this.device.deviceType.toLowerCase()].raw.state,
            severity: currentAlert.severity,
            msgPrivate: eventInstance.msgPrivate ? eventInstance.msgPrivate : currentAlert.message,
            msgPublic: eventInstance.msgPublic ? eventInstance.msgPublic : currentAlert.message,
            title: currentAlert.title,
            articles: articles,
            actions: currentAlert.status.actions.types,
            allowSuppress: eventInstance.allowSuppress
          };
          currentAlerts.push(alertBody);
        }
        this.dataSource.data = currentAlerts;
      }

    } catch(err) {
      console.error(err)
    }
  }

  clearEvents() {
    this.eventsHolder = [];
    this.dataSource.data = [];
    this.tempDataHolder = [];
    this.tempDataHolderPostTransform = [];
  }

  async ngOnChanges() {
    // if (this.device && this.device.deviceType) {
    //   console.log('On Dvice Change');
    //   const shadowAlerts = this.device.shadow.alerts;
    //   const alertKeys = Object.keys( shadowAlerts || {});
    //   this.clearAlertIds();
    //   this.clearEvents();
    //   for (const alertId of alertKeys) {
    //     this.alertIds.push(alertId);
    //     const alertData = this.device.shadow.alerts[alertId];
    //     this.tempDataHolder.push({
    //       id: alertId,
    //       timestamp: alertData.timestamp,
    //       state: alertData.state,
    //       severity: null,
    //       message: null,
    //       articleId: null,
    //       articleUrl: null});
    //   }
    //   this.buildQuery(this.alertIds);
    //   await this.getEvents();
    //   for (const temp of this.tempDataHolder) {
    //     for (const ev of this.eventsHolder) {
    //       if (Number(temp.id) === ev.eventId) {
    //         temp.severity = ev.level;
    //         temp.message = ev.message;
    //         temp.articleId = ev.articleId;
    //         temp.articleUrl = ev.url;
    //         break;
    //       }
    //     }
    //   }
    //   for (const temp of this.tempDataHolder) {
    //     if (temp.severity !== null) {
    //       this.tempDataHolderPostTransform.push(temp);
    //     } else {
    //       temp.severity = 'N/A';
    //       temp.message = 'Not Available In Database';
    //       temp.articleId = 'N/A';
    //       temp.articleUrl = '#';
    //       this.tempDataHolderPostTransform.push(temp);
    //     }
    //   }
    // }
    // console.log(this.tempDataHolderPostTransform);
    // this.dataSource.data = this.tempDataHolderPostTransform;
    // console.log(this.dataSource.data);

  }
  async ngAfterViewInit() {
    this.dataSource.data = this.tempDataHolderPostTransform;
  }
  clearSourceData() {
    this.dataSource.data = [];
    this.tempDataHolderPostTransform = [];
  }

  clearAlertIds() {
    this.alertIds = [];
  }


  async acknowledgeClick(alertId, type: string) {
    console.log(`Clicked to ack ${alertId} type ${type}`);
    if(type == 'remote') {
      const response = await this.alertService.remoteAck(this.device.clientId, alertId);
    } else if(type == 'local') {
      const response = await this.alertService.localAck(this.device.clientId, alertId);
    } else {
      throw new Error(`Unrecognized alert acknowledge type: ${type}`);
    }
    this.notificationService.show(`Alert ${alertId} was acknowledged`);
  }
  async suppressClick(alertId, duration) {
    const response = await this.alertService.suppressAlert(this.device.clientId, alertId, duration);
    if (duration > 0) {
      this.notificationService.show(`Alert ${alertId} was suppressed for ${this.secondsToDhms(duration)}`);
    } else if (duration < 0) {
      this.notificationService.show(`Alert ${alertId} was suppressed forever`);
    } else {
      this.notificationService.show(`Alert ${alertId} was unsuppressed`);
    }
  }

  secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    var dDisplay = d > 0 ? d + (d == 1 ? ' day' : ' days') : '';
    return dDisplay;
  }

}
