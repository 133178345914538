import { LambdaFunction } from '../lambda-function';
import { environment } from '../../../environments/environment';


export class SupAlert implements LambdaFunction {
  functionName: string;
  payload: object;

  constructor(payload: object) {
    this.functionName = `fbgpg_alerts_v1_suppress_alert_${environment.stage}`;
    this.payload = payload;
  }
}
