import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { ApiService } from '../../services/api.service';
import { Subject } from 'rxjs';


import { User } from '../../models/user';
import { Device } from '../../models/device';
import { PubSub } from 'aws-amplify';
import { Shadow } from '../../models/shadow';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit, OnDestroy {
  ngUnsubscribe: Subject<void> = new Subject<void>();
  user: User;
  isLoadingUser = false;
  userNotFound = false; // added just to fix --prod build error
  activeDeviceId: string;
  activeDevice: Device;
  shadow: Shadow;
  activePubSub: any;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private route: ActivatedRoute) { }

  private loadUserDetails() {
    this.isLoadingUser = true;

    const clientId = this.route.snapshot.queryParams.clientId;
    const federatedIdentity = this.route.snapshot.params.federatedIdentity;

    this.userService.getUser(federatedIdentity)
      .then(user => {
        this.user = user;
        this.activateDevice(clientId);
        this.isLoadingUser = false;
    });
  }

  activateDevice(deviceId) {
    if (this.activeDeviceId !== deviceId) {
      if (this.activePubSub) {
        this.activePubSub.unsubscribe();
      }
      this.activePubSub = PubSub.subscribe(this.awsTopic(deviceId, 'get/accepted')).subscribe({
        next: data => {
          this.shadow = data.value.state.reported;
          this.activeDevice.shadow = data.value.state.reported;
        },
        error: error => console.log(error),
        complete: () => console.log('Unsubscribed Successfully')
      });
      this.getDeviceShadow(deviceId);
      this.activeDeviceId = deviceId;
      this.activeDevice = this.deviceForUser(deviceId);
    }
  }

  deviceForUser(deviceId) {
    return this.user.devices.find(device => {
      return device.clientId === deviceId;
    });
  }

  getDeviceShadow(deviceId) {
    setTimeout(() => {
      PubSub.publish(this.awsTopic(deviceId, 'get'), {});
    }, 2000);
  }

  awsTopic(deviceId, topic): string {
    return `$aws/things/${deviceId}/shadow/${topic}`;
  }

  getRawDeviceData(device) {
    const mergedData = {
      deviceClientId: device.clientId,
      ...device.shadow
    };
    return Object.keys(mergedData).map(key => {
      return {
        key: key,
        value: mergedData[key]
      };
    });
  }

  ngOnInit() {
    this.loadUserDetails();
  }

  ngOnDestroy() {
    if (this.activePubSub) {
      this.activePubSub.unsubscribe();
    }
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
