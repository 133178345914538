import { LambdaFunction } from '../lambda-function';
import { environment } from '../../../environments/environment';

export class DashboardEvents implements LambdaFunction {
    functionName: string;
    payload: object;

    insertEvents(payload: object) {
        this.payload = payload;
        this.functionName = `fbgpg_common_putEvents_${environment.stage}`;
        return this;
    }

    insertArticle(payload: object) {
        this.payload = payload;
        this.functionName = `fbgpg_common_putArticles_${environment.stage}`;
        return this;
    }

    getArticlesByEventId(payload: object) {
        this.payload = payload;
        this.functionName = `fbgpg_common_getArticlesByEventId_${environment.stage}`;
        return this;
    }

    deleteArticle(payload: object) {
        this.payload = payload;
        this.functionName = `fbgpg_common_deleteArticle_${environment.stage}`;
        return this;
    }

    updateArticle(payload: object) {
        this.payload = payload;
        this.functionName = `fbgpg_common_updateArticle_${environment.stage}`;
        return this;
    }

    linkArticle(payload: object) {
        this.payload = payload;
        this.functionName = `fbgpg_common_linkArticleToEvent_${environment.stage}`;
        return this;
    }
    unlinkArticle(payload: object) {
        this.payload = payload;
        this.functionName = `fbgpg_common_unlinkArticleToEvent_${environment.stage}`;
        return this;
    }

    getEventLogs(payload: object) {
        this.payload = payload;
        this.functionName = 'fbgpg_logs_v1_get_logs';
        return this;
    }

    getEventLogsBySeverities(payload: object) {
        this.payload = payload;
        this.functionName = 'fbgpg_logs_v1_get_logs';
        return this;
    }

    getEventLogsById(payload: object) {
        this.payload = payload;
        this.functionName = 'fbgpg_logs_v1_get_logs';
        return this;
    }

    getLocalePackage(payload: object) {
        this.payload = payload;
        this.functionName = 'fbgpg_common_getLocalePackage';
        return this;
    }
}
