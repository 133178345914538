import { Component, Input, SimpleChanges, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { Device } from '../../models/device';
import { SessionServiceV2 } from '../../services/session-v2.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ApiService } from '../../services/api.service';
import { DeleteSessionData } from '../../models/lambda-functions/delete-session';
import { NotificationService } from '../../services/notification.service';
import { NabSession } from '../../models/nab-session';
import { ClientStorageKey, ClientStorageService, ClientStorageValueType } from '../../services/client-storage.service';
import { DatePipe } from '@angular/common';

import * as columnify from 'columnify';
import {NabSessionResults} from '../../models/nab-session-results';
import { saveAs } from 'file-saver'

interface DialogData {
  clientId: string;
  confirm: boolean;
  message: any; // added just to fix --prod build error
}
interface SessionData {

  timestamp: any;
  levelMin: number;
  levelMax: number;
  fillRate: number;
  fillDurS: number;
  emptyRate: number;
  emptyDurMs: number;
  bckEmptyRate: number;
  bckRunDurMs: number;
  cyclesPerHour: number;
  runtimePerHour: number;
  status: string;
  bckStatus: string;
  backupStart: number;
  draw1Avg: number;
  draw1P2P: number;
  draw2Avg: number;
  draw2P2P: number;
  drawTotalP2P: number;
  drawTotalAvg: number;
  bckRunStartMs:number;
  sortKey: string;

}
@Component({
  selector: 'app-nab-session-table',
  styleUrls: ['./nab-session-table.component.css'],
  templateUrl: './nab-session-table.component.html',
  encapsulation: ViewEncapsulation.None
})
export class NabSessionTableComponent {
  private paginator: MatPaginator;
  private sort: MatSort;
  @Input() device: Device;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  displayedColumns = [
    'date',
    'levelMin',
    'levelMax',
    'fillRate',
    'fillDurS',
    'emptyRate',
    'emptyDurMs',
    'cyclesPerHour',
    'runtimePerHour',
    'status',
    'bckStatus',
    'bckEmptyRate',
    'backupStart',
    'bckRunDurMs',
    'draw1Avg',
    'draw1P2P',
    'draw2Avg',
    'draw2P2P',
    'drawTotalP2P',
    'drawTotalAvg'
  ];

  dataSource = new MatTableDataSource<SessionData>([]);
  isLoadingSession = false;
  useCelsius = false;
  lastEvaluatedKey = null;

  constructor (private apiService: ApiService,
               private sessionService: SessionServiceV2,
               private notificationService: NotificationService,
               private datePipe: DatePipe,
               private dialog: MatDialog) {}

  ngOnInit() {
    const storedTempPref = ClientStorageService.get(ClientStorageKey.PreferCelsius, ClientStorageValueType.String);
    if (storedTempPref && storedTempPref === 'true') {
      this.useCelsius = true;
    } else if (storedTempPref && storedTempPref === 'false') {
      this.useCelsius = false;
    } else {
      ClientStorageService.put(ClientStorageKey.PreferCelsius, 'false');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.device.currentValue) {
      this.getNabSessionData();
    }
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public getNabSessionData() {
    this.isLoadingSession = true;
    this.sessionService.getSessionDataForThing<NabSessionResults>(this.device.clientId)
      .then(sessions => {
        this.dataSource.data = sessions.Items;
        if ( sessions.LastEvaluatedKey ) {
          this.lastEvaluatedKey = sessions.LastEvaluatedKey;
        } else {
          this.lastEvaluatedKey = null;
        }
        this.isLoadingSession = false;
      })
      .catch(error => {
        console.error(error);
      });
  }

  changePage(event) {
    if (event.pageIndex * event.pageSize >= event.length - event.pageSize) {
      if (this.lastEvaluatedKey !== null) {
          this.getNextSessionData();
      }  else {
        // console.log("nothing to get");
      }
    }
  }

  getNextSessionData() {

    this.isLoadingSession = true;

    this.sessionService.getNextSessionDataForThing<NabSessionResults>(this.device.clientId, this.lastEvaluatedKey)
      .then(sessions => {
        for (const item of sessions.Items) {
          this.dataSource.data.push({
            timestamp: item.timestamp,
            levelMin: item.levelMin,
            levelMax: item.levelMax,
            fillRate: item.fillRate,
            fillDurS: item.fillDurS,
            emptyRate: item.emptyRate,
            emptyDurMs: item.emptyDurMs,
            bckEmptyRate: item.bckEmptyRate,
            bckRunDurMs: item.bckRunDurMs,
            cyclesPerHour: item.cyclesPerHour,
            runtimePerHour: item.runtimePerHour,
            status: item.status,
            bckStatus: item.bckStatus,
            backupStart: item.backupStart,
            draw1Avg: item.draw1Avg,
            draw1P2P: item.draw1P2P,
            draw2Avg: item.draw2Avg,
            draw2P2P: item.draw2P2P,
            drawTotalP2P: item.drawTotalP2P,
            drawTotalAvg: item.drawTotalAvg,
            bckRunStartMs: item.bckRunStartMs,
            sortKey: item.sortKey
          });
        }

        if ( sessions.LastEvaluatedKey ) {
          this.lastEvaluatedKey = sessions.LastEvaluatedKey;
        } else {
          this.lastEvaluatedKey = null;
        }

        this.isLoadingSession = false;
      })
      .catch(error => {
        console.error(error);
      });

  }

  public dumpNabSessionData() {
    const text = this.dataSource.data
      .sort((a, b) => {
        return this.compare(new Date(a.timestamp), new Date(b.timestamp), true);
      })
      .map(session => {
        session.timestamp = this.datePipe.transform(session.timestamp, 'medium');

        //Reorder columns to match displayedColumns so the text file 'looks right'. DT-248
        return {
          date: session.timestamp,
          levelMin: session.levelMin,
          levelMax: session.levelMax,
          fillRate: session.fillRate,
          fillDurS: session.fillDurS,
          emptyRate: session.emptyRate,
          emptyDurMs: session.emptyDurMs,
          cyclesPerHour: session.cyclesPerHour,
          runtimePerHour: session.runtimePerHour,
          status: session.status,
          bckStatus: session.bckStatus,
          bckEmptyRate: session.bckEmptyRate,
          backupStart: session.backupStart,
          bckRunDurMs: session.bckRunDurMs,
          draw1Avg: session.draw1Avg,
          draw1P2P: session.draw1P2P,
          draw2Avg: session.draw2Avg,
          draw2P2P: session.draw2P2P,
          drawTotalP2P: session.drawTotalP2P,
          drawTotalAvg: session.drawTotalAvg
        };
      });
    console.log(text);
    let columns = columnify(text, {
      columnSplitter: ' '
    });
    const platform = window.navigator.platform;
    const windows = ['Win32', 'Win64', 'Windows', 'WinCE'];
    if (windows.includes(platform)) {
      columns = columns.replace(/\n/g, '\r\n');
    }
    const blob = new Blob([columns], {type: 'text/plain;carset=utf-8'});
    saveAs(blob, `session_${this.device.clientId}.txt`);
  }

  public sortData = (sort: Sort) => {
    const data = this.dataSource.data;
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'date': return this.compare(new Date(a.timestamp), new Date(b.timestamp), isAsc);
        case 'bckEmptyRate': return this.compare(a.bckEmptyRate, b.bckEmptyRate, isAsc);
        case 'bckRunDurMs': return this.compare(a.bckRunDurMs, b.bckRunDurMs, isAsc);
        case 'emptyDurMs': return this.compare(a.emptyDurMs, b.emptyDurMs, isAsc);
        case 'emptyRate': return this.compare(a.emptyRate, b.emptyRate, isAsc);
        case 'fillDurS': return this.compare(a.fillDurS, b.fillDurS, isAsc);
        case 'fillRate': return this.compare(a.fillRate, b.fillRate, isAsc);
        case 'levelMax': return this.compare(a.levelMax, b.levelMax, isAsc);
        case 'levelMin': return this.compare(a.levelMin, b.levelMin, isAsc);
      }
    });
  }


  private compare = (a, b, isAsc) => {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  private openDialog() {
    const dialog = this.dialog.open(NabSessionConfirmationDialog, {
      width: '500px',
      data: { clientId: this.device.clientId, confirm: false }
    });

    dialog.afterClosed().subscribe(result => {
      if (result) { this.deleteSessionData(); }
    });
  }

  private deleteSessionData() {
    const deleteSessionData = new DeleteSessionData({
      clientId: this.device.clientId
    });

    this.apiService.invokeAsPromise(deleteSessionData)
      .then(() => {
        this.dataSource.data = [];
        this.notificationService.show('Device session data removed successfully');
      })
      .catch((error) => {
        console.error(error);
        this.notificationService.show('There was a problem deleting the session data for this device');
      });
  }

  private roundTemperature(input) {
    return Math.round(input * 10) / 10 ;
  }

  private formatNumber(input) {
     let output = input.toString();
     if ( output.indexOf('.') <= 0) {
       output = output + '.0';
     }
     return output;
  }


  roundMsDuration(input) {

    let seconds = input / 1000;
    if (input === undefined) {
      seconds = 0.0;
   }
    return Math.round(seconds * 10) / 10;
  }
}

@Component({
  selector: 'confirmation-dialog',
  templateUrl: 'confirmation-dialog.html'
})
export class NabSessionConfirmationDialog {
  constructor(
    public dialogRef: MatDialogRef<NabSessionConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick() {
    this.dialogRef.close();
  }
}
