import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { AccountsApiService, CognitoAccount } from '../../core/services/endpoints/v1/accounts-endpoints.service'
import { Device } from '../../models/device';
import { RouteMap } from '../../route-map';
import { DeviceService } from '../../services/device.service';
import { FilterPipe } from '../../pipes/filter.pipe';
import { FormatSerialPipe } from '../../pipes/format-serial.pipe';
import { GroupService } from '../../services/group.service';
import { AuthService } from '../../services/auth.service';
import { NotificationService } from '../../services/notification.service';
import { Router } from '@angular/router';
import { UserService, UserConfirmationDialog} from '../../services/user.service';

@Component({
  selector: 'app-devices-table',
  templateUrl: './devices-table.component.html',
  styleUrls: ['./devices-table.component.css']
})
export class DevicesTableComponent implements OnInit, AfterViewInit {
  private paginator: MatPaginator;
  private sort: MatSort;
  @Input() dataSource: MatTableDataSource<Device>;
  @Input() displayedColumns: string[];
  @Input() selectedDevices: string[] = [];
  @Input() thingName: string = '';
  @Output() selectedDevicesChange = new EventEmitter<string[]>();
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatSort) set MatSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }
  RouteMap = RouteMap;
  isLoadingDevices = false;
  deviceNotFound = false;
  deviceDoesNotExist = false;
  emailNotFound = false;
  emailFoundNoDevices = false;
  emailFoundInCongitoOnly = false;
  cognitoUser: CognitoAccount;
  groupNoDevices = false;
  filterPipe = new FilterPipe();
  formatSerialPipe = new FormatSerialPipe();
  devices: Device[] = [];
  searchType = '';
  email = '';
  isTier2Support = false;
  isTier1Support = false;

  get renderedDevices() {
    return this.dataSource.data.filter(device => {
      return this.dataSource.filterPredicate(device, this.dataSource.filter);
    });
  }

  constructor(
    private deviceService: DeviceService,
    private groupService: GroupService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private notificationService: NotificationService,
    private accountsApi: AccountsApiService
  ) {
    this.sortingDataAccessor.bind(this.formatSerialPipe);
  }

  async ngOnInit() {
   // this.isTier2Support = await this.authService.hasRole(['TIER_2_SUPPORT']);
   this.isTier2Support = await this.isTier2SupportRole();
   this.isTier1Support = await this.isTier1SupportRole();

    this.dataSource.data = [];
    this.dataSource.filterPredicate = (data: Device, filter: string) => {
      return !!this.filterPipe.transform([data], filter).length;
    };
    this.dataSource.sortingDataAccessor = (device, property) => {
      return this.sortingDataAccessor(device, property, this.formatSerialPipe);
    };
  }

  // ngAfterViewChecked() {
  //   const paginatorElement = document.getElementsByClassName('mat-paginator');
  //   if (this.dataSource.data && this.dataSource.data.length > 0
  //     && paginatorElement.length != 0 && (!this.dataSource.paginator || this.dataSource.paginator.getNumberOfPages() <= 0)) {
  //     paginatorElement[0].innerHTML = '';
  //   }
  // }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async getDeviceBySerialNumber(clientId: string) {
    if (!clientId) {
      this.notificationService.show('Serial Number cannot be blank!');
      return;
    }
    this.isLoadingDevices = true;
    this.emailNotFound = false;
    this.emailFoundNoDevices = false;
    this.deviceNotFound = false;
    this.deviceDoesNotExist = false;
    this.emailFoundInCongitoOnly = false;
    this.searchType = 'serialNumber';
    try {
      const device = await this.deviceService.getDeviceByClientId(clientId);
      if (device.clientId) {
        this.router.navigate([RouteMap.device.absolute], {queryParams: {clientId: device.clientId, deviceType: device.deviceType}});
      } else {
        const exists : boolean = await this.deviceService.isDeviceManufactured(clientId);

        if(exists) {
          this.deviceNotFound = true;
        } else {
          this.deviceDoesNotExist = true;
        }

        this.dataSource.data = [];
        this.isLoadingDevices = false;
      }
    } catch(error) {
      this.dataSource.data = [];
      this.isLoadingDevices = false;
      this.notificationService.show(error.message);
    }
  }

  async getDevicesByEmail(email: string) {
    if (!email) {
      this.notificationService.show('Email cannot be blank!');
      return;
    }
    this.email = email;
    this.isLoadingDevices = true;
    this.deviceNotFound = false;
    this.deviceDoesNotExist = false;
    this.groupNoDevices = false;
    this.emailNotFound = false;
    this.emailFoundNoDevices = false;
    this.emailFoundInCongitoOnly = false;
    this.searchType = 'email';

    try {
      const user = await this.userService.getUser(email);
      if (!user.federatedIdentity) {
        this.dataSource.data = [];
        this.cognitoUser = await this.accountsApi.getUserByEmail(email);
        console.debug('cogUser', this.cognitoUser);
        if(this.cognitoUser) {
          this.emailFoundInCongitoOnly = true;
        } else {
          this.emailNotFound = true;
        }
        this.isLoadingDevices = false;
      } else if (user.devices.length === 0) {
        this.dataSource.data = [];
        this.isLoadingDevices = false;
        this.emailFoundNoDevices = true;
      }
      this.isLoadingDevices = false;
      this.dataSource.data = user.devices;
    } catch (error) {
      this.dataSource.data = [];
      this.isLoadingDevices = false;
      this.notificationService.show(error.message);
    }
  }

  getDevicesByGroup(type: string, group: string) {
    this.isLoadingDevices = true;
    this.deviceNotFound = false;
    this.deviceDoesNotExist = false;
    this.groupNoDevices = false;
    this.emailNotFound = false;
    this.emailFoundNoDevices = false;
    this.emailFoundInCongitoOnly = false;
    this.searchType = 'group';
    this.deviceService.listDevicesByGroup([], type, group)
    .then(devices => {
      this.devices = devices;
      this.isLoadingDevices = false;
      console.log(`Length of device list returned: ${this.devices.length}`);
      console.log(JSON.stringify(this.devices));
      if (this.devices.length === 0) {
        this.groupNoDevices = true;
      }
      console.log(devices);
      this.dataSource.data = devices;
    })
    .catch(error => {
      this.dataSource.data = [];
      this.isLoadingDevices = false;
      this.notificationService.show(error.message);
    })
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(query): void {
    this.dataSource.filter = query.trim().toLowerCase();

    this.selectedDevices = this.selectedDevices.filter(clientId => {
      return this.dataSource.data.find(device => {
        return device.clientId === clientId;
      });
    });
    this.selectedDevicesChange.emit(this.selectedDevices);
  }

  selectDevice(clientId: string): void {
    if (this.selectedDevices.includes(clientId)) {
      const index = this.selectedDevices.indexOf(clientId);
      this.selectedDevices.splice(index, 1);
    } else {
      this.selectedDevices.push(clientId);
    }
  }

  setSelectedDevices(): void {
    if (this.selectedDevices.length === this.renderedDevices.length) {
      this.selectedDevices = [];
    } else {
      this.selectedDevices = this.renderedDevices.map(device => device.clientId);
    }
    this.selectedDevicesChange.emit(this.selectedDevices);
  }

  sortingDataAccessor(device, property: string, formatSerialPipe: FormatSerialPipe): string {
    switch (property) {
      case 'clientId':
        return formatSerialPipe.transform(device.clientId);
      case 'username':
        return device.username;
      case 'firmware_version':
        return device.firmwareVersion;
      default:
        return device[property];
    }
  }

  sortData(sort: Sort) {
    if (!sort.active) {
      return;
    }

    const tempData = this.dataSource.data;
    const isAsc = sort.direction === 'asc';
    this.dataSource.data = tempData.sort((a, b) => {
      return this.safeSort(sort.active, a, b, isAsc);
    });
  }

  safeSort(column: string, a: any, b: any, isAsc: boolean) {
    if (a && b) {
      if (column === 'clientId' && a.clientId && b.clientId) {
        return this.compare(a.clientId, b.clientId, isAsc);
      } else if (column === 'deviceType' && a.deviceType && b.deviceType) {
        return this.compare(a.deviceType, b.deviceType, isAsc);
      } else if (column === 'username' && a.username && b.username) {
        return this.compare(a.username, b.username, isAsc);
      } else if (column === 'name' && a.nickname && b.nickname) {
        return this.compare(a.nickname, b.nickname, isAsc);
      } else if (column === 'location' && a.location && a.location.nickname && b.location && b.location.nickname) {
        return this.compare(a.location.nickname, b.location.nickname, isAsc);
      } else if (column === 'room' && a.room && a.room.nickname && b.room && b.room.nickname) {
        return this.compare(a.room.nickname, b.room.nickname, isAsc);
      } else if (column === 'firmware_version' && a.firmwareVersion && b.firmwareVersion) {
        return this.compare(a.firmwareVersion, b.firmwareVersion, isAsc);
      } else if (column === 'offline' && (a.connected !== undefined || a.connected !== null)
        && (b.connected !== undefined || b.connected !== null)) {
        return this.compare(a.connected.toString(), b.connected.toString(), isAsc);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  compare(a: number | String, b: number | String, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  openDeleteDialog(email: string) {
    this.userService.openDeleteDialog(email);
  }

  async isDeveloperSupportRole() {
    var isDeveloper = false;
    isDeveloper = await this.authService.hasRole(['DEVELOPER']);
    return isDeveloper;
  }

  async isTier2SupportRole() {
    var isTier2 = false;
    isTier2 = await this.authService.hasRole(['TIER_2_SUPPORT']);
    if (! isTier2 ) {
      isTier2 = await this.isDeveloperSupportRole();
    }
    return isTier2;
  }

  async isTier1SupportRole() {
    var isTier1 = false;

    isTier1 = await this.authService.hasRole(['TIER_1_SUPPORT']);
    if (! isTier1 ) {
      isTier1 = await this.isTier2SupportRole();
    }

    return isTier1;
  }

  /**
   * Deletes user account if no database information is available.
   */
  async deleteUserCognitoAccount(email) {
    try {
      if(this.cognitoUser.status === 'UNCONFIRMED') {
        await this.accountsApi.deleteUnconfirmedCognitoAccount(email);
      } else {
        await this.accountsApi.deleteUserCognitoAccount(email);
      }
      this.notificationService.show('User deleted successfully');
      await this.sleep(1500)
      window.location.reload();
    } catch (error) {
      console.error(error);
      this.notificationService.show(error.message);
    }

  }
  sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }

}
