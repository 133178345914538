export class LambdaError extends Error {
  constructor(name, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, LambdaError);
    }

    this.name = name;
  }
}
