import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
//import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table/'
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { RouteMap } from '../../route-map';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    'firstName',
    'lastName',
    'email',
    'devices',
    'createdAt'
  ];
  dataSource: MatTableDataSource<User> = new MatTableDataSource([]);
  isLoadingUsers = false;
  userNotFound = false;
  RouteMap = RouteMap;
  username: string = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private userService: UserService) {
  }

  keyUp = (event) => {
    if (event.keyCode === 13) {
      this.getUsers();
    }
  }

  ngOnInit() {
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getUsers = () => {
    if (this.username === '') {
      return this.dataSource.data = [];
    }
    this.isLoadingUsers = true;
    this.userNotFound = false;
    this.userService.getUser(this.username)
      .then(result => {
        let user = result as User;
        this.isLoadingUsers = false;
        this.dataSource.data = [user];
      })
      .catch(() => {
        this.dataSource.data = [];
        this.userNotFound = true;
        this.isLoadingUsers = false;
      });
  }

  applyFilter(query: string): void {
    this.dataSource.filter = query.trim().toLowerCase();
  }

  sortingDataAccessor(user: User, property: string): string {
    switch (property) {
      case 'firstName':
        if (user.firstName) {
          return user.firstName.toLowerCase();
        }
        return '';
      case 'lastName':
        if (user.lastName) {
          return user.lastName.toLowerCase();
        }
        return '';
      case 'email':
        if (user.username) {
           return user.username.toLowerCase();
        }
        return '';
      default:
        return user[property];
    }
  }
}
