import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Device } from '../models/device';

import { GetDevice } from '../models/lambda-functions/get-device';
import { ListDevices, UpdateDeviceAttributes, GetSkuDetails } from '../models/lambda-functions/list-devices';
import { DeleteDevice } from '../models/lambda-functions/delete-device';

import { ApiService } from './api.service';
import { UserService } from './user.service';

@Injectable()
export class DeviceService {

  constructor(
    private apiService: ApiService,
    private userService: UserService
  ) { }

  get deviceTypes() {
    return ['VAK', 'UBM', 'RAP', 'NAB'];
  }

  async getDeviceByClientId(clientId: string): Promise<Device> {

    const getDevice = new GetDevice({
      //deviceType: type,
      clientId: clientId
    });
    const response = await this.apiService.invokeAsPromise(getDevice);
    if (response.statusCode !== 200) {
      console.log('Function: getDeviceByClientID');
      console.log(`Status Code:  ${response.statusCode}`);
      console.log(`Response: ${response.body}`);
      return response.body;
    }
    const device = response.body;
    const user = await this.userService.getUser(device.username, device.federatedIdentity);
    device.shadow = device.shadow.state.reported;
    device.user = user;
    device.details = user.devices.find(item => item.clientId == device.clientId);
    return device;
  }

  async listDevicesByFirmware(devices: Device[], type: string, version: string, condition: string = '=', limit: string): Promise<Device[]> {
    const listDevices = new ListDevices({
      deviceType: type,
      firmwareVersion: version,
      condition: condition,
      limit: limit
    });
    const response = await this.apiService.invokeAsPromise(listDevices);
    console.log(response);
    devices = devices.concat(response.body.Items);
    const lastEvaluatedKey = response.body.lastEvaluatedKey;
    if (!lastEvaluatedKey) { return devices; }
    return this.listDevicesByFirmware(devices, type, version, condition, limit);
  }

  async listDevicesByGroup(devices: Device[], type: string, group: string): Promise<Device[]> {
    const listDevices = new ListDevices({
      deviceType: type,
      groupId: group
    });
    const response = await this.apiService.invokeAsPromise(listDevices);
    devices = devices.concat(response.body.Items);

    const lastEvaluatedKey = response.body.lastEvaluatedKey;
    if (!lastEvaluatedKey) {
      return devices;
    } else {
      return this.listDevicesByGroup(devices, type, group);
    }
  }

  async deleteDevice(clientId: string) {
    const deleteDevice = new DeleteDevice({
      clientId: clientId
    });
    return await this.apiService.invokeAsPromise(deleteDevice);
  }

  async updateDeviceAttributes(clientId: string, federatedIdentity: string, attributeType: string, data) {
    const updateDeviceAttributes = new UpdateDeviceAttributes({
        cognitoIdentityId: federatedIdentity,
        pathParameters: {
          duid: clientId,
          attribute: attributeType
        },
        body: {
          data: data
        }
    });
    console.log(updateDeviceAttributes);
    return await this.apiService.invokeAsPromise(updateDeviceAttributes);

  }

  async isDeviceManufactured(clientId: string) {
    const getSkuDetails = new GetSkuDetails({
      serialNumber: clientId
    });
    console.log(getSkuDetails);
    try {
      const val = await this.apiService.invokeAsPromise(getSkuDetails);
      return true;
    } catch(err) {
      console.debug("Sku lookup returned error, indicating the device could not be found.")
      console.debug(JSON.stringify(err));
      return false;
    }
  }
}
