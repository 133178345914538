import { Component, Input, SimpleChanges, Inject, ViewChild } from '@angular/core';
import { Device } from '../../models/device';

//Switch to alert history
import { AlertService } from '../../services/alert.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { saveAs } from 'file-saver'


import { ApiService } from '../../services/api.service';

import { NotificationService } from '../../services/notification.service';

import { Alert } from '../../models/alert';
import { ClientStorageKey, ClientStorageService, ClientStorageValueType } from '../../services/client-storage.service';
import { DatePipe } from '@angular/common';

import * as columnify from 'columnify';

interface DialogData {
  clientId: string;
  confirm: boolean;
}

@Component({
  selector: 'app-alert-history-table',
  styleUrls: ['./alert-history-table.component.css'],
  templateUrl: './alert-history-table.component.html'
})
export class AlertHistoryTableComponent {
  private paginator: MatPaginator;
  private sort: MatSort;
  @Input() device: Device;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  displayedColumnsVAK = [
    'timestamp',
    'eventId',
    'severity',
    'title',
    'msgPublic',
    'msgPrivate',
    'article',
    'state',
    'action',
    'alertAck',
    'alertActive',
    'alertClear'
  ];


  dataSource = new MatTableDataSource<any>([]);
  isLoadingAlert = false; // added just to fix --prod build error
  isLoadingSession = false;
  useCelsius = false;
  lastEvaluatedKey = null;

  constructor (private apiService: ApiService,
               private alertService: AlertService,
               private notificationService: NotificationService,
               private datePipe: DatePipe,
               private dialog: MatDialog) {}

  ngOnInit() {
    const storedTempPref = ClientStorageService.get(ClientStorageKey.PreferCelsius, ClientStorageValueType.String);
    if (storedTempPref && storedTempPref === 'true') {
      this.useCelsius = true;
    } else if (storedTempPref && storedTempPref === 'false') {
      this.useCelsius = false;
    } else {
      ClientStorageService.put(ClientStorageKey.PreferCelsius, 'false');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.device.currentValue) {
      this.getAlertData();
    }
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public getAlertData() {
    this.isLoadingSession = true;
    let x =
    this.alertService.getAlertDataForThing(this.device.clientId)
      .then(alerts => {
        this.dataSource.data = alerts.Items;
        if ( alerts.LastEvaluatedKey ) {
          this.lastEvaluatedKey = alerts.LastEvaluatedKey;
        } else {
          this.lastEvaluatedKey = null;
        }
      //  console.log("lastEvaluatedKey="+JSON.stringify(this.lastEvaluatedKey));
      //  console.log("datasource.size="+this.dataSource.data.length);
        this.isLoadingSession = false;
      })
      .catch(error => {
        console.error(error);
      });
  }

  changePage(event) {
    if (event.pageIndex * event.pageSize >= event.length - event.pageSize) {
      if (this.lastEvaluatedKey !== null) {
          this.getNextSessionData();
      }  else {
        // console.log("nothing to get");
      }
    }
  }

  async getNextSessionData() {

    this.isLoadingSession = true;

    this.alertService.getNextAlertDataForThing(this.device.clientId, this.lastEvaluatedKey)
      .then(alerts => {
        this.dataSource.data = this.dataSource.data.concat(alerts.Items);

        if ( alerts.LastEvaluatedKey ) {
          this.lastEvaluatedKey = alerts.LastEvaluatedKey;
        } else {
          this.lastEvaluatedKey = null;
        }

        this.isLoadingSession = false;
      })
      .catch(error => {
        console.error(error);
      });

  }

  public dumpAlertData() {
    const text = this.dataSource.data
      .sort((a, b) => {
        return this.compare(new Date(a.timestamp), new Date(b.timestamp), true);
      })
      .map(alert => {
        alert.date = this.datePipe.transform(alert.timestamp, 'medium');
        return alert;
      });
    let columns = columnify(text, {
      columnSplitter: '|'
    });
    const platform = window.navigator.platform;
    const windows = ['Win32', 'Win64', 'Windows', 'WinCE'];
    if (windows.includes(platform)) {
      columns = columns.replace(/\n/g, '\r\n');
    }
    const blob = new Blob([columns], {type: 'text/plain;carset=utf-8'});
    saveAs(blob, `alert_${this.device.clientId}.txt`);
  }

  public sortData = (sort: Sort) => {
    const data = this.dataSource.data;
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'date': return this.compare(new Date(a.timestamp), new Date(b.timestamp), isAsc);
        case 'bckEmptyRate': return this.compare(a.bckEmptyRate, b.bckEmptyRate, isAsc);
        case 'bckRunDurMs': return this.compare(a.bckRunDurMs, b.bckRunDurMs, isAsc);
        case 'bckRunMs': return this.compare(a.bckRunMs, b.bckRunMs, isAsc);
        case 'bckStatus': return this.compare(a.bckStatus, b.bckStatus, isAsc);
        case 'draw1Avg': return this.compare(a.draw1Avg, b.draw1Avg, isAsc);
        case 'draw1P2P': return this.compare(a.draw1P2P, b.draw1P2P, isAsc);
        case 'draw2Avg': return this.compare(a.draw2Avg, b.draw2Avg, isAsc);
        case 'draw2P2P': return this.compare(a.draw2P2P, b.draw2P2P, isAsc);
        case 'drawTotalP2P': return this.compare(a.drawTotalP2P, b.drawTotalP2P, isAsc);
        case 'emptyDurMs': return this.compare(a.emptyDurMs, b.emptyDurMs, isAsc);
        case 'emptyRate': return this.compare(a.emptyRate, b.emptyRate, isAsc);
        case 'fillDurS': return this.compare(a.fillDurS, b.fillDurS, isAsc);
        case 'fillRate': return this.compare(a.fillRate, b.fillRate, isAsc);
        case 'levelMax': return this.compare(a.levelMax, b.levelMax, isAsc);
        case 'levelMin': return this.compare(a.levelMin, b.levelMin, isAsc);
      }
    });
  }


  private compare = (a, b, isAsc) => {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  private roundTemperature(input) {
    return Math.round(input * 10) / 10 ;
  }

  private formatNumber(input) {
     let output = input.toString();
     if ( output.indexOf('.') <= 0) {
       output = output + '.0';
     }
     return output;
  }


  roundMsDuration(input) {

    let seconds = input / 1000;
    if (input === undefined) {
      seconds = 0.0;
   }
    return Math.round(seconds * 10) / 10;
  }
  private convertDate(epochTimestamp:number) {
    return new Date(epochTimestamp).toISOString();
  }

  async acknowledgeClick(alertId) {
    console.log(`Clicked to ack ${alertId}`);
    const response = await this.alertService.acknowledgeAlert(this.device.clientId, alertId);
    console.log(response);
    this.notificationService.show(`Alert ${alertId} was acknowledged`);
  }
}
