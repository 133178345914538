import { LambdaFunction } from '../lambda-function';
import { environment } from '../../../environments/environment';
import { Location, LocationResults } from '../../models/location';


class GetLocations implements LambdaFunction {
  functionName: string;
  payload: object;
    constructor(federatedIdentity:string) {
    this.functionName = `smartwater-app-location-api-${environment.stage}-list`;
    this.payload = {cognitoIdentityId:federatedIdentity};
  }
}

class UpdateLocation implements LambdaFunction {
  functionName: string;
  payload: object;
    constructor(federatedIdentity:string, location:Location) {
    this.functionName = `smartwater-app-location-api-${environment.stage}-update`;
    this.payload = location;
    this.payload['cognitoIdentityId'] = federatedIdentity;
  }
}

export class LocationLambdas {
  constructor() {

  }

  public getLocations(federatedIdentity:string) : GetLocations {
    return new GetLocations(federatedIdentity);
  }
  public updateLocation(federatedIdentity:string, location:Location){
    return new UpdateLocation(federatedIdentity, location);
  }
}