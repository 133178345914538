import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { Group } from '../models/group';
import { ListGroups } from '../models/lambda-functions/list-groups';
import { CreateGroup } from '../models/lambda-functions/create-group';
import { AddDeviceToGroup } from '../models/lambda-functions/add-device-group';

interface GroupDevice {
  clientId: string;
  deviceType: string;
}

@Injectable()
export class GroupService {

  constructor(
    private apiService: ApiService
  ) {}

  listGroups(): Promise<Group[]> {
    const getGroups = new ListGroups({});
    return this.apiService.invokeAsPromise(getGroups)
      .then(response => {
        return response.body;
      });
  }

  createGroup(name: string, deviceType: string, description: string) {
    const createGroup = new CreateGroup({
      nickname: name,
      deviceType: deviceType,
      description: description
    });
    return this.apiService.invokeAsPromise(createGroup);
  }

  /**
   * Sets the groupId of a device.
   * @param devices A list of devices to add to the group.
   * @param group The group id of the group the device(s) are set to. An existing group id or null to unset the devices assigned group.
   * @returns 
   */
  setDevicesToGroup(devices: GroupDevice[], group: string) {
    const addDeviceToGroup = new AddDeviceToGroup({
      devices: devices,
      groupId: group
    });
    return this.apiService.invokeAsPromise(addDeviceToGroup);
  }
}
