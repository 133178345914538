import { Injectable } from "@angular/core";
import { ApiEndpointsService, ApiService } from "../../api-endpoints.service";
import { environment } from "../../../../../environments/environment";
import { ApiHttpService } from "../../api-http.service";


const urlStage = {
    'dev': 'yvo63bdubk',
    'qa': '6pmtqm0vsh',
    'prod': '4j1gkf0vji'
};
/**
 * Reference: https://betterprogramming.pub/angular-api-calls-the-right-way-264198bf2c64
 */
@Injectable() 
export class AccountsEndpointsService extends ApiEndpointsService{


    constructor(){
        //super(`https://yvo63bdubk.execute-api.ca-central-1.amazonaws.com/dev`);
        super(`https://${urlStage[environment.stage]}.execute-api.${environment.region}.amazonaws.com/${environment.stage}/v1`);
    }


    /**
     * Serverless function: Ping
     * 
     * @returns 
     */
    public getPing() : string {
        return this.createUrl('ping');
    }

    /**
     * Serverless function: GetUser
     * @returns 
     */
    public getAuthenticatedUser() : string {
        return this.createUrl('users/me');
    }


    /**
     * Serverless function: DeleteDisabledUser
     * @returns The url for the call.
     */
    public deleteDisabledUser(email:string) : string {
        return this.createUrl(`users/disabled/${email}`);
    }

    /**
     * Serverless function: GetUserByEmail
     * @param email 
     * @returns The url for the call.
     */
    public getUserByEmail(email:string) : string {
        return this.createUrl(`users/email/${email}`)
    }

    /**
     * Serverless function: GetAllRoles
     * @returns 
     */
    public getAllRoles() : string {
        return this.createUrl('roles');
    }

    /**
     * Queues user for deletion by disabling the cognito account.
     * Serverless function: 
     * @param email 
     * @returns The url for the call.
     */
    public deleteUnconfirmedCognitoAccount(email:string) : string {
        return this.createUrl(`users/registration/${email}`)
    }
}

@Injectable() 
export class AccountsApiService extends ApiService {

    constructor(
        private accountsEndpointService: AccountsEndpointsService,
        private apiHttpService: ApiHttpService){
        super();
        
    }
    public async getUserByEmail(email: string) : Promise<CognitoAccount> {
        const url = this.accountsEndpointService.getUserByEmail(email);
        const res = this.apiHttpService.get(url, await this.buildAuthHeaders());
        const ares = await res;
        return ares[0];
    }

    /**
     * Deletes the user account from the cognito pool if it's disabled.
     * @param email 
     * @returns 
     */
    public async deleteUserCognitoAccount(email:string) { 
        const url = this.accountsEndpointService.deleteDisabledUser(email);
        const res = await this.apiHttpService.delete(url, await this.buildAuthHeaders());
        return res;
    }

    /**
     * Queue up a the user to be deleted.
     * Disables the users account, which queues up the user to be deleted.
     * @param email 
     * @returns 
     */
    public async deleteUnconfirmedCognitoAccount(email: string) {
        const url = this.accountsEndpointService.deleteUnconfirmedCognitoAccount(email);
        const res = await this.apiHttpService.delete(url, await this.buildAuthHeaders());
        return res;
    }
}



export class CognitoAccount {
    status: string;
    accountStatus: string;
    smsMfaStatus: string;
    lastModified: string;
    created: string;
    sub: string;
    email_verified: string;
    email: string;
    username: string;
}