import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { AuthService } from './auth.service';
import { NotificationService } from './notification.service';
import { RouteMap } from '../route-map';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const requiredRoles = route.data.requiredRoles;
    const hasRole = await this.authService.hasRole(requiredRoles);

    if (hasRole) {
      return true;
    }

    this.router.navigate([RouteMap.devices.absolute]);
    this.notificationService.show('You do not have permission to view this page.');
    return false;
  }
}
