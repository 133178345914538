import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { DashboardEvents } from '../models/lambda-functions/dashboard-events';
import { LanguageConverter } from '../models/languageConverter';

const classifications = {
  deviceEvents: "DeviceEvents",
  articles: "KBArticle"
}

@Injectable()
export class EventService {

  languageConverter: LanguageConverter = new LanguageConverter();
  eventLocalePackage: any[];
  articleLocalePackage: any[];
  localePackagePromise;

  constructor(
    private apiService: ApiService
  ) {
    this.localePackagePromise = this.loadLocalePackages();
  }

  async loadLocalePackages() {
    // console.log('navigator.languages==>', navigator.languages);
    const locale = this.languageConverter.toCloudLocaleTerminology(navigator.languages[0]);
    this.eventLocalePackage = await this.loadLocalePackage(classifications.deviceEvents, locale);
    this.articleLocalePackage = await this.loadLocalePackage(classifications.articles, locale);
  }

  private async loadLocalePackage(classification:string, locale:string) {
    const localePackageKey = `${classification}${locale}`;
    let localePackage;
    if(localStorage[localePackageKey]) {
      //console.log("retrieving");
      //console.log(localePackageKey)
      const packageKey = localStorage[localePackageKey];
      try {
        //console.log(localStorage[packageKey]);
        if(localStorage[packageKey]) {
          localePackage = JSON.parse(localStorage[packageKey]);
        }
      } catch (e) {
        console.error(e);
      }

      const getLocalePackage = new DashboardEvents().getLocalePackage({pathParameters:{classification:classification, locale:locale, currentPackage:localStorage[localePackageKey]}});
      const response = await this.apiService.invokeAsPromise(getLocalePackage);
      //console.log(response);
      const body = JSON.parse(response.body);
      if(body.IsUpdated) {
        //console.log("package updated");
        localStorage[localePackageKey] = body.Key;
        localStorage[body.Key] = JSON.stringify(body.Data);
        localePackage = body.Data;
      }
    } else {
      //console.log("package not found and initialized");
      //console.log(localePackageKey)
      const getLocalePackage = new DashboardEvents().getLocalePackage({pathParameters:{classification:classification, locale:locale}});
      const response = await this.apiService.invokeAsPromise(getLocalePackage);
      const body = JSON.parse(response.body);
      localStorage[localePackageKey] = body.Key;
      localStorage[body.Key] = JSON.stringify(body.Data);
      localePackage = body.Data;
    }
    //console.log("Using locale package with key");
    //console.log(localStorage[localePackageKey]);
    return JSON.parse(localePackage);
  }

  async getEventLogsDevice(model:string=null, lastEvaluatedKeys:any=null, startTime=null, endTime=null) {
    await this.localePackagePromise;
    const getEventLogs = new DashboardEvents().getEventLogs({queryStringParameters: {model: model, startTime:startTime, endTime:endTime}, multiValueQueryStringParameters: {lastEvaluatedKeys:lastEvaluatedKeys}});
    const response = await this.apiService.invokeAsPromise(getEventLogs);
    const body = JSON.parse(response.body);
    return await this.customizeLogResponse(body);
  }


  async getEventLogsDeviceBySeverities(severities, model:string=null, lastEvaluatedKeys:any=null, startTime=null, endTime=null) {
    await this.localePackagePromise;
    const getEventLogsBySeverities = new DashboardEvents().getEventLogsBySeverities({queryStringParameters: {model: model, startTime:startTime, endTime:endTime}, multiValueQueryStringParameters: {severities:severities, lastEvaluatedKeys:lastEvaluatedKeys}});
    const response = await this.apiService.invokeAsPromise(getEventLogsBySeverities);
    const body = JSON.parse(response.body);
    return await this.customizeLogResponse(body);
  }

  async getEventLogsDeviceById(eventId:number, model:string=null, lastEvaluatedKeys:any=null, startTime=null, endTime=null) {
    await this.localePackagePromise;
    const getEventLogsById = new DashboardEvents().getEventLogsById({queryStringParameters: {model: model, eventId:eventId, startTime:startTime, endTime:endTime}, multiValueQueryStringParameters: {lastEvaluatedKeys:lastEvaluatedKeys}});
    const response = await this.apiService.invokeAsPromise(getEventLogsById);
    const body = JSON.parse(response.body);
    return await this.customizeLogResponse(body);
  }

  async getEventLogsDuid(duid:string=null, lastEvaluatedKeys:any=null, startTime=null, endTime=null) {
    await this.localePackagePromise;
    const getEventLogs = new DashboardEvents().getEventLogs({queryStringParameters: {duid: duid, startTime:startTime, endTime:endTime}, multiValueQueryStringParameters: {lastEvaluatedKeys:lastEvaluatedKeys}});
    const response = await this.apiService.invokeAsPromise(getEventLogs);
    const body = JSON.parse(response.body);
    return await this.customizeLogResponse(body);
  }


  async getEventLogsDuidBySeverities(severities, duid:string=null, lastEvaluatedKeys:any=null, startTime=null, endTime=null) {
    await this.localePackagePromise;
    const getEventLogsBySeverities = new DashboardEvents().getEventLogsBySeverities({queryStringParameters: {duid: duid, startTime:startTime, endTime:endTime }, multiValueQueryStringParameters: {severities:severities, lastEvaluatedKeys:lastEvaluatedKeys}});
    const response = await this.apiService.invokeAsPromise(getEventLogsBySeverities);
    const body = JSON.parse(response.body);
    return await this.customizeLogResponse(body);
  }

  async getEventLogsDuidById(eventId:number, duid:string=null, lastEvaluatedKeys:any=null, startTime=null, endTime=null) {
    await this.localePackagePromise;
    const getEventLogsById = new DashboardEvents().getEventLogsById({queryStringParameters: {duid: duid, eventId:eventId, startTime:startTime, endTime:endTime}});
    const response = await this.apiService.invokeAsPromise(getEventLogsById);
    const body = JSON.parse(response.body);
    return await this.customizeLogResponse(body);
  }

  private loadEventInstance(eventId, eventInstance){
    let matchingArticles;

    //Event definition may be null, we should show us much data regardless.
    const eventDefinition = this.eventLocalePackage.find(evnt => evnt.eventId == eventId);
    if(eventDefinition && eventDefinition.kbArticles) {
      //console.log(eventDefinition.kbArticles)
      try {
        matchingArticles = this.articleLocalePackage.filter(arts => eventDefinition.kbArticles.includes(arts.articleId));
      } catch(e) {
        console.error(e);
      }
    }



    let msgPrivate = eventDefinition ? eventDefinition.msgPrivate : eventInstance.msgPrivate;
    let msgPublic = eventDefinition ? eventDefinition.msgPublic : eventInstance.msgPublic;

    if(!msgPublic) {
      msgPublic = '';
    }

    if (eventInstance.hasOwnProperty('args')) {
      let args;
      if(typeof eventInstance.args === "string"){
        args = JSON.parse(eventInstance.args);
      } else {
        args = eventInstance.args;
      }
      //console.log(args);
      for (const arg of args) {
        //console.log(msgPrivate);
        //msgPrivate = msgPrivate.replace(/%.?\w+/, arg);
        //msgPublic = msgPublic.replace(/%.?\w+/, arg);
        msgPrivate = msgPrivate.replace(/%\.?[\w][lu]?[llu]?[f]?[%]?/, arg);
        msgPublic = msgPublic.replace(/%\.?[\w][lu]?[llu]?[f]?[%]?/, arg);
        //console.log(msgPrivate);
        msgPublic = msgPublic.replace('%%', '%'); // because firmware
        //console.log(msgPrivate);
      }
    } else if (eventInstance.eventId === 2) {
      msgPrivate = '';
      msgPublic = '';
    }

    let suppress1:boolean = false;
    let suppress7:boolean = false;
    let suppress30:boolean = false;
    let suppressForever:boolean = false;

    if(eventDefinition && eventDefinition.alertDefn) {
      suppress1 = eventDefinition.alertDefn.flags.includes("suppress_1");
      suppress7 = eventDefinition.alertDefn.flags.includes("suppress_7");
      suppress30 = eventDefinition.alertDefn.flags.includes("suppress_30");
      suppressForever = eventDefinition.alertDefn.flags.includes("suppress_forever");
    }

    return {
      clientId: eventInstance.duid,
      eventId: eventId,
      severity: eventDefinition ? eventDefinition.severity : eventInstance.severity,
      msgPrivate: msgPrivate,
      title: eventDefinition?eventDefinition.title : eventInstance.title,
      msgPublic: msgPublic,
      createdAt: eventInstance.timestamp,
      articles: matchingArticles,
      suppress1: suppress1,
      suppress7: suppress7,
      suppress30: suppress30,
      suppressForever: suppressForever,
      allowSuppress: suppress1||suppress7||suppress30||suppressForever
    };
  }

  async loadEvent(eventId, eventInstance) {
    await this.localePackagePromise;
    return this.loadEventInstance(eventId, eventInstance);
  }

  async loadArticles(eventId) {
    await this.localePackagePromise;
    const event = this.eventLocalePackage.find(evnt => evnt.eventId == eventId);
    let matchingArticles = this.articleLocalePackage.filter(arts => event.kbArticles.includes(arts.articleId));
    return matchingArticles;
  }

  private async customizeLogResponse(logResponse) {
    logResponse.Items = logResponse.Items.map(record => {
      //Load event by eventId on log item.
      //Load articles by article ids in event.
      return this.loadEventInstance(record.eventId, record);
    }).sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1);
    return logResponse;
  }
  async insertEvents(events: object) : Promise<any> {
    const insertEvents = new DashboardEvents().insertEvents(events);
    const response = await this.apiService.invokeAsPromise(insertEvents);
    return response;
  }

  async insertArticle(article: object) : Promise<any> {
    const insertArticle = new DashboardEvents().insertArticle({item:article});
    const response = await this.apiService.invokeAsPromise(insertArticle);
    return response;
  }
  async getArticlesByEventId(eventId:number, locale?:string) {
    let payload:any = {eventId:eventId};
    if(locale) {
      payload.locale = locale;
    }
    const getArticles = new DashboardEvents().getArticlesByEventId(payload);
    const response = await this.apiService.invokeAsPromise(getArticles);
    //console.log(response.body);
    return response.body;
  }

  async deleteArticle(article: object, eventId: number) : Promise<any> {
    const deleteArticle = new DashboardEvents().deleteArticle({article:article, eventId:eventId});
    const response = await this.apiService.invokeAsPromise(deleteArticle);
    return response;
  }

  async updateArticle(article:object) : Promise<any> {
    const updateArticle = new DashboardEvents().updateArticle({article:article});
    const response = await this.apiService.invokeAsPromise(updateArticle);
    return response;
  }

  async linkArticleToEvent(articleId: number, eventId: number) : Promise<any> {
    const link = new DashboardEvents().linkArticle({articleId, eventId});
    const response = await this.apiService.invokeAsPromise(link);
    return response;
  }

  async unlinkArticleToEvent(articleId: number, eventId: number) : Promise<any> {
    const unlink = new DashboardEvents().unlinkArticle({articleId, eventId});
    const response = await this.apiService.invokeAsPromise(unlink);
    return response;
  }

}
