import { Component, Input, SimpleChanges, Inject, ViewChild } from '@angular/core';
import { Device } from '../../models/device';
import { LocationService } from '../../services/location.service';
import { Location, LocationResults } from '../../models/location';
//import { MatTableDataSource, MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatPaginator, MatSort, Sort } from '@angular/material';
import { ApiService } from '../../services/api.service';
import { NotificationService } from '../../services/notification.service';
import { ClientStorageKey, ClientStorageService, ClientStorageValueType } from '../../services/client-storage.service';
import { DatePipe } from '@angular/common';

import * as columnify from 'columnify';
import { catchError } from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material/table/';
import {MatDialog} from '@angular/material/dialog';


@Component({ selector: 'app-account-info', styleUrls: ['./account-info.component.css'], templateUrl: './account-info.component.html'})
export class AccountInfoComponent {
  @Input() userAccount: any;
  @Input() device: Device;

  dataSource = new MatTableDataSource<any>([]);
  isLoadingAccount = false;
  useCelsius = false;
  lastEvaluatedKey = null;
  locations: any = null;
  settings: any = {};
  tempSafetyLimitMin: number = 25;
  tempSafetyLimitMax: number = 49;
  tempSafetyLimitStep: number = 1;

  constructor (private apiService: ApiService,
               private datePipe: DatePipe,
               private locationService: LocationService,
               private dialog: MatDialog) {


               }

  ngOnInit() {
    const storedTempPref = ClientStorageService.get(ClientStorageKey.PreferCelsius, ClientStorageValueType.String);
    if (storedTempPref && storedTempPref === 'true') {
      this.useCelsius = true;
    } else if (storedTempPref && storedTempPref === 'false') {
      this.useCelsius = false;
    } else {
      ClientStorageService.put(ClientStorageKey.PreferCelsius, 'false');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userAccount.currentValue) {
      this.getLocations();
    }
  }

  async getLocations() {
    this.locations = await this.locationService.getLocations(this.userAccount.federatedIdentity);
  }

  temperaturePreferenceUpdated() {
    if (this.useCelsius) {
      ClientStorageService.put(ClientStorageKey.PreferCelsius, 'true');
      const tempData = this.dataSource.data;
      this.dataSource.data = tempData.map(temp => {
        //temp.avgTempC = this.convertTempFToC(temp.avgTempC);
        return temp;
      });
    } else {
      ClientStorageService.put(ClientStorageKey.PreferCelsius, 'false');
      const tempData = this.dataSource.data;
      this.dataSource.data = tempData.map(temp => {
        //temp.targetTempC = this.convertTempCToF(temp.targetTempC);
        return temp;
      });
    }
    // this.getSessionData();
  }


  changePage(event) {
    if (event.pageIndex * event.pageSize >= event.length - event.pageSize) {
      if (this.lastEvaluatedKey !== null) {
          //this.getNextSessionData();
      }  else {
        // console.log("nothing to get");
      }
    }
  }



  public temperatureBasedName(baseName) {
    if (this.useCelsius) {
      return `${baseName} (°C)`;
    } else {
      return `${baseName} (°F)`;
    }
  }

  private compare = (a, b, isAsc) => {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private convertTempCToF(celsius) {
    return (celsius * 1.8) + 32;
  }

  private convertTempFToC(fahren) {
    return (fahren - 32) / 1.8;
  }



  private roundTemperature(input) {
    return Math.round(input * 10) / 10 ;
  }

  private formatNumber(input) {
     let output = input.toString();
     if ( output.indexOf('.') <= 0) {
       output = output + '.0';
     }
     return output;
  }

  private roundTemperatureHottestColdest(input) {
    let input2 = this.roundTemperature(input);
    let output;

    if (this.useCelsius) {
      if (input2 === 0) { return 'coldest'; } else {
        if (input2 === 100) { return 'hottest'; } else {
          output = this.formatNumber(this.roundTemperature(input));
          if (output) {
          return output;
          }  }
      }
    } else {
      if (input2 === 32) { return 'coldest'; } else {
        if (input2 === 212) { return 'hottest'; } else { output = this.formatNumber(this.roundTemperature(input));
          return output;
        }
      }
    }
  }

  roundMsDuration(input) {

    let seconds = input / 1000;
    if (input === undefined) {
      seconds = 0.0;
   }
    return Math.round(seconds * 10) / 10;
  }

  async save(location:Location) {
    try {
      await this.locationService.updateLocation(this.userAccount.federatedIdentity, location);
    } catch(err) {
      console.error(err);
    }
  }
}

