import {Component, ViewChild, ViewEncapsulation} from '@angular/core';
import {Input, OnInit} from '@angular/core';
import {Shadow} from '../../../models/shadow';
import {Device} from '../../../models/device';
import {HydControlSettings} from '../../../models/hyd/hyd-control-settings';
import {Subject} from 'rxjs';
import * as lodash from 'lodash';
import {MatExpansionPanel} from '@angular/material/expansion';

@Component({
  selector: 'app-control-settings-hyd',
  templateUrl: './control-settings-hyd.component.html',
  styleUrls: ['./control-settings-hyd.component.css'],
  // encapsulation none allows for resizing of the mat-tab widths.
  encapsulation: ViewEncapsulation.None
})
export class ControlSettingsHydComponent implements OnInit {
  @Input() device: Device;
  @Input() saveDesired;
  @Input() saveClicked: Subject<any>;
  settings: HydControlSettings = new HydControlSettings();

  constructor() {

  }

  async ngOnChanges() {
    try {
      if (this.device) {
        this.settings.loadShadow(this.device.shadow);
        const shadow = this.settings.toDesiredShadow();
        console.log("Original:", this.device.shadow);
        console.log("Test:", shadow);
      }
    } catch(err) {
      console.error(err);
    }
  }

  ngOnInit(): void {
    this.saveClicked.subscribe(event => {
        this.saveDesired(this.settings.toDesiredShadow());
    });
  }

}
