import { LambdaFunction } from '../lambda-function';
import { environment } from '../../../environments/environment';

export class GetAuthingUserGroup implements LambdaFunction {
  functionName: string;
  payload: object;

  constructor(payload: object) {
    this.functionName = `fbgpg_account_v1_internal_dashboard_user_group_${environment.stage}`;
    this.payload = payload;
  }
}
