import { LambdaFunction } from '../lambda-function';
import { environment } from '../../../environments/environment';


export class AckAlert implements LambdaFunction {
  functionName: string;
  payload: object;

  constructor(payload: object) {
    this.functionName = `fbgpg_alerts_v1_acknowledge_alert_${environment.stage}`;
    this.payload = payload;
  }
}

export class RemoteAckAlert implements LambdaFunction {
  functionName: string;
  payload: object;

  constructor(payload: object) {
    this.functionName = `fbgpg_alerts_v1_remote_ack_alert_${environment.stage}`;
    this.payload = payload;
  }
}

export class LocalAckAlert implements LambdaFunction {
  functionName: string;
  payload: object;

  constructor(payload: object) {
    this.functionName = `fbgpg_alerts_v1_silence_alert_${environment.stage}`;
    this.payload = payload;
  }
}