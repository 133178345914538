import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(array: any[], query: string): any[] {
    return array.filter(item => {
      const deviceString = (JSON.stringify(item)).toLowerCase();
      return deviceString.includes(query.toLowerCase());
    });
  }

}
