import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { RouteMap } from './route-map';
import { Hub } from 'aws-amplify';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  RouteMap = RouteMap;

  get logoutURL(): string {
    return RouteMap.logout.absolute;
  }

  get navBarEnabled(): boolean {
    const urls = [
      RouteMap.login.absolute,
      RouteMap.logout.absolute,
      RouteMap.sso.absolute
    ];
    return !urls.includes(this.router.url.split('?')[0]);
  }

  public logout() {
    this.authService.logout();
  }

  public async isTier2Support(): Promise<boolean> {
    await this.authService.hasRole(['TIER_2_SUPPORT']);
    return true;
  }

  private isAuthenticatedRoute(currentUrl: string): boolean {
    const urls = [
      RouteMap.logout.absolute,
      RouteMap.login.absolute,
      RouteMap.signup.absolute,
      RouteMap.pendingApproval.absolute,
      RouteMap.sso.absolute
    ];

    return !urls.find(url => currentUrl.includes(url));
  }

  public appversion  = environment.appversion;
  public stage = environment.stage;

  constructor(
    private authService: AuthService,
    private router: Router) {
  }
}
