import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

import { GetStatistics } from '../models/lambda-functions/get-statistics';

@Injectable()
export class StatisticsService {

  constructor(
    private apiService: ApiService
  ) {}


  async getStatistics(clientId: string) {
    let getStatistics = new GetStatistics( {
        pathParameters: { duid: clientId }
    })
    const response = await this.apiService.invokeAsPromise(getStatistics);
    if(response.body) {
      return JSON.parse(response.body);
    } else {
      return undefined;
    }
  }

}