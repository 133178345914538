export class LanguageConverter {
  public toBrowserLocaleTerminology(cloudLocaleTerminology:string):string{
    return this.cloudToBrowserLocale[cloudLocaleTerminology];
  }

  public toCloudLocaleTerminology(browserLocaleTerminology:string):string{
    return this.browserToCloudLocale[browserLocaleTerminology];
  }

  swap(json){
    var ret = {};
    for(var key in json){
      ret[json[key]] = key;
    }
    return ret;
  }

  browserToCloudLocale= { 
    "en-US": "en_US",
    "en": "en_US",
    "zh-CN": "en_US",
    "zh": "en_US",
  };

  cloudToBrowserLocale = this.swap(this.browserToCloudLocale);
}