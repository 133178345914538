import {
  Component,
  Input,
  SimpleChanges,
  Inject,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
  QueryList,
  ChangeDetectorRef
} from '@angular/core';
import { Device } from '../../../models/device';
import { SessionServiceV2 } from '../../../services/session-v2.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table'
import { ApiService } from '../../../services/api.service';
import { DeleteSessionData } from '../../../models/lambda-functions/delete-session';
import { NotificationService } from '../../../services/notification.service';
import { HydraSession } from '../../../models/hyd/hyd-session';
import { ClientStorageKey, ClientStorageService, ClientStorageValueType } from '../../../services/client-storage.service';
import { DatePipe } from '@angular/common';

import * as columnify from 'columnify';
import {SoilSensor} from '../../../models/hyd/soil-sensor';
import {HydSessionResults} from '../../../models/hyd/hyd-session-results';
import {state, trigger, transition, animate, style } from '@angular/animations';
import { DataSource } from '@angular/cdk/table'
import { saveAs } from 'file-saver'

interface DialogData {
  clientId: string;
  confirm: boolean;
  message: string;
}

interface SessionData {
  date: any;
  event: string;
  status: string;
  requestedDuration: number;
  actualDuration: number;
  scheduleID: string;
  zoneID: number;
  skipReason: string;
  solenoidCurrent: number;
  volume: number;
  soilSensors: MatTableDataSource<SoilSensor>;
  sortKey: string;

}

@Component({
  selector: 'app-session-hyd-table',
  styleUrls: ['./session-hyd-table.component.css'],
  templateUrl: './session-hyd-table.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])]
})
export class SessionHydTableComponent {
  private paginator: MatPaginator;
  private sort: MatSort;
  @Input() device: Device;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }
  //@ViewChild('outerSort', { static: true }) sort: MatSort;
  @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<SoilSensor>>;

  displayedColumns = [
    'date',
    'event',
    'status',
    'requestedDuration',
    'actualDuration',
    'scheduleID',
    'zoneID',
    'skipReason',
    'solenoidCurrent',
    'volume',
    'soilSensors'
  ];

  innerDisplayedColumns = [
    'sensorID',
    'address',
    'temp',
    'depth1',
    'depth3',
    'depth5'
  ];

  dataSource = new MatTableDataSource<SessionData>([]);
  isLoadingSession = false;
  useCelsius = false;
  lastEvaluatedKey = null;
  expandedElement: SessionData | null;

  constructor (private apiService: ApiService,
               private sessionService: SessionServiceV2,
               private notificationService: NotificationService,
               private datePipe: DatePipe,
               private dialog: MatDialog,
               private cd: ChangeDetectorRef) {}

  ngOnInit() {
    const storedTempPref = ClientStorageService.get(ClientStorageKey.PreferCelsius, ClientStorageValueType.String);
    if (storedTempPref && storedTempPref === 'true') {
      this.useCelsius = true;
    } else if (storedTempPref && storedTempPref === 'false') {
      this.useCelsius = false;
    } else {
      ClientStorageService.put(ClientStorageKey.PreferCelsius, 'false');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.device.currentValue) {
      this.getSessionData();
    }
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public getSessionData() {
    this.isLoadingSession = true;
    this.sessionService.getSessionDataForThing<HydSessionResults>(this.device.clientId)
      .then(sessions => {
        let res: HydSessionResults = sessions;
        this.dataSource.data = this.toSessionData(res.Items);
        if ( sessions.LastEvaluatedKey ) {
          this.lastEvaluatedKey = sessions.LastEvaluatedKey;
        } else {
          this.lastEvaluatedKey = null;
        }
        this.isLoadingSession = false;
      })
      .catch(error => {
        console.error(error);
      });
  }

  changePage(event) {
    if (event.pageIndex * event.pageSize >= event.length - event.pageSize) {
      if (this.lastEvaluatedKey !== null) {
          this.getNextSessionData();
      }  else {
        // console.log("nothing to get");
      }
    }
  }

  getNextSessionData() {

    this.isLoadingSession = true;

    this.sessionService.getNextSessionDataForThing<HydSessionResults>(this.device.clientId, this.lastEvaluatedKey)
      .then(sessions => {
        for (const item of sessions.Items) {
          this.dataSource.data.push({
            date: item.timestamp,
            event: item.event,
            status: item.status,
            requestedDuration: item.dur,
            actualDuration: item.durUser,
            scheduleID: item.scheduleID,
            zoneID: item.zoneID,
            skipReason: item.skipReason,
            solenoidCurrent: item.solenoid,
            volume: item.volume,
            soilSensors: item.soilSensors,
            sortKey: item.sortKey
          });
        }

        if ( sessions.LastEvaluatedKey ) {
          this.lastEvaluatedKey = sessions.LastEvaluatedKey;
        } else {
          this.lastEvaluatedKey = null;
        }

        this.isLoadingSession = false;
      })
      .catch(error => {
        console.error(error);
      });

  }

  public dumpSessionData() {
    const text = this.dataSource.data
      .sort((a, b) => {
        return this.compare(new Date(a.date), new Date(b.date), true);
      })
      .map(session => {
        session.date= this.datePipe.transform(session.date, 'medium');

        //Reorder columns to match displayedColumns so the text file 'looks right'. DT-248
        return {
          date: session.date,
          event: session.event,
          status: session.status,
          requestedDuration: session.requestedDuration,
          actualDuration: session.actualDuration,
          scheduleID: session.scheduleID,
          zoneID: session.zoneID,
          skipReason: session.skipReason,
          solenoidCurrent: session.solenoidCurrent,
          volume: session.volume,
          soilSensors: session.soilSensors
        };
      });

    let columns = columnify(text, {
      columnSplitter: ' '
    });
    const platform = window.navigator.platform;
    const windows = ['Win32', 'Win64', 'Windows', 'WinCE'];
    if (windows.includes(platform)) {
      columns = columns.replace(/\n/g, '\r\n');
    }
    const blob = new Blob([columns], {type: 'text/plain;carset=utf-8'});
    saveAs(blob, `session_${this.device.clientId}.txt`);
  }

  public sortData = (sort: Sort) => {
    const data = this.dataSource.data;
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'date': return this.compare(new Date(a.date), new Date(b.date), isAsc);
        case 'event': return this.compare(a.event, b.event, isAsc);
        case 'status': return this.compare(a.status, b.status, isAsc);
        case 'requestedDuration': return this.compare(a.requestedDuration, b.requestedDuration, isAsc);
        case 'actualDuration': return this.compare(a.actualDuration, b.actualDuration, isAsc);
        case 'scheduleID': return this.compare(a.scheduleID, b.scheduleID, isAsc);
        case 'zoneID': return this.compare(a.zoneID, b.zoneID, isAsc);
        case 'skipReason': return this.compare(a.skipReason, b.skipReason, isAsc);
        case 'solenoidCurrent': return this.compare(a.solenoidCurrent, b.solenoidCurrent, isAsc);
        case 'volume': return this.compare(a.volume, b.volume, isAsc);
      }
    });
  }


  private compare = (a, b, isAsc) => {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  private openDialog() {
    const dialog = this.dialog.open(SessionConfirmationDialog, {
      width: '500px',
      data: { clientId: this.device.clientId, confirm: false }
    });

    dialog.afterClosed().subscribe(result => {
      if (result) { this.deleteSessionData(); }
    });
  }

  private deleteSessionData() {
    const deleteSessionData = new DeleteSessionData({
      clientId: this.device.clientId
    });

    this.apiService.invokeAsPromise(deleteSessionData)
      .then(() => {
        this.dataSource.data = [];
        this.notificationService.show('Device session data removed successfully');
      })
      .catch((error) => {
        console.error(error);
        this.notificationService.show('There was a problem deleting the session data for this device');
      });
  }

  private formatNumber(input) {
     let output = input.toString();
     if ( output.indexOf('.') <= 0) {
       output = output + '.0';
     }
     return output;
  }


  roundMsDuration(input) {

    let seconds = input / 1000;
    if (input === undefined) {
      seconds = 0.0;
   }
    return Math.round(seconds * 10) / 10;
  }

  private toSessionData(items: HydraSession[]): SessionData[]  {
    return items.map(session => {
      return {
        date: session.timestamp,
        event: session.event,
        status: session.status,
        requestedDuration: session.dur,
        actualDuration: session.durUser,
        scheduleID: session.scheduleID,
        zoneID: session.zoneID,
        skipReason: session.skipReason,
        solenoidCurrent: session.solenoid,
        volume: session.volume,
        soilSensors: new MatTableDataSource<SoilSensor>(session.soilSensors)
      } as SessionData;
    });
  }
  toggleRow(element: SessionData) {
    element.soilSensors && (element.soilSensors as MatTableDataSource<SoilSensor>).data.length ? (this.expandedElement = this.expandedElement === element ? null : element) : null;
    this.cd.detectChanges();
    this.innerTables.forEach((table, index) => (table.dataSource as MatTableDataSource<SoilSensor>).sort = this.innerSort.toArray()[index]);
  }

  applyFilter(filterValue: string) {
    this.innerTables.forEach((table, index) => (table.dataSource as MatTableDataSource<SoilSensor>).filter = filterValue.trim().toLowerCase());
  }
}

@Component({
  selector: 'confirmation-dialog',
  templateUrl: 'confirmation-dialog.html'
})

export class SessionConfirmationDialog {
  constructor(
    public dialogRef: MatDialogRef<SessionConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick() {
    this.dialogRef.close();
  }
}

