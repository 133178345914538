import { Component, Input, SimpleChanges, Inject, ViewChild } from '@angular/core';
import { Device } from '../../models/device';
import { SessionService } from '../../services/session.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ApiService } from '../../services/api.service';
import { DeleteSessionData } from '../../models/lambda-functions/delete-session';
import { NotificationService } from '../../services/notification.service';
import { Session } from '../../models/session';
import { ClientStorageKey, ClientStorageService, ClientStorageValueType } from '../../services/client-storage.service';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver'

import * as columnify from 'columnify';

interface DialogData {
  clientId: string;
  confirm: boolean;
  message: any; // added just to fix --prod build error
}

@Component({
  selector: 'app-usage-table',
  styleUrls: ['./usage-table.component.css'],
  templateUrl: './usage-table.component.html'
})
export class UsageTableComponent {
  private paginator: MatPaginator;
  private sort: MatSort;
  @Input() device: Device;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  displayedColumnsVAK = [
    'date',
    'source',
    'presetId',
    'sessionEndReason',
    'targetTempC',
    'temperatureMin',
    'temperatureAvg',
    'temperatureMax',
    'timeToTargetTempC',
    'durationMs',
    'motorActiveColdTimeMs',
    'motorActiveHotTimeMs',
    'motorCurrentColdMilliAmpSeconds',
    'motorCurrentHotMilliAmpSeconds',
    'purgeVolUl',
    'totalVolUl',
    'maxFlowUlPerSec'];

  dataSource = new MatTableDataSource<any>([]);
  isLoadingSession = false;
  useCelsius = false;
  lastEvaluatedKey = null;

  constructor (private apiService: ApiService,
               private sessionService: SessionService,
               private notificationService: NotificationService,
               private datePipe: DatePipe,
               private dialog: MatDialog) {}

  ngOnInit() {
    const storedTempPref = ClientStorageService.get(ClientStorageKey.PreferCelsius, ClientStorageValueType.String);
    if (storedTempPref && storedTempPref === 'true') {
      this.useCelsius = true;
    } else if (storedTempPref && storedTempPref === 'false') {
      this.useCelsius = false;
    } else {
      ClientStorageService.put(ClientStorageKey.PreferCelsius, 'false');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.device.currentValue) {
      this.getSessionData();
    }
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  temperaturePreferenceUpdated() {
    if (this.useCelsius) {
      ClientStorageService.put(ClientStorageKey.PreferCelsius, 'true');
      const tempData = this.dataSource.data;
      this.dataSource.data = tempData.map(temp => {
        temp.avgTempC = this.convertTempFToC(temp.avgTempC);
        temp.minTempC = this.convertTempFToC(temp.minTempC);
        temp.maxTempC = this.convertTempFToC(temp.maxTempC);
        temp.targetTempC = this.convertTempFToC(temp.targetTempC);
        return temp;
      });
    } else {
      ClientStorageService.put(ClientStorageKey.PreferCelsius, 'false');
      const tempData = this.dataSource.data;
      this.dataSource.data = tempData.map(temp => {
        temp.avgTempC = this.convertTempCToF(temp.avgTempC);
        temp.minTempC = this.convertTempCToF(temp.minTempC);
        temp.maxTempC = this.convertTempCToF(temp.maxTempC);
        temp.targetTempC = this.convertTempCToF(temp.targetTempC);
        return temp;
      });
    }
    // this.getSessionData();
  }

  public getSessionData() {
    this.isLoadingSession = true;
    let x =
    this.sessionService.getSessionDataForThing(this.device.clientId)
      .then(sessions => {
        if (!this.useCelsius) {
          this.dataSource.data = sessions.Items.map(session => {
            session.avgTempC = this.convertTempCToF(session.avgTempC);
            session.minTempC = this.convertTempCToF(session.minTempC);
            session.maxTempC = this.convertTempCToF(session.maxTempC);
            session.targetTempC = this.convertTempCToF(session.targetTempC);
            return session;
          });
        } else {
          this.dataSource.data = sessions.Items;
        }
        if ( sessions.LastEvaluatedKey ) {
          this.lastEvaluatedKey = sessions.LastEvaluatedKey;
        } else {
          this.lastEvaluatedKey = null;
        }
      //  console.log("lastEvaluatedKey="+JSON.stringify(this.lastEvaluatedKey));
      //  console.log("datasource.size="+this.dataSource.data.length);
        this.isLoadingSession = false;
      })
      .catch(error => {
        console.log(error);
      });
  }

  changePage(event) {
    if (event.pageIndex * event.pageSize >= event.length - event.pageSize) {
      if (this.lastEvaluatedKey !== null) {
          this.getNextSessionData();
      }  else {
        // console.log("nothing to get");
      }
    }
  }

  async getNextSessionData() {

    this.isLoadingSession = true;

    this.sessionService.getNextSessionDataForThing(this.device.clientId, this.lastEvaluatedKey)
      .then(sessions => {
        if (!this.useCelsius) {
          this.dataSource.data = this.dataSource.data.concat(sessions.Items.map(session => {
            session.avgTempC = this.convertTempCToF(session.avgTempC);
            session.minTempC = this.convertTempCToF(session.minTempC);
            session.maxTempC = this.convertTempCToF(session.maxTempC);
            session.targetTempC = this.convertTempCToF(session.targetTempC);
            return session;
          }));
        } else {
           this.dataSource.data = this.dataSource.data.concat(sessions.Items);
        }

        if ( sessions.LastEvaluatedKey ) {
          this.lastEvaluatedKey = sessions.LastEvaluatedKey;
        } else {
          this.lastEvaluatedKey = null;
        }

        this.isLoadingSession = false;
      })
      .catch(error => {
        console.log(error);
      });

  }

  public dumpUsageData() {
    const text = this.dataSource.data
      .sort((a, b) => {
        return this.compare(new Date(a.timestamp), new Date(b.timestamp), true);
      })
      .map(session => {
        session.date = this.datePipe.transform(session.timestamp * 1000, 'medium');
        return session;
      });
    let columns = columnify(text, {
      columnSplitter: '|'
    });
    const platform = window.navigator.platform;
    const windows = ['Win32', 'Win64', 'Windows', 'WinCE'];
    if (windows.includes(platform)) {
      columns = columns.replace(/\n/g, '\r\n');
    }
    const blob = new Blob([columns], {type: 'text/plain;carset=utf-8'});
    saveAs(blob, `usage_${this.device.clientId}.txt`);
  }

  public sortData = (sort: Sort) => {
    const data = this.dataSource.data;
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'date': return this.compare(new Date(a.timestamp), new Date(b.timestamp), isAsc);
        case 'source': return this.compare(a.source, b.source, isAsc);
        case 'presetId': return this.compare(a.presetId, b.presetId, isAsc);
        case 'sessionEndReason': return this.compare(a.sessionEndReason, b.sessionEndReason, isAsc);
        case 'durationMs': return this.compare(a.durationMs, b.durationMs, isAsc);
        case 'targetTempC': return this.compare(a.targetTempC, b.targetTempC, isAsc);
        case 'temperatureAvg': return this.compare(a.avgTempC, b.avgTempC, isAsc);
        case 'temperatureMin': return this.compare(a.minTempC, b.minTempC, isAsc);
        case 'temperatureMax': return this.compare(a.maxTempC, b.maxTempC, isAsc);
        case 'timeToTargetTempC': return this.compare(a.timeToTargetTemp, b.timeToTargetTemp, isAsc);
        case 'purgeVolUl': return this.compare(a.purgeVolUl, b.purgeVolUl, isAsc);
        case 'totalVolUl': return this.compare(a.totalVolUl, b.totalVolUl, isAsc);
        case 'maxFlowUlPerSec': return this.compare(a.maxFlowUlPerSec, b.maxFlowUlPerSec, isAsc);
      }
    });
  }

  public temperatureBasedName(baseName) {
    if (this.useCelsius) {
      return `${baseName} (°C)`;
    } else {
      return `${baseName} (°F)`;
    }
  }

  private compare = (a, b, isAsc) => {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private convertTempCToF(celsius) {
    return (celsius * 1.8) + 32;
  }

  private convertTempFToC(fahren) {
    return (fahren - 32) / 1.8;
  }

  openDialog(session) { // added just to fix --prod build error
    const dialog = this.dialog.open(UsageConfirmationDialog, {
      width: '500px',
      data: { clientId: this.device.clientId, confirm: false }
    });

    dialog.afterClosed().subscribe(result => {
      if (result) { this.deleteSessionData(); }
    });
  }

  private deleteSessionData() {
    const deleteSessionData = new DeleteSessionData({
      clientId: this.device.clientId
    });

    this.apiService.invokeAsPromise(deleteSessionData)
      .then(() => {
        this.dataSource.data = [];
        this.notificationService.show('Device session data removed successfully');
      })
      .catch((error) => {
        console.log(error);
        this.notificationService.show('There was a problem deleting the session data for this device');
      });
  }

  private roundTemperature(input) {
    return Math.round(input * 10) / 10 ;
  }

  private formatNumber(input) {
     let output = input.toString();
     if ( output.indexOf('.') <= 0) {
       output = output + '.0';
     }
     return output;
  }

  private roundTemperatureHottestColdest(input) {
    let input2 = this.roundTemperature(input);
    let output;

    if (this.useCelsius) {
      if (input2 === 0) { return 'coldest'; } else {
        if (input2 === 100) { return 'hottest'; } else {
          output = this.formatNumber(this.roundTemperature(input));
          if (output) {
          return output;
          }  }
      }
    } else {
      if (input2 === 32) { return 'coldest'; } else {
        if (input2 === 212) { return 'hottest'; } else { output = this.formatNumber(this.roundTemperature(input));
          return output;
        }
      }
    }
  }

  roundMsDuration(input) {

    let seconds = input / 1000;
    if (input === undefined) {
      seconds = 0.0;
   }
    return Math.round(seconds * 10) / 10;
  }
}

@Component({
  selector: 'confirmation-dialog',
  templateUrl: 'confirmation-dialog.html'
})
export class UsageConfirmationDialog {
  constructor(
    public dialogRef: MatDialogRef<UsageConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick() {
    this.dialogRef.close();
  }
}
