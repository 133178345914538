import { LambdaFunction } from './../lambda-function';
import { environment } from '../../../environments/environment';

export class GetSessionData implements LambdaFunction {
  functionName: string;
  payload: object;

  constructor(payload: object) {
    this.functionName = `session-api-${environment.stage}-get`;
    this.payload = payload;
  }
}
