import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { NabTemp } from '../models/nab-temp';
import { GetSessionDataV2 } from '../models/lambda-functions/get-session-dataV2';
import { NabTempResults } from '../models/nab-temp-results';

@Injectable()
export class NabTempService {
  constructor (private apiService: ApiService) {}

  getSessionDataForThing(clientId: string): Promise<NabTempResults> {
    const getSessionData = new GetSessionDataV2({
      duid: clientId,
      limit: 1001,
      usageType: 'tempData'
    });
    return this.apiService.invokeAsPromise(getSessionData)
      .then(response => JSON.parse(response.body));
  }

  async getNextSessionDataForThing(clientId: string, lastEvaluatedKey: string): Promise<NabTempResults> {
    const getSessionData = new GetSessionDataV2({
      clientId: clientId,
      limit: 1001,
      lastEvaluatedKey: lastEvaluatedKey
    });
    return this.apiService.invokeAsPromise(getSessionData)
      .then(response => response.body);
  }
}
