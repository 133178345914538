import { LambdaFunction } from './../lambda-function';
import { environment } from '../../../environments/environment';


export class AlertData implements LambdaFunction {
  functionName: string;
  payload: object;

  constructor(payload: object) {
    this.functionName = `fbgpg_alerts_v1_get_alerts_${environment.stage}`;
    this.payload = payload;
  }
}