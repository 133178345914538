import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

import { GetShadow } from '../models/lambda-functions/get-shadow';
import { UpdateShadow } from '../models/lambda-functions/update-shadow';

@Injectable()
export class ShadowService {

  constructor(
    private apiService: ApiService
  ) {}


  async getShadow(clientId: string) {
    let getShadow = new GetShadow({
      clientId: clientId
    });
    const response = await this.apiService.invokeAsPromise(getShadow);
    return response.body;
  }

  async updateShadow(clientId: string, payload: any) {
    let updateShadow = new UpdateShadow({
      clientId: clientId,
      payload: payload
    });
    return await this.apiService.invokeAsPromise(updateShadow);
  }
}
