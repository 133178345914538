import { Injectable } from '@angular/core';
import { Reminder } from '../models/reminder';
import { ReminderLambdas } from '../models/lambda-functions/reminder';
import { ApiService } from './api.service';


@Injectable()
export class ReminderService {

  constructor(
    private apiService: ApiService,
    private reminderLambdas: ReminderLambdas,
  ) { 

  }

  async getReminder(type:string, federatedIdentity:string, entityId: string) : Promise<Reminder[]> {
    const getLoc = this.reminderLambdas.getReminder(type, federatedIdentity, entityId);
    console.log(getLoc);
    const locs = await this.apiService.invokeAsPromise(getLoc);
    const locsBody = JSON.parse(locs.body);
    console.log("Reminder");
    console.log(locsBody);
    return locsBody.items;
  }

  async changeReminderInterval(id:string, userId:string, type:string, interval:string, entityId:string, deviceId:string, starting:Date) {
    const updateLoc = this.reminderLambdas.changeReminderInterval(id, userId, type, interval, entityId,  deviceId, starting.toUTCString());

    console.log("Reminder Interval Change");
    console.log(updateLoc);
    const res = await this.apiService.invokeAsPromise(updateLoc);
    console.log(res);
    return res.body;
  }


}