import { Component,OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { EnvironmentService } from '../../services/environment.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientStorageKey, ClientStorageService } from '../../services/client-storage.service';
import { GetAuthingUserGroup } from '../../models/lambda-functions/get-authing-user-groups';
import { ApiService } from '../../services/api.service';
import { decode } from 'jsonwebtoken';
import axios from 'axios';

@Component({
  template: ''
})
export class SSOAuthingCallbackComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private environmentService: EnvironmentService,
    private apiService: ApiService
  ) {
    //Hub.listen('auth', this, 'AuthCodeListener');
  }

  //onHubCapsule(capsule) {
    // const { channel, payload } = capsule;
    // console.log(capsule);
    // if (channel === 'auth' && payload.event === 'signIn') {
    //   window.location.assign(`${this.environmentService.get('baseHref')}devices`);
    // }
  //}

  async ngOnInit() {
    const code = this.getQueryParam('code');
    const authnResponse = await this.getOauthToken(code);
    console.log(JSON.stringify(authnResponse))
    await this.authService.getCognitoCredentials(authnResponse['body'])
    const user = await this.authService.currentUser();
    await this.getUserGroupsFromAuthing(user['token']);
    window.location.assign(`${this.environmentService.get('baseHref')}events`);
  }

  getQueryParam(code:string) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('code');
  }

  async getOauthToken(code: string) {
    const clientId = this.environmentService.get("authingUserPoolClientId");
    //console.log('clientId: ' + clientId);
    const clientSecret = this.environmentService.get("authingUserPoolClientSecret");
    const grantType =  this.environmentService.get('oauthAuthorizationGrantType');
    const redirectUri = this.environmentService.get('oauthRedirectSignIn');
    //console.log('redirectUri: ' + redirectUri);
    const tokenUri = this.environmentService.get('oauthTokenUrl');
    
    const params = new URLSearchParams();
    params.append("client_id", clientId);
    params.append("client_secret", clientSecret);
    params.append("grant_type", grantType);
    params.append("code", code);
    params.append("redirect_uri", redirectUri);

    const axiosInstance = axios.create({
        baseURL: tokenUri,
    });
    try {
        const resp:any = await axiosInstance.post(tokenUri, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        //console.log('token.response: ' + JSON.stringify(resp))
        //alert(JSON.stringify(resp.data))
        return {
            statusCode: 200,
            body: resp.data,
        };
    } catch (err:any) {
        console.log(err);
        return {
            statusCode: err?.response?.status,
            body: err?.response?.data,
        };
    }
  }

  //this is only for China, Authing does not include the group info in the JWT, use the API to get the group info and save the info in local stroage
  /*
  public async getUserGroupsFromAuthing(token:string): Promise<any> {
      let groups = [];
      try {
        const uri = this.environmentService.get('lambdaProxyEndpoint');
        const params = {
          "escape": true,
          "parse": true,
          "body": {},
          "fn": "fbgpg_dashboard_cn_user_api_get_" + this.environmentService.get('stage')
        }
        const axiosInstance = axios.create({
            baseURL: uri,
        });
        const resp:any = await axiosInstance.post(uri, params, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
          }
        });
        groups = resp.data;
      } catch (err) {
        console.log(err);
      }
      let profile = ClientStorageService.get(ClientStorageKey.UserProfile);
      if(!profile) {
        profile = {
          'groups' : groups
        }
      } else {
        profile.groups = groups;
      }
      ClientStorageService.put(ClientStorageKey.UserProfile, profile);
      return groups;
    }
    */
  
    async getUserGroupsFromAuthing(token: string) {
      if(!token) {
        return [];
      }
      const decodedToken = decode(token);
      const cognitoIdentityId = decodedToken['sub'];
      const getAuthingUserGroup = new GetAuthingUserGroup({
        cognitoIdentityId: cognitoIdentityId
      });
      let groups:any = [];
      await this.apiService.invokeAsPromise(getAuthingUserGroup)
      .then((resp) => {
        if(resp?.body) {
          const body = JSON.parse(resp.body);
          groups = body?.data ? body.data : []
        }  
      })
      .catch((error) => {
          console.log('error: ', JSON.stringify(error))
      });
      let profile = ClientStorageService.get(ClientStorageKey.UserProfile);
      if(!profile) {
        profile = {
          'groups' : groups
        }
      } else {
        profile.groups = groups;
      }
      ClientStorageService.put(ClientStorageKey.UserProfile, profile);
      return groups;
    }

}
