import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { ClientStorageService, ClientStorageKey } from './client-storage.service';
import { AuthService } from './auth.service';
import { RouteMap } from '../route-map';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService,
              private router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      const user = await this.authService.currentUser();
      if (!user) {
        this.router.navigate([RouteMap.login.absolute]);
        return false;
      }
      return true;
    } catch (error) {
      console.log(error);
      this.router.navigate([RouteMap.login.absolute]);
      return false;
    }
  }
}
