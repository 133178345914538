import { LambdaFunction } from './../lambda-function';
import { environment } from '../../../environments/environment';

export class ListGroups implements LambdaFunction {
  functionName: string;
  payload: object;

  constructor(payload: object) {
    this.functionName = `group-api-${environment['stage']}-list`;
    this.payload = payload;
  }
}
