import { Component } from '@angular/core';
import { RouteMap } from '../../route-map';

@Component({
  selector: 'app-pending-approval',
  templateUrl: './pending-approval.component.html',
  styleUrls: ['./pending-approval.component.css']
})
export class PendingApprovalComponent {
  RouteMap = RouteMap;
}
