//In China, there isn't Cognito User Pool, will use Authing to replace the Cognito User Pool as Identity provider, will continue to integrate with Cognito Identity Pool
export const environment = {
  apiGatewayKey: '7f1efdcd-d2e0-45ab-a3b9-e60c576d620c',
  production: false,
  appversion: require('../../package.json').version,
  lambdaPrefix: 'smartwater',
  stage: 'cndev2',
  region: 'cn-northwest-1', 
  userPoolClientId: '', //cognito user pool client id, not used for China
  identityPoolId: 'cn-north-1:b1ecf765-f05f-40cb-aa70-c38d5af2c233',
  userPoolId: '', //cognito user pool id, not used for China
  federatedDomain: 'ahpjizkfwziq-demo.authing.cn/oidc', 
  aws_pubsub_region: 'cn-northwest-1',
  aws_pubsub_endpoint: 'wss://a2q1rf197me5m5-ats.iot.cn-northwest-1.amazonaws.com.cn/mqtt',
  baseHref: '/',
  oauthDomain: 'ahpjizkfwziq-demo.authing.cn',
  oauthScope: [
    'email',
    'profile',
    'openid',
    'aws.cognito.signin.user.admin'
  ],
  oauthRedirectSignIn: 'https://smartwaterdev.moeniot.com/ssoAuthing',
  oauthRedirectSignOut: 'https://smartwaterdev.moeniot.com/logout/',
  oauthResponseType: 'code',

  //below are configuration specifically for China usage, in China there isn't Cognito User Pool, use Authing instead
  isChina: true,
  
  authingUserPoolId: '659ab36ef554496436ad270d', //authing user pool id
  authingUserPoolClientId: '659ab36fd932525c721ce053', //authing user pool client/app id
  authingUserPoolClientSecret: '0a632114c0d546c3ae86c427f3a8cb1b', //authing does not has a Public oauth client concept, for SPA as this portal, rely on the pre-configured redirect uri for security.
  
  cnCognitoRegion: 'cn-north-1', //China Beijing, only Beijig region has Cognito Identity Pool service
  identityCredentialExpiresInSeconds: 3600,
  oauthAuthorizationGrantType: 'authorization_code',
  oauthEndSessionUrl: 'https://ahpjizkfwziq-demo.authing.cn/oidc/session/end',
  oauthTokenUrl: 'https://ahpjizkfwziq-demo.authing.cn/oidc/token',
};
