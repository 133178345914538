
import {Component, Input, OnInit, ChangeDetectorRef, ViewChild, HostListener, SimpleChanges} from '@angular/core';
import {Device} from '../../models/device';
import { NotificationService } from '../../services/notification.service';
import {DeviceService} from '../../services/device.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Shadow} from '../../models/shadow';
import {PubSub} from 'aws-amplify';
import {ClientStorageService, ClientStorageKey, ClientStorageValueType} from '../../services/client-storage.service';
import {EventsTableComponent} from '../../components/events-table/events-table.component';
import {format} from 'url';
import {UsageTableComponent} from '../../components/usage-table/usage-table.component';
import {AuthService} from '../../services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ViewEncapsulation } from '@angular/core';
import { EnvironmentService} from '../../services/environment.service';

@Component({
  selector: 'app-status-nabvak-table',
  templateUrl: './status-nabvak-table.html',
  styleUrls: ['./status-nabvak-table.css'],

  // encapsulation none allows for resizing of the mat-tab widths.
  encapsulation: ViewEncapsulation.None
})
export class StatusNabVakTableComponent implements OnInit {
  @Input() device: Device;
  @Input() refreshDevice: () => void;

  constructor(
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private environment: EnvironmentService,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    private sanitizer: DomSanitizer) {
  }

  @ViewChild(EventsTableComponent) eventsTable: EventsTableComponent;
  @ViewChild(UsageTableComponent) usageComponent: UsageTableComponent;
  isSticky = false;
  isLoadingDevice = false;
  isLoadingShadow = false;
  shadow: Shadow;
  shadowString: String;
  clientId: string;
  shadowData = {
    generalKeys: [],
    displayKeys: [],
    occupancyKeys: [],
    handleKeys: [],
    sensorKeys: [],
    gestureSensorKeys: [],
    powerKeys: [],
    outletsKeys: [],
    valveKeys: [],
    nabooCrockKeys: [],
    nabooEnvKeys: [],
    nabooDropletKeys: [],
    nabooAlertKeys: [],
    nabooSensorKeys: []
  };

  settings = {
    dropEnabled: false,
    crockMonitorOn: false
  };

// tslint:disable-next-line:max-line-length
  generalKeys = ['firmwareVersion', 'connected', 'lastConnect', 'wifiRssi', 'command', 'commandSrc', 'beeperVolume', 'beeperPiezoEnable', 'beeperSirenEnable', 'beeperEnable', 'sensorDisable'];
  displayKeys = ['dispBrightness', 'dispInactivityTimeout', 'dispUnits', 'dispLanguage', 'dispUtcOffset', 'dispQuickPresetIds'];
  occupancyKeys = ['occupancy'];
  handleKeys = ['handleFW', 'handleReverse', 'spoutInactivtyTimeout'];
  sensorKeys = ['latchFW', 'ledBrightness'];
  gestureSensorKeys = ['gestureFW'];
  powerKeys = ['powerSource', 'batteryPercentage', 'batterySavingLevel'];
  outletsKeys = ['outletTotal', 'outletNumSimul', 'outletTempC', 'outletIcons'];
  // tslint:disable-next-line:max-line-length
  valveKeys = ['valveTotal', 'state', 'command', 'commandSrc', 'presetID', 'volume', 'flowRate', 'temperature', 'purge', 'isFreezing', 'trickleFlowRate',
    'learnedMinTemp', 'learnedMaxTemp', 'defaultTemp', 'defaultFlowRate', 'maxFlowRate', 'purgeTimeout', 'handleTimeout', 'sensorTimeout',
    // tslint:disable-next-line:max-line-length
    'voiceTimeout', 'safetyLimitTemp', 'safetyModeEnabled', 'childLimitTemp', 'childModeEnabled', 'systemSafeTemp', 'timeRemaining', 'timerExpired',
    'assemblyAirTemp', 'userCalibrated', 'reverseHotCold'];
  // tslint:disable-next-line:max-line-length
  temperatureKeys = ['assemblyAirTemp', 'safetyLimitTemp', 'childLimitTemp', 'defaultTemp', 'temperature', 'learnedMinTemp', 'learnedMaxTemp'];
  nabooCrockKeys = [ 'crockDiameterMM', 'crockBackup', 'crockBckTst', 'crockState', 'crockTofHeight'];
  nabooEnvKeys = ['tempLowThreshold', 'tempHighThreshold', 'humidLowThreshold', 'humidHighThreshold'];
  nabooSensorKeys = ['crockTofDistance'];
  nabooDropletKeys = ['level', 'trend', 'floodRisk', 'primaryState', 'backupState'];
  nabooAlertKeys = ['alerts'];
  isDeveloper = false;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 64;
  }

  async ngOnInit() {
    // Working around https://github.com/angular/material2/issues/5593
    this.changeDetector.detectChanges();
    await this.updateDevice();
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnChanges(changes: SimpleChanges) {
    if (changes.device.currentValue) {
      // tslint:disable-next-line:max-line-length
      this.settings.crockMonitorOn = this.device.shadow.crockTofDistance !== -1;
      // tslint:disable-next-line:max-line-length
      if (this.device.shadow.droplet) {
        this.settings.dropEnabled = this.device.shadow.droplet['level'] !== -1;
      }
    }
  }

  private async updateDevice() {
    this.isLoadingShadow = true;
    try {
      const shadowKeys = Object.keys(this.device.shadow || {});
      for (const key of shadowKeys) {
        if (this.generalKeys.includes(key)) {
          this.shadowData.generalKeys.push(key);
        } else if (this.displayKeys.includes(key)) {
          this.shadowData.displayKeys.push(key);
        } else if (this.occupancyKeys.includes(key)) {
          this.shadowData.occupancyKeys.push(key);
        } else if (this.handleKeys.includes(key)) {
          this.shadowData.handleKeys.push(key);
        } else if (this.sensorKeys.includes(key)) {
          this.shadowData.sensorKeys.push(key);
        } else if (this.gestureSensorKeys.includes(key)) {
          this.shadowData.gestureSensorKeys.push(key);
        } else if (this.powerKeys.includes(key)) {
          this.shadowData.powerKeys.push(key);
        } else if (this.outletsKeys.includes(key)) {
          this.shadowData.outletsKeys.push(key);
        } else if (this.valveKeys.includes(key)) {
          this.shadowData.valveKeys.push(key);
        } else if (this.nabooCrockKeys.includes(key)) {
          this.shadowData.nabooCrockKeys.push(key);
        } else if (this.nabooSensorKeys.includes(key)) {
          this.shadowData.nabooSensorKeys.push(key);
        } else if (key === 'droplet') {
          const dropletObj = this.device.shadow.droplet;
          const dropletKeys = Object.keys(dropletObj || {});
          for (const drop of dropletKeys) {
            if (this.nabooDropletKeys.includes(drop)) {
              this.shadowData.nabooDropletKeys.push(drop);
            }
          }
        } else if (key === 'alerts') {
          const shadowAlerts = this.device.shadow.alerts;
          const alertKeys = Object.keys( shadowAlerts || {});
          for (const alertId of alertKeys) {
            const alertData = this.device.shadow.alerts[alertId];
            this.shadowData.nabooAlertKeys.push({alertId: alertId, alertTimestamp: alertData.timestamp, alertState: alertData.state});
          }
        }
      }

      if (!this.shadowData.valveKeys.includes('learnedMinTemp')) {
        this.shadowData.valveKeys.push('learnedMinTemp');
        this.device.shadow['learnedMinTemp'] = -1;
      }

      if (!this.shadowData.valveKeys.includes('learnedMaxTemp')) {
        this.shadowData.valveKeys.push('learnedMaxTemp');
        this.device.shadow['learnedMaxTemp'] = -1;
      }

      this.isLoadingDevice = false;
      this.isLoadingShadow = false;
    } catch (error) {
      console.log(error);
    }
  }

  private prettyPrintKey(key: string): string {
    // Replace ToF with sensor
    // Replace MM with mm.
    // Replace Bck Tst with Backup Test
    if (key === 'crockBackup') {
      key = 'crockHasBackup';
    }
    if (key.includes('Tof')) {
      key = key.replace('Tof', 'Sensor');
    }
    if (key.includes('BckTst')) {
      key = key.replace('BckTst', 'Backup Test');
    }
    if (!key.includes('FW')) {
      key = key
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) => str.toUpperCase());
      if (key.includes('Tof Distance')) {
        key  = 'Sensor Distance' + ' (Not updated unless Updates ON)';
      }
      if (key.includes('M M')) {
        key = key.replace('M M', '');
      }
      return key;
    } else {
      if (key.includes('latch')) {
        key = 'sensorFW';
      }
      key = key.replace('FW', 'Version');
      return key
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) => str.toUpperCase());
    }
  }

  private formatDisplay(shadow, key) {
    if (key === 'wifiRssi') {
      return this.formatRssi(shadow[key]);
    } else if (key.includes('learnedMinTemp') && shadow[key] === -1) {// Learned min temp won't be known if LYH hasn't been completed.
      return 'Unknown';
    } else if (key.includes('learnedMaxTemp') && shadow[key] === -1) {// Learned max temp won't be known if LYH hasn't been completed.
      return 'Unknown';
    } else if (this.temperatureKeys.includes(key) && !isNaN(shadow[key])) {
      return this.formatTemperature(shadow[key]);
    } else if (key === 'batteryPercentage') {
      if (shadow[key] === -1) {
        return 'N/A';
      } else if (shadow[key] <= 10) {
        return '<10%';
      } else {
        return shadow[key] + '%';
      }
    } else if (key.includes('Timeout')) {
      return shadow[key] / 60 + ' min';
    } else if (key.includes('Rate')) {
      return shadow[key] + '%';
    } else if (key.includes('Diameter') || key.includes('crockTofHeight')) {
      return shadow[key] + ' mm';
    } else {
      return shadow[key];
    }
  }

  private formatTemperature(tempC) {
    return `${Math.round(tempC * 10) / 10} °C (${this.celsiusToFahrenheit(tempC)} °F)`;
  }

  private celsiusToFahrenheit(tempC) {
    return Math.round(tempC * 1.8 + 32);
  }

  private formatRssi(value) {
    if (value >= -55) {
      return `${value}   (GREAT)`;
    } else if (value < -55 && value >= -65) {
      return `${value}   (GOOD)`;
    } else if (value < -65 && value >= -70) {
      return `${value}   (ACCEPTABLE)`;
    } else if (value < -70 && value >= -75) {
      return `${value}   (POOR)`;
    } else {
      return `${value}   (BAD)`;
    }
  }

  private rssiClass(value, connected) {
    if (connected && value >= -55) {
      return `rssi-great`;
    } else if (connected && value < -55 && value >= -65) {
      return `rssi-good`;
    } else if (connected && value < -65 && value >= -70) {
      return `rssi-acceptable`;
    } else if (connected && value < -70 && value >= -75) {
      return `rssi-poor`;
    } else {
      return `rssi-bad`;
    }
  }

  async toggleSumpCrockMonitor() {
    const shadow = {
      state: {
        desired: {}
      }
    };
    let desired = {};
    let message = '';
    if (this.settings.crockMonitorOn) {
      desired = {crockCommand: 'sens_on'};
      message = 'Turned crock monitoring sensor on!';

    } else {
      desired = {crockCommand: 'updates_off'};
      message = 'Turned crock monitoring sensor off!';

    }
    shadow.state.desired = desired;
    PubSub.publish(`$aws/things/${this.device.clientId}/shadow/update`, shadow);
    this.notificationService.show(message);
    await this.refreshDevice();
  }

  async toggleDrop() {
    const shadow = {
      state: {
        desired: {}
      }
    };
    let desired = {};
    let message = '';
    if (this.settings.dropEnabled) {
      desired = {crockCommand: 'drop_on'};
      message = 'Turned drop updates on!';

    } else {
      desired = {crockCommand: 'updates_off'};
      message = 'Turned drop updates off!';

    }
    shadow.state.desired = desired;
    PubSub.publish(`$aws/things/${this.device.clientId}/shadow/update`, shadow);
    this.notificationService.show(message);
    await this.refreshDevice();
  }
}
