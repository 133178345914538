import {DashboardControlActions} from '../../core/models/dashboard-control-actions';
import {Shadow, ShadowCommand, ShadowCommandNaboo} from '../shadow';
import {DashboardAction} from '../../core/models/dashboard-action';
import {DialogService} from '../../services/dialog.service';
import {ViewContainerRef} from '@angular/core';

export class NabCommands extends DashboardControlActions {
  shadow: Shadow = new Shadow();
  dialogService: DialogService;
  viewContainerRef: ViewContainerRef;

  constructor(clientId: string, dialogService: DialogService, viewContainerRef:ViewContainerRef) {
    super();

    this.dialogService = dialogService;
    this.viewContainerRef = viewContainerRef;
    this.clientId = clientId;
    this.AddAction(new DashboardAction({name: 'completeInstallation', displayName: 'Complete Installation', action: () => this.completeInstallation()}));
    this.AddAction(new DashboardAction({name: 'reboot', displayName: 'Reboot', action: () => this.reboot()}));
    this.AddAction(new DashboardAction({name: 'locate', displayName: 'Locate', action: () => this.locate()}));
    this.AddAction(new DashboardAction({name: 'resetCrock', displayName: 'Reset Crock', action: () => this.resetCrock()}));
    this.AddAction(new DashboardAction({
      name: 'resetPrimaryState',
      displayName: 'Reset Primary State',
      action: () => this.resetCrockPrimaryState()
    }));
    this.AddAction(new DashboardAction({
      name: 'resetBackupState',
      displayName: 'Reset Backup State',
      action: () => this.resetCrockBackupState()
    }));
    this.AddAction(new DashboardAction({
      name: 'resetBatteryReminder',
      displayName: 'Reset Battery Reminder',
      action: () => this.resetCrockBatteryReminder()
    }));
    this.AddAction(new DashboardAction({name: 'queueBackupTest', displayName: 'Queue Backup Test', action: () => this.queueBackupTest()}));
  }

  reboot() {
    this.shadow.command = ShadowCommand.Reboot;
    this.shadow.commandSrc = 'dashbd';
    this.updateShadow(this.shadow);
  }

  completeInstallation() {
    const res = this.dialogService.show('Confirm Crock/Basin Settings', 'Monitor will be set to run normally. Remember to confirm crock/basin diameter is set correctly on the Control Tab', this.viewContainerRef);
    this.shadow.crockState = 'normal';
    this.updateShadow(this.shadow);
  }

  locate() {
    this.shadow.command = ShadowCommand.Locate;
    this.shadow.commandSrc = 'dashbd';
    this.updateShadow(this.shadow);
  }

  resetCrockBatteryReminder() {
    console.log('Reset Battery Reminder');
  }

  resetCrockPrimaryState() {
    this.shadow.crockCommand = ShadowCommandNaboo.ResetPrimary;
    this.shadow.crockCommandSrc = 'dashbd';
    this.updateShadow(this.shadow);
  }

  resetCrockBackupState() {
    this.shadow.crockCommand = ShadowCommandNaboo.ResetBackup;
    this.shadow.crockCommandSrc = 'dashbd';
    this.updateShadow(this.shadow);
  }

  queueBackupTest() {
    this.shadow.crockBckTst = ShadowCommand.Scheduled;
    this.updateShadow(this.shadow);
  }

  resetCrock() {
    this.shadow.crockCommand = ShadowCommandNaboo.Reset;
    this.shadow.crockCommandSrc = 'dashbd';
    this.updateShadow(this.shadow);
  }
}
