import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { NotificationService } from '../../services/notification.service';
import { RouteMap } from '../../route-map';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {
  authService: AuthService;
  notificationService: NotificationService;
  router: Router;
  shouldShowReset = false;
  isResettingPassword = false;
  isChangingPassword = false;
  username: string;
  code: string;
  newPassword: string;
  confirmPassword: string;

  constructor(
    authService: AuthService,
    notificationService: NotificationService,
    router: Router) {
    this.authService = authService;
    this.notificationService = notificationService;
    this.router = router;
  }

  passwordReset($event) {
    $event.preventDefault();
    this.isResettingPassword = true;
    this.authService.forgotPassword(this.username)
      .then(response => {
        const method = response.CodeDeliveryDetails.AttributeName;
        const destination = response.CodeDeliveryDetails.Destination;
        this.notificationService.show(`Code successfully sent via ${method} to ${destination}`);
        this.shouldShowReset = true;
      })
      .catch(error => {
        this.notificationService.show(error.message);
        this.isResettingPassword = false;
      });
  }

  passwordChange($event) {
    $event.preventDefault();
    this.isChangingPassword = true;
    this.authService.forgotPasswordSubmit(this.username, this.code, this.newPassword, this.confirmPassword)
      .then(response => {
        this.notificationService.show('Password changed successfully. Redirecting you...');
        this.router.navigate([RouteMap.login.absolute]);
      })
      .catch(error => {
        this.notificationService.show(error.message);
        this.isChangingPassword = false;
      });
  }
}
