import { Injectable } from '@angular/core';
import { Location, LocationResults } from '../models/location';
import { LocationLambdas } from '../models/lambda-functions/location';
import { AlertResults } from '../models/alert-results';
import { Alert } from '../models/alert';
import { AlertData } from '../models/lambda-functions/alert-functions';
import { ApiService } from './api.service';


@Injectable()
export class LocationService {

  constructor(
    private apiService: ApiService,
    private locationLambdas: LocationLambdas,
  ) { 

  }

  async getLocations(federatedIdentity:string) : Promise<Location[]> {
    const getLoc = this.locationLambdas.getLocations(federatedIdentity);
    const locs = await this.apiService.invokeAsPromise(getLoc);
    return locs.body;
  }

  async updateLocation(federatedIdentity:string, location:Location) {
    const updateLoc = this.locationLambdas.updateLocation(federatedIdentity, location);
    const res = await this.apiService.invokeAsPromise(updateLoc);
    return res.body;
  }


}

