import { Injectable } from '@angular/core';

import { Auth } from 'aws-amplify';
import { Iot } from 'aws-sdk';
import { ClientStorageKey, ClientStorageService } from './client-storage.service';
import { EnvironmentService } from './environment.service';
import { decode } from 'jsonwebtoken';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  constructor(
    private environmentService: EnvironmentService,
    private router: Router
    ) {
  }

  public currentUser = async (): Promise<any> => {
    return Auth.currentAuthenticatedUser();
  }

  public async login() {
    await this.attachPolicy();
  }

  private async attachPolicy() {
    const credentials = await Auth.currentCredentials();
    const creds = Auth.essentialCredentials(credentials);
    const iot = new Iot({
      region: this.environmentService.get('region'),
      apiVersion: '2015-05-28',
      credentials: creds
    });
    const params = {
      policyName: `smartwater-consumer-services-${this.environmentService.get('stage')}`,
      target: credentials.identityId
    };
    iot.attachPolicy(params, (error, data) => {
      if (error) { console.log(error); }
    })
  }

  private async setRoles() {
    const user = await this.currentUser();
    const token = user.signInUserSession.idToken.jwtToken;
    console.log(user);
    console.log(token);
  }

  /**
   * Send password reset email
   */
  public forgotPassword(email: string) {
    if (!email) {
      return Promise.reject(Error('Username is required'));
    }

    if (email.indexOf('@') === -1) {
      return Promise.reject(Error('Invalid email format'));
    }

    return Auth.forgotPassword(email);
  }

  /**
   * Change user password from reset email
   */
  public forgotPasswordSubmit(email: string, code: string, newPassword: string, confirmPassword: string) {
    if (!code) {
      return Promise.reject(Error('Invalid verification code'));
    }

    if (!newPassword) {
      return Promise.reject(Error('Invalid password'));
    }

    if (newPassword !== confirmPassword) {
      return Promise.reject(Error('Passwords do not match'));
    }

    return Auth.forgotPasswordSubmit(email, code, newPassword);
  }

  /**
   * Remove tokens and expiry time from localStorage
   */
  public async logout() {
    await Auth.signOut();
    [
      ClientStorageKey.QueryType,
      ClientStorageKey.Query,
      ClientStorageKey.ClientId,
      ClientStorageKey.FirmwareSearch,
      ClientStorageKey.UserProfile 
    ].forEach(ClientStorageService.remove);
    if(this.environmentService.get('isChina')) {
      window.location.href = this.environmentService.get('oauthEndSessionUrl');
    }
  }

  /**
   * Check whether a user has one of the passed in roles
   * @returns {boolean}
   */
  public async hasRole(roles: string[]): Promise<boolean> {
    //for China, Authing does not include the Group info in the token claims, need to get the group info from other places e.g. API
    if(this.environmentService.get('isChina')) {
      const user = await this.currentUser();
      const token = user.token;
      if(!token) {
        return false;
      }
      const userRoles = this.getUserGroups()
      if (userRoles) {
        return roles.every(role => userRoles.includes(role));
      }
      return false;
    }
    const user = await this.currentUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const decodedToken = decode(token);
    const userRoles = decodedToken['cognito:groups'];
    if (userRoles) {
      return roles.every(role => userRoles.includes(role));
    }
    return false;
  }

  //this is only for China, get the user's group info from local storage
  public getUserGroups(): string[] {
    const profile = ClientStorageService.get(ClientStorageKey.UserProfile);
    if(!profile || !profile.groups) {
      return []
    }
    return profile.groups;
  }

  //this is only for China, Use the Authing token to get the Cognito Identity Pool credential
  public async getCognitoCredentials(principal:any): Promise<any> {
    try {
      const token = principal['access_token'];
      const domainOrProviderName = this.environmentService.get('federatedDomain');
      const identity_id = '';
      const expiresIn = this.environmentService.get('identityCredentialExpiresInSeconds');
      const user = null;
      //console.log('access_token: ' + token)
      await Auth.federatedSignIn(
        domainOrProviderName, {
          token,
          identity_id, // Optional
          expires_at: expiresIn * 1000 + new Date().getTime() // the expiration timestamp
        },
        user
      );
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      return authenticatedUser;
    } catch (err) {
      console.log(err);
    }
  }
}
