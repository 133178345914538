import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
//import { HttpModule } from '@angular/http';
import { CdkTableModule } from '@angular/cdk/table';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { APP_BASE_HREF } from '@angular/common';
import { MatButtonModule } from '@angular/material/button'
import { MatTableModule } from '@angular/material/table'

import {  MatCardModule } from '@angular/material/card'
import {  MatIconModule} from '@angular/material/icon'
import {  MatExpansionModule} from '@angular/material/expansion'
import {  MatListModule} from '@angular/material/list'
import {  MatTabsModule} from '@angular/material/tabs'
import {  MatGridListModule} from '@angular/material/grid-list'
import {  MatInputModule} from '@angular/material/input'
import {  MatCheckboxModule} from '@angular/material/checkbox'
import {  MatSelectModule} from '@angular/material/select'
import {  MatPaginatorModule} from '@angular/material/paginator'
import {  MatSortModule} from '@angular/material/sort'
import {  MatProgressSpinnerModule} from '@angular/material/progress-spinner'
import {  MatSnackBarModule} from '@angular/material/snack-bar'
import {  MatDialogModule} from '@angular/material/dialog'
import {  MatSliderModule } from '@angular/material/slider'
import {  MatSlideToggleModule} from '@angular/material/slide-toggle'
import {  MatDatepickerModule} from '@angular/material/datepicker'
import {  MatNativeDateModule} from '@angular/material/core'
import {  MatAutocompleteModule} from '@angular/material/autocomplete';
//import {
  //MatButtonModule,
  //MatTableModule,
  //MatCardModule,
  //MatIconModule,
  //MatExpansionModule,
  //MatListModule,
  //MatTabsModule,
  //MatGridListModule,
  //MatInputModule,
  //MatCheckboxModule,
  //MatSelectModule,
  //MatPaginatorModule,
  //MatSortModule,
  //MatProgressSpinnerModule,
  //MatSnackBarModule,
  //MatDialogModule,
  //MatSliderModule,
  //MatSlideToggleModule,
  //MatDatepickerModule,
  //MatNativeDateModule,
  //MatAutocompleteModule
//} from '@angular/material';

import { environment } from '../environments/environment';
import { ApiHttpService } from './core/services/api-http.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { EnvironmentService } from './services/environment.service';
import { LoginComponent } from './views/login/login.component';
import { DevicesComponent } from './views/devices/devices.component';
import { DeviceService } from './services/device.service';
import { AccountsApiService, AccountsEndpointsService } from './core/services/endpoints/v1/accounts-endpoints.service';
import { AlertsApiService, AlertsEndpointsService } from './core/services/endpoints/v3/alerts-endpoints.service';
import { DeviceApiService, DeviceEndpointsService } from './core/services/endpoints/v1/device-endpoints.service';
import { FormatSerialPipe } from './pipes/format-serial.pipe';
import { UsersComponent } from './views/users/users.component';
import { SignupComponent } from './views/signup/signup.component';
import { PendingApprovalComponent } from './views/pending-approval/pending-approval.component';
import { UserService,  UserConfirmationDialog } from './services/user.service';
import { UserComponent } from './views/user/user.component';
import { FormatUserIdPipe } from './pipes/format-user-id.pipe';
import { FilterComponent } from './components/filter/filter.component';
import { FilterPipe } from './pipes/filter.pipe';
import { FirmwareComponent } from './views/firmware/firmware.component';
import { DevicesTableComponent } from './components/devices-table/devices-table.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { LogoutComponent } from './components/logout/logout.component';
import { SignupFormComponent } from './components/signup-form/signup-form.component';
import { AuthService } from './services/auth.service';
import { ApiService } from './services/api.service';
import { NotificationService } from './services/notification.service';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { DialogService } from './services/dialog.service';
import { RoleGuard } from './services/role-guard.service';
import { AuthGuard } from './services/auth-guard.service';
import { EventService } from './services/event.service';
import { EventsTableComponent } from './components/events-table/events-table.component';
import { DeviceControlComponent } from './components/device-control/device-control.component';
import { DeviceSettingsDialogComponent } from './components/device-settings-dialog/device-settings-dialog.component';
import { MessageService } from './services/messages.service';
import { EventsComponent } from './views/events/events.component';
import { DeviceComponent } from './views/device/device.component';
import { DeveloperComponent } from './views/developer/developer.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { SlidePanelComponent } from './components/slide-panel/slide-panel.component';
import { DeveloperConfirmationDialog } from './views/developer/developer.component';
import { SessionService } from './services/session.service';
import { LocationService } from './services/location.service';
import { LocationLambdas } from './models/lambda-functions/location';
import { GroupService } from './services/group.service';
import { UsageTableComponent, UsageConfirmationDialog } from './components/usage-table/usage-table.component';
import { AccountInfoComponent } from './components/account-info/account-info.component';
import { FirmwareService } from './services/firmware.service';
import { ShadowService } from './services/shadow.service';
import { AdminComponent } from './views/admin/admin.component';
import { SSOCallbackComponent } from './components/sso-callback/sso-callback.component';
import { SSOAuthingCallbackComponent } from './components/sso-callback/sso-authing-callback.component';
import {AlertsTableComponent} from './components/alerts-table/alerts-table.component';
import {AlertService} from './services/alert.service';
import { NabSessionTableComponent, NabSessionConfirmationDialog } from './components/nab-session-table/nab-session-table.component';
import { SessionHydTableComponent, SessionConfirmationDialog } from './components/hyd/session-hyd-table/session-hyd-table.component';
import { SessionConfirmationDialog as HwkConfirmationDialog } from './components/hyd/session-hwk-table/session-hwk-table.component';
import { SessionServiceV2 } from './services/session-v2.service';
import { NabHumidService } from './services/nab-humid.service';
import { NabHumidTableComponent } from './components/nab-humid-table/nab-humid-table.component';
import { NabTempService } from './services/nab-temp.service';
import { NabTempTableComponent } from './components/nab-temp-table/nab-temp-table.component';
import { AlertHistoryTableComponent } from './components/alert-history-table/alert-history-table.component';
import { StatisticsTableComponent } from './components/statistics-table/statistics-table.component';
import { StatusNabVakTableComponent } from './components/status-nabvak-table/status-nabvak-table';
import { StatusHydTableComponent } from './components/hyd/status-hyd-table/status-hyd-table';
import { StatisticsService } from './services/statistics.service';
import { ReminderService } from './services/reminder.service';
import { ReminderLambdas } from './models/lambda-functions/reminder';
import { HttpClientModule } from '@angular/common/http';
import {FlowSensorHydTableComponent} from './components/hyd/flow-sensor-hyd-table/flow-sensor-hyd-table';
import { ZonesHydTableComponent } from './components/hyd/zones-hyd-table/zones-hyd-table.component';
import { ControlSettingsHydComponent} from './components/hyd/control-settings-hyd/control-settings-hyd.component';
import { SessionHwkTableComponent } from './components/hyd/session-hwk-table/session-hwk-table.component';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
  declarations: [
    AlertsTableComponent,
    AppComponent,
    LoginComponent,
    DevicesComponent,
    FormatSerialPipe,
    UsersComponent,
    SignupComponent,
    PendingApprovalComponent,
    UserComponent,
    FormatUserIdPipe,
    FilterComponent,
    FilterPipe,
    FirmwareComponent,
    DevicesTableComponent,
    LoadingIndicatorComponent,
    LoginFormComponent,
    LogoutComponent,
    SignupFormComponent,
    ConfirmDialogComponent,
    SafeHtmlPipe,
    EventsTableComponent,
    DeviceControlComponent,
    DeviceSettingsDialogComponent,
    EventsComponent,
    DeviceComponent,
    DeveloperComponent,
    ResetPasswordComponent,
    SlidePanelComponent,
    DeveloperConfirmationDialog,
    UsageTableComponent,
    AccountInfoComponent,
    UsageConfirmationDialog,
    AdminComponent,
    SSOCallbackComponent,
    UserConfirmationDialog,
    NabSessionTableComponent,
    NabSessionConfirmationDialog,
    SessionHydTableComponent,
    SessionConfirmationDialog,
    HwkConfirmationDialog,
    SessionHwkTableComponent,
    NabHumidTableComponent,
    NabTempTableComponent,
    AlertHistoryTableComponent,
    StatisticsTableComponent,
    StatusNabVakTableComponent,
    StatusHydTableComponent,
    FlowSensorHydTableComponent,
    ZonesHydTableComponent,
    ControlSettingsHydComponent
  ],
  entryComponents: [
    ConfirmDialogComponent,
    DeviceSettingsDialogComponent,
    DeveloperConfirmationDialog,
    UsageConfirmationDialog,
    UserConfirmationDialog
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatToolbarModule,
    MatButtonModule,
    MatTableModule,
    CdkTableModule,
    MatCardModule,
    MatIconModule,
    MatExpansionModule,
    MatTabsModule,
    MatListModule,
    MatGridListModule,
    FlexLayoutModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatSelectModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatDialogModule,
    FormsModule,
    //HttpModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    HttpClientModule
  ],
  providers: [
    {provide: APP_BASE_HREF, useValue: environment.baseHref },
    ApiService,
    EnvironmentService,
    AuthService,
    AuthGuard,
    DeviceService,
    UserService,
    NotificationService,
    DialogService,
    RoleGuard,
    MessageService,
    EventService,
    SessionService,
    LocationService,
    SessionServiceV2,
    NabHumidService,
    NabTempService,
    DatePipe,
    GroupService,
    FirmwareService,
    ShadowService,
    AlertService,
    StatisticsService,
    ReminderService,
    ReminderLambdas,
    LocationLambdas,
    ApiHttpService,
    AccountsEndpointsService,
    AccountsApiService,
    AlertsEndpointsService,
    AlertsApiService,
    DeviceApiService,
    DeviceEndpointsService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
