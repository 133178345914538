import { Component } from '@angular/core';

import { RouteMap } from '../../route-map';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html'
})
export class SignupComponent {
  RouteMap = RouteMap;
}
