
import { LambdaFunction } from '../lambda-function';
import { environment } from '../../../environments/environment';
import { Reminder } from '../../models/reminder';


class GetReminder implements LambdaFunction {
  functionName: string;
  payload: object;
    constructor(type:string, federatedIdentity:string, entityId: string) {
    this.functionName = `fbgpg-reminder-${environment.stage}-reminderFetch`;
    this.payload = { body: {userId:federatedIdentity, type:type, entityId:entityId}};
  }
}

class ChangeReminderInterval implements LambdaFunction {
  functionName: string;
  payload: object;
    constructor(id:string, userId:string, type:string, interval:string, entityId:string, deviceId:string, starting:string) {
    this.functionName = `fbgpg-reminder-${environment.stage}-changeReminderInterval`;
    this.payload = {
      body: {
        id: id,
        userId: userId,
        type:type,
        interval:interval,
        entityId: entityId,
        starting: starting,
        deviceId: deviceId
      }
    };
  }
}

export class ReminderLambdas {
  constructor() {

  }

  public getReminder(type:string, federatedIdentity:string, entityId: string) : GetReminder {
    return new GetReminder(type, federatedIdentity, entityId);
  }
  public changeReminderInterval(id:string, userId:string, type:string, interval:string, entityId:string, deviceId:string, starting:string) : ChangeReminderInterval {
    return new ChangeReminderInterval(id, userId, type, interval, entityId, deviceId, starting);
  }
}