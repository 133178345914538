import { Event } from './event';
import { User } from './user';
import { Shadow } from './shadow';
import { Group } from './group';

export class Device {
  clientId: string;
  federatedIdentity: string;
  firmwareVersion: string;
  username: string;
  shadow: Shadow;
  deviceType: string;
  groupId: string;
  group: Group;
  user: any;
  details: any;
}
