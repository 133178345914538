import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

import { ListFirmware } from '../models/lambda-functions/list-firmware';
import { GetLatestFirmware } from '../models/lambda-functions/get-latest-firmware';
import { SetLatestFirmware } from '../models/lambda-functions/set-latest-firmware';
import { UpgradeFirmware } from '../models/lambda-functions/upgrade-firmware';

@Injectable()
export class FirmwareService {

  constructor(
    private apiService: ApiService
  ) {}

  async getFirmwaresByDevice(type: string): Promise<string[]> {
    const listFirmwares = new ListFirmware({deviceType: type});
    const response = await this.apiService.invokeAsPromise(listFirmwares);  
    const list = response.body.map(version => {
      return version.substring(4, version.length).replace(/_/g, '.').replace('.upg', '').replace(/^(v\d+\.\d+\.\w+)\.(.+)$/, '$1-$2');
      //return version.replace(/^vak_|ubm_|rap_|nab_|hyd_|jaz_/, '').replace(/_/g, '.').replace('.upg', '').replace(/^(v\d+\.\d+\.\w+)\.(.+)$/, '$1-$2');
    });
    if (!list.includes("None")) {
      list.unshift("None");
    }
    return list;
  }

  async getLatestFirmware(type: string): Promise<string> {
    const getLatestFirmware = new GetLatestFirmware({deviceType: type});
    const response = await this.apiService.invokeAsPromise(getLatestFirmware)
    if (response.body.firmwareVersion) {
       return response.body.firmwareVersion;
    } else {
      return "N/A";
    }
  }

  async setLatestFirmware(type: string, version: string): Promise<any> {
    const setLatestFirmware = new SetLatestFirmware({
      deviceType: type,
      firmwareVersion: version
    });
    return await this.apiService.invokeAsPromise(setLatestFirmware);
  }

  async upgradeDevices(type: string, deviceList: string[], version: string): Promise<any> {
    const upgradeFirmware = new UpgradeFirmware({
      deviceType: type,
      devices: deviceList,
      upgrade: true,
      upgradeVersion: version
    });
    return await this.apiService.invokeAsPromise(upgradeFirmware);
  }

  async upgradeGroup(type: string, group: string, version: string): Promise<any> {
    const upgradeFirmware = new UpgradeFirmware({
      deviceType: type,
      groupId: group,
      upgradeVersion: version
    });
    return await this.apiService.invokeAsPromise(upgradeFirmware);
  }

  async upgradeVersion(type: string, oldVersion: string, version: string, condition: string, limit: string): Promise<any> {
    const upgradeFirmware = new UpgradeFirmware({
      deviceType: type,
      firmwareVersion: oldVersion,
      upgradeVersion: version,
      condition: condition
    });
    return await this.apiService.invokeAsPromise(upgradeFirmware);
  }

  async doUpgradeVersion(type: string, oldVersion: string, version: string, condition: string, slimit: string): Promise<any> {
    const upgradeFirmware = new UpgradeFirmware({
      deviceType: type,
      firmwareVersion: oldVersion,
      upgradeVersion: version,
      condition: condition,
      limit: slimit,
      upgrade: true
    });
    //console.log("in doUpgradeVersion = slimit=" + slimit);  return;
    return await this.apiService.invokeAsPromise(upgradeFirmware);
  }
}
