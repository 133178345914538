import { Component, OnInit, ChangeDetectorRef, ViewChild, HostListener } from '@angular/core';
import { MatTableDataSource, } from '@angular/material/table';
import { Device } from '../../models/device';
import { DeviceService } from '../../services/device.service';
import { RouteMap } from '../../route-map';
import { DevicesTableComponent } from '../../components/devices-table/devices-table.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientStorageService, ClientStorageKey, ClientStorageValueType } from '../../services/client-storage.service';
import { GroupService } from '../../services/group.service';
import { Group } from '../../models/group';
import { AuthService } from '../../services/auth.service';
import { FormControl } from '@angular/forms';
import { loadavg } from 'os';



@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.css']
})

export class DevicesComponent implements OnInit {
  @HostListener('window:keyup', ['$event'])
  @HostListener('window:scroll', ['$event'])
  onEvent(e) {
    if (e.type === 'keyup' && e.keyCode === 13) {
      this.getDevices();
    } else if (e.type === 'scroll') {
      this.isSticky = window.pageYOffset >= 64;
    }
  }
  @ViewChild(DevicesTableComponent) devicesTable: DevicesTableComponent;
  displayedColumns: string[] = [
    'clientId',
    'deviceType',
    'username',
    'name',
    'firmware_version',
    'groupId',
    'offline',
    'externalLink'
  ];
  dataSource: MatTableDataSource<Device> = new MatTableDataSource([]);
  thingName = ''; // added just to fix --prod build error
  RouteMap = RouteMap;
  clientId = '';
  allGroups: Group[] = [];
  groups: Group[] = [];
  selectedGroup: string;
  isSticky = false;
  searchTypes = ['Serial Number', 'Email', 'Group'];
  searchType;
  deviceTypes: string[];
  selectedDeviceType: string = 'VAK';
  searchTerm : FormControl = new FormControl();
  matchingTerms : string[];
  searchHistoryList : string[] = [];

  constructor(
    private deviceService: DeviceService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private groupService: GroupService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute) {
    this.router = router;
    this.activatedRoute = activatedRoute;
    this.searchType = this.searchTypes[0];
    this.deviceTypes = this.deviceService.deviceTypes;
  }

  async ngOnInit() {
    // Working around https://github.com/angular/material2/issues/5593
    this.changeDetector.detectChanges();
    await this.authService.login();
    const queryType = ClientStorageService.get(ClientStorageKey.QueryType, ClientStorageValueType.String);
    let query;
    if (queryType === 'Group' || queryType === 'Serial Number') {
      query = ClientStorageService.get(ClientStorageKey.Query, ClientStorageValueType.Object);
    } else {
      query = ClientStorageService.get(ClientStorageKey.Query, ClientStorageValueType.String);
    }

    this.searchTerm.valueChanges.subscribe(term => {
      if(term!= '') {
        this.matchingTerms  = this.searchHistoryList.filter(item => item.includes(term))
      } else {
        this.matchingTerms = [...this.searchHistoryList];
      }
    });

    if (query && queryType) {
      this.searchType = queryType;
      this.loadSearchHistory();
      if (this.searchType === 'Group') {
        this.selectedDeviceType = query.type;
        this.selectedGroup = query.group;
      } else if (this.searchType === 'Serial Number') {
        this.selectedDeviceType = query.type;
        this.clientId = query.clientId;
      } else {
        this.clientId = query;
      }
      if (this.searchType === 'Email' || this.searchType === 'Group') {
        this.getDevices();
      }
    }

    await this.getGroups();
    if (this.selectedDeviceType) {
      await this.onDeviceTypeChanged();
    }
  }

  async onDeviceTypeChanged() {
    this.groups = [
      {nickname: "All", createdAt: new Date().getTime(), deviceType: this.selectedDeviceType, groupId: null},
      ...this.allGroups
      .filter(g => g.deviceType == this.selectedDeviceType)
      .sort((a,b) => a.nickname > b.nickname ? 1 : -1)
    ];
  }

  async getGroups() {
    this.allGroups = await this.groupService.listGroups();
  }

  keyUp = (event) => {
    if (event.keyCode === 13) {
      this.getDevices();
    }
  }

  getDevices() {
    this.clientId = this.clientId.replace(/^\s+|\s+$/gm,'');

    ClientStorageService.put(ClientStorageKey.QueryType, this.searchType);

    if (this.searchType === 'Group') {
      const query = {
        type: this.selectedDeviceType,
        group: this.selectedGroup
      };
      ClientStorageService.put(ClientStorageKey.Query, query);
    } else if(this.searchType === 'Serial Number') {
      const query = {
        clientId: this.clientId,
        type: this.selectedDeviceType
      };
      ClientStorageService.put(ClientStorageKey.Query, query);
    } else {
      ClientStorageService.put(ClientStorageKey.Query, this.clientId);
    }

    switch (this.searchType) {
      case 'Serial Number':
        this.devicesTable.getDeviceBySerialNumber(this.clientId);
        break;
      case 'Email':
        this.devicesTable.getDevicesByEmail(this.clientId);
        break;
      case 'Group':
        this.devicesTable.getDevicesByGroup(this.selectedDeviceType, this.selectedGroup);
        break;
    }

    if(this.clientId) {
      this.saveSearchHistory();
    }
  }

  private emailSearchHistoryKey = 'emailSearchHistoryKey';
  private serialNumberSearchHistoryKey = 'serialNumberSearchHistoryKey';

  loadSearchHistory() {
    let history;
    if(this.searchType == 'Serial Number') {
      history = localStorage.getItem(this.serialNumberSearchHistoryKey);
    } else if(this.searchType == 'Email') {
      history = localStorage.getItem(this.emailSearchHistoryKey);
    }
    if(history && history !== 'undefined'){
      this.searchHistoryList = JSON.parse(history);
    }
    this.matchingTerms = [...this.searchHistoryList];
  }

  searchTypeChanged() {
    this.loadSearchHistory();
    if(this.searchHistoryList.length>0) {
      this.clientId = this.searchHistoryList[0];
    }
  }

  saveSearchHistory() {
    const existing = this.searchHistoryList.lastIndexOf(this.clientId);
    if(existing != -1) {
      this.searchHistoryList.splice(existing, 1);
    }
    this.searchHistoryList.unshift(this.clientId);
    const max = this.searchHistoryList.length > 9 ? 9 : this.searchHistoryList.length;
    this.searchHistoryList = this.searchHistoryList.splice(0, max);

    if(this.searchType == 'Serial Number') {
      const saved = JSON.stringify(this.searchHistoryList);
      localStorage.setItem(this.serialNumberSearchHistoryKey, saved);
      const history = localStorage.getItem(this.serialNumberSearchHistoryKey);
    } else if(this.searchType == 'Email') {
      const saved = JSON.stringify(this.searchHistoryList);
      localStorage.setItem(this.emailSearchHistoryKey, saved);
    }
  }


}
