import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

/**
 * This class needs to exist in every app, it's just a wrapper for the access to env vars.
 */
@Injectable()
export class EnvironmentService {
  public get = (key: string): any => {
    return environment[key];
  }
}
