import { Component, Inject, Input, SimpleChange, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Device } from '../../models/device';
import { DeviceService } from '../../services/device.service'
import { PubSub } from 'aws-amplify';
import { NotificationService } from '../../services/notification.service';
import { ReminderService } from '../../services/reminder.service';
import { Reminder } from '../../models/reminder';
import {Subject} from 'rxjs';

interface Settings {
  // start Vak settings
  sensorDisable: boolean;
  handleReverse: boolean;
  batterySavingLevel: string;
  freezeEnable: boolean;
  defaultTemp: string;
  defaultFlowRate: number;
  maxFlowRate: number;
  purgeTimeout: number;
  handleTimeout: number;
  sensorTimeout: number;
  voiceTimeout: number;
  safetyLimitTemp: number;
  safetyModeEnabled: boolean;
  childLimitTemp: number;
  childModeEnabled: boolean;
  wifiNoPoll: boolean;
  devDevice: boolean;
  gestureMode: string;
  // start naboo attributes
  beeperPiezoEnable: boolean;
  beeperSirenEnable: boolean;
  crockDiameterMM: number;
  crockBackup: string;
  crockBckTst: string;
  //crockState: string;
  crockTofHeight: number;
  tempLowThreshold: number;
  tempHighThreshold: number;
  humidLowThreshold: number;
  humidHighThreshold: number;
  dropEnabled: boolean;
  crockMonitorOn: boolean;

}

@Component({
  selector: 'app-device-settings-dialog',
  templateUrl: './device-settings-dialog.component.html',
  styleUrls: ['./device-settings-dialog.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class DeviceSettingsDialogComponent {
  @Input() device: Device;
  settings: Settings = {
    sensorDisable: false,
    handleReverse: false,
    batterySavingLevel: 'off',
    freezeEnable: false,
    defaultTemp: 'handle',
    defaultFlowRate: 100,
    maxFlowRate: 100,
    purgeTimeout: 60,
    handleTimeout: 60,
    sensorTimeout: 60,
    voiceTimeout: 60,
    safetyLimitTemp: 49,
    safetyModeEnabled: false,
    childLimitTemp: 41,
    childModeEnabled: false,
    wifiNoPoll: false,
    devDevice: false,
    gestureMode: 'simple',
    // start naboo specific settings
    beeperPiezoEnable: false,
    beeperSirenEnable: false,
    crockDiameterMM: 700,
    crockBackup: 'none',
    crockBckTst: 'none',
    //crockState: 'normal',
    crockTofHeight: -1,
    tempLowThreshold: 5, // C
    tempHighThreshold: 32, // C
    humidLowThreshold: 0, // %
    humidHighThreshold: 80, // %
    dropEnabled: false,
    crockMonitorOn: false

  };
  saveDesiredShadow = ((desired: any) => this.setDesired(desired));
  saveClicked: Subject<any> = new Subject();

  temperatures = [
    {value: 'handle', displayedValue: 'Handle'},
    {value: 'hottest', displayedValue: 'Hot'},
    {value: 'coldest', displayedValue: 'Cold'},
    {value: 'equal', displayedValue: 'Equal'},
    {value: 'last', displayedValue: 'Last'}
  ];

  gestures = [
    { value: 'simple', displayedValue: 'Simple'},
    { value: 'mode_one', displayedValue: 'Mode One'},
    { value: 'mode_two', displayedValue: 'Mode Two'},
    { value: 'mode_three', displayedValue: 'Mode Three'},
    { value: 'mode_four', displayedValue: 'Mode Four'},
    { value: 'mode_five', displayedValue: 'Mode Five'}
  ];

  batterySavingLevels = [
    {value: 'off', displayedValue: 'Off'},
    {value: 'low', displayedValue: 'Low'},
    {value: 'high', displayedValue: 'High'}
  ];

  crockBackupOptions = [
    {value: 'none', displayedValue: 'None'},
    {value: 'present', displayedValue: 'Present'}
  ];

  crockBackupTestOptions = [
    {value: 'none', displayedValue: 'None'},
    {value: 'scheduled', displayedValue: 'Scheduled'}
  ];

  crockBackupTestSchedule = [
    {value: 'disabled', displayedValue: 'Disabled' },
    {value: 'monthly', displayedValue: 'Monthly' },
    {value: 'quarterly', displayedValue: 'Quarterly' }
  ];

  batteryReminderFrequency = [
    {value: 'quarterly', displayedValue: 'Quarterly' },
    {value: 'semiannually', displayedValue: 'Semi-Annually' },
    {value: 'annually', displayedValue: 'Annually' }
  ];
  batteryReminderStartMonth = [
    {value: '00', displayedValue: 'January' },
    {value: '01', displayedValue: 'February' },
    {value: '02', displayedValue: 'March' },
    {value: '03', displayedValue: 'April' },
    {value: '04', displayedValue: 'May' },
    {value: '05', displayedValue: 'June' },
    {value: '06', displayedValue: 'July' },
    {value: '07', displayedValue: 'August' },
    {value: '08', displayedValue: 'September' },
    {value: '09', displayedValue: 'October' },
    {value: '10', displayedValue: 'November' },
    {value: '11', displayedValue: 'December' }
  ];

  backupBatteryReminder = {
    batteryWaterLevelCheckReminder: "quarterly",
    startMonth: "00"
  };
  handleMinutes = [1, 2, 5, 15, 60];
  voiceMinutes = [1, 2, 5, 10, 15];
  sensorMinutes = [1, 2, 5, 10, 15];
  purgeMinutes = [1, 2, 5];
  // Handle: 1m / 2m / 5m / 15m / 60m
  // Voice : 1m / 2m / 5m / 10m / 15m
  // Sensor: 1m / 2m / 5m / 10m / 15m
  // Purge(Command): 1m / 2m / 5m

  crockDiameterSliderMin = 305;
  crockDiameterSliderMax = 1220;
  crockDiameterSliderStep = 25;

  crockTOFHeightSliderMin = -1;
  crockTOFHeightSliderMax = 1000;
  crockTOFHeightSliderStep = 5;

  tempThreshSliderMin = 0;
  tempThreshSliderMax = 100;
  tempThreshSliderStep = 1;

  humidityThreshSliderMin = 0;
  humidityThreshSliderMax = 100;
  humidityThreshSliderStep = 1;


  constructor(
    private notificationService: NotificationService,
    private reminderService: ReminderService,
    private deviceService: DeviceService
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.device.currentValue) {
      this.device = changes.device.currentValue;
      this.settings.sensorDisable = this.device.shadow.sensorDisable === 0 ? false : true;
      this.settings.handleReverse = this.device.shadow.handleReverse;
      this.settings.freezeEnable = this.device.shadow.freezeEnable;
      this.settings.defaultTemp = this.device.shadow.defaultTemp;
      this.settings.defaultFlowRate = this.device.shadow.defaultFlowRate;
      this.settings.maxFlowRate = this.device.shadow.maxFlowRate;
      this.settings.purgeTimeout = this.device.shadow.purgeTimeout / 60;
      this.settings.handleTimeout = this.device.shadow.handleTimeout / 60;
      this.settings.sensorTimeout = this.device.shadow.sensorTimeout / 60;
      this.settings.voiceTimeout = this.device.shadow.voiceTimeout / 60;
      this.settings.safetyLimitTemp = Math.round(this.device.shadow.safetyLimitTemp * 10) / 10;
      this.settings.safetyModeEnabled = this.device.shadow.safetyModeEnabled;
      this.settings.childLimitTemp = Math.round(this.device.shadow.childLimitTemp * 10) / 10;
      this.settings.childModeEnabled = this.device.shadow.childModeEnabled;
      this.settings.wifiNoPoll = this.device.shadow.wifiNoPoll;
      this.settings.devDevice = this.device.shadow.devDevice;
      this.settings.gestureMode = this.device.shadow.gestureMode;
      // start naboo settings update
      this.settings.beeperPiezoEnable = this.device.shadow.beeperPiezoEnable;
      this.settings.beeperSirenEnable = this.device.shadow.beeperSirenEnable;
      this.settings.crockDiameterMM = this.device.shadow.crockDiameterMM;
      this.settings.crockBackup = this.device.shadow.crockBackup;
      this.settings.crockBckTst = this.device.shadow.crockBckTst;
      //this.settings.crockState = this.device.shadow.crockState;
      this.settings.crockTofHeight = this.device.shadow.crockTofHeight;
      this.settings.tempHighThreshold = this.device.shadow.tempHighThreshold;
      this.settings.tempLowThreshold = this.device.shadow.tempLowThreshold;
      this.settings.humidHighThreshold = this.device.shadow.humidHighThreshold;
      this.settings.humidLowThreshold = this.device.shadow.humidLowThreshold;
      this.settings.crockMonitorOn = this.device.shadow.crockTofDistance !== undefined && this.device.shadow.crockTofDistance !== -1;
      this.settings.dropEnabled = this.device.shadow.droplet && this.device.shadow.droplet['level'] !== -1;

      if(!this.device.details) {
        this.device.details = {};
      }
      if(!this.device.details.pumpInfo) {
        this.device.details.pumpInfo = {};
      }

      if(this.device.details.pumpInfo.backup) {
        this.backupBatteryReminder.batteryWaterLevelCheckReminder = this.device.details.pumpInfo.backup.batteryWaterLevelCheckReminder;
      }

    }
  }

  save(): void {
    let desired = {};
    if (this.device.deviceType === 'VAK') {
      desired = {
        sensorDisable: this.settings.sensorDisable ? 1 : 0,
        handleReverse: this.settings.handleReverse,
        batterySavingLevel: this.settings.batterySavingLevel,
        freezeEnable: this.settings.freezeEnable,
        defaultTemp: this.settings.defaultTemp,
        defaultFlowRate: this.settings.defaultFlowRate,
        maxFlowRate: this.settings.maxFlowRate,
        purgeTimeout: this.settings.purgeTimeout * 60,
        handleTimeout: this.settings.handleTimeout * 60,
        sensorTimeout: this.settings.sensorTimeout * 60,
        voiceTimeout: this.settings.voiceTimeout * 60,
        safetyLimitTemp: this.settings.safetyLimitTemp,
        safetyModeEnabled: this.settings.safetyModeEnabled,
        childLimitTemp: this.settings.childLimitTemp,
        childModeEnabled: this.settings.childModeEnabled,
        gestureMode: this.settings.gestureMode,
        wifiNoPoll: this.settings.wifiNoPoll
      };
      this.setDesired(desired);
    } else if (this.device.deviceType === 'NAB') {
      desired = {
        beeperPiezoEnable: this.settings.beeperPiezoEnable,
        beeperSirenEnable: this.settings.beeperSirenEnable,
        crockDiameterMM: this.settings.crockDiameterMM,
        crockBackup: this.settings.crockBackup,
        crockBckTst: this.settings.crockBckTst,
        //crockState: this.settings.crockState,
        crockTofHeight: this.settings.crockTofHeight,
        tempLowThreshold: this.settings.tempLowThreshold,
        tempHighThreshold: this.settings.tempHighThreshold,
        humidLowThreshold: this.settings.humidLowThreshold,
        humidHighThreshold: this.settings.humidHighThreshold
      };
      this.updateDeviceAttributes();
      this.setDesired(desired);
    } else {
      this.saveClicked.next({});
      desired = {};
    }

  }

  setDesired(desired:any) {
    const shadow = {
      state: {
        desired: {}
      }
    };

    shadow.state.desired = desired;
    PubSub.publish(`$aws/things/${this.device.clientId}/shadow/update`, shadow);
    this.notificationService.show('Settings saved successfully');
  }

  updateDeviceAttributes() {
    //const device = this.device.user.devices.find(dev => dev.clientId==this.device.clientId);
    //console.log(device);
    const dataType = "pumpInfo";
    let pumpInfo = this.device.details.pumpInfo;
    if(!pumpInfo) {
      pumpInfo = {};
    }

    if(this.settings.crockBackup == 'present') {
      pumpInfo.hasBackupPump = true;
      if(!pumpInfo.backup) {
        pumpInfo.backup = { };
        pumpInfo.backup.batteryWaterLevelCheckReminder = '';
        pumpInfo.backup.installDate = '';
        pumpInfo.backup.model = '';
        pumpInfo.backup.manufacturer = '';
        pumpInfo.backup.batteryNeedsWater = '';
        pumpInfo.backup.pumpTestFrequency = '';
      }
    } else {
      pumpInfo.hasBackupPump = false;
    }
    this.device.details.pumpInfo = pumpInfo;

    //device.pumpInfo = pumpInfo;
    try {
      this.deviceService.updateDeviceAttributes(this.device.clientId, this.device.federatedIdentity, dataType, this.device.details.pumpInfo);
    } catch (error) {
      console.error(error);
    }
  }

  async updateBackupBatteryReminder() : Promise<void> {
    try {
      await this.updateBatteryReminder(this.backupBatteryReminder);
    } catch(err){
      console.error(err);
    }
  }

  private async updateBatteryReminder(backupBatteryReminder) : Promise<void> {
    //TODO This will need an enum of reminder types or something along those lines.
    const type = "NAB:battery_reminder";
    const userId = this.device.user.federatedIdentity;
    const entityId = this.device.clientId;
    const deviceId = entityId;

    const rem : Reminder[] = await this.reminderService.getReminder(type, userId, entityId);

    const id = rem[0].id;
    const interval = backupBatteryReminder.batteryWaterLevelCheckReminder;
    const d = new Date();
    let starting = new Date(d.getFullYear(), Number.parseInt(backupBatteryReminder.startMonth), 1, 0, 0, 0, 0);
    console.debug(starting);
    if(starting > d) {
      console.debug("Updating Starting Date");
      starting = new Date(d.getFullYear()-1, Number.parseInt(backupBatteryReminder.startMonth), 1, 0, 0, 0, 0)
      console.debug(starting);
    }
    await this.reminderService.changeReminderInterval(id, userId, type, interval, entityId, deviceId, starting);
  }

  private cToF(celsius: number): number {
    return Math.round((celsius * (9 / 5)) + 32);
  }
}
