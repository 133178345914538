import {Component, ViewEncapsulation} from '@angular/core';
import {Input, OnInit} from '@angular/core';
import { Zone } from '../../../models/hyd/zone';
import { SoilSensor } from '../../../models/hyd/soil-sensor';
import {Shadow} from '../../../models/shadow';

@Component({
  selector: 'app-zones-hyd-table',
  templateUrl: './zones-hyd-table.component.html',
  styleUrls: ['./zones-hyd-table.component.css'],


  // encapsulation none allows for resizing of the mat-tab widths.
  encapsulation: ViewEncapsulation.None
})
export class ZonesHydTableComponent implements OnInit {
  @Input() shadow: Shadow;

  zones: Zone[];
  constructor() {

  }

  ngOnInit(): void {
    const zones = this.shadow['zones'];

    // Convert shadow key/value map to object
    this.zones = Object.keys(zones).map(key => ({zoneID: key, ...zones[key], soilSensors: Object.keys(zones[key].soilSensors).map(sKey => ({ sensorID: sKey, ...zones[key].soilSensors[sKey]})) }));

  }
}

