import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent {
  public title: string;
  public message: SafeHtml;
  public confirmBtnLabel: string;
  public cancelMessage: boolean;
  public showCancelButton: boolean = true;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) { }

}
