import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { RouteMap } from '../../route-map';
import { EnvironmentService } from '../../services/environment.service';

@Component({
  selector: 'app-login',
  template: '',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  RouteMap = RouteMap;

  constructor(
    private authService: AuthService,
    private environmentService: EnvironmentService,
    private router: Router) {
  }

  async ngOnInit() {
    try {
      const user = await this.authService.currentUser();
      console.log(user);
      if (user) {
        this.router.navigate([RouteMap.devices.absolute]);
        return;
      }
    } catch (error) {
      console.log(error);
    }

    const authUrl = !this.environmentService.get('isChina') ? 
    `https://${this.environmentService.get('oauthDomain')}/oauth2/authorize?client_id=${this.environmentService.get('userPoolClientId')}&response_type=code&scope=email+openid+profile+aws.cognito.signin.user.admin&redirect_uri=${this.environmentService.get('oauthRedirectSignIn')}`
    : 
    `https://${this.environmentService.get('oauthDomain')}/oidc/auth?client_id=${this.environmentService.get('authingUserPoolClientId')}&response_type=code&scope=email+openid+profile+aws.cognito.signin.user.admin&redirect_uri=${this.environmentService.get('oauthRedirectSignIn')}`;
    window.location.replace(authUrl);
  }
}
