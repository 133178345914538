import { SoilSensor } from './soil-sensor';
import {Zone} from './zone';

export class RainSensorType {
  public constructor(init?: Partial<RainSensorType>) {
    Object.assign(this, init);
  }
  displayValue: string;
  value: string;
}
export class FlowSensor {
  get Offset(): number {
    return this._Offset;
  }
  set Offset(value: number) {
    this._Offset = value;
  }
  get KFactor(): number {
    return this._KFactor;
  }
  set KFactor(value: number) {
    this._KFactor = value;
  }
  get Connected(): boolean {
    return this._Connected;
  }
  set Connected(value: boolean) {
    this._Connected = value;
  }

  public constructor () {

  }

  private _Connected: boolean = false;//default to false
  private _KFactor: number = 0;//pulses per liter
  private _Offset: number = 0;//Offset in uL(micro liters)
}

export class HydControlSettings {
  get SchedulesLoaded(): string[] {
    return this._SchedulesLoaded;
  }

  set SchedulesLoaded(value: string[]) {
    this._SchedulesLoaded = value;
  }

  get NumZones(): any {
    return this._NumZones;
  }

  set NumZones(value: any) {
    this._NumZones = value;
  }

  get Zones(): Zone[] {
    return this._Zones;
  }

  set Zones(value: Zone[]) {
    this._Zones = value;
  }

  get SchedulesMax(): number {
    return this._SchedulesMax;
  }

  set SchedulesMax(value: number) {
    this._SchedulesMax = value;
  }

  get FlowSensor(): FlowSensor {
    return this._FlowSensor;
  }

  set FlowSensor(value: FlowSensor) {
    this._FlowSensor = value;
  }

  get LostSoilSensorIds(): any {
    return this._LostSoilSensorIds;
  }

  set LostSoilSensorIds(value: any) {
    this._LostSoilSensorIds = value;
  }

  get WorkingSoilSensorIds(): any {
    return this._WorkingSoilSensorIds;
  }

  set WorkingSoilSensorIds(value: any) {
    this._WorkingSoilSensorIds = value;
  }

  get shadowKeys(): string[] {
    return this._shadowKeys;
  }

  set shadowKeys(value: string[]) {
    this._shadowKeys = value;
  }

  public get WaterScheduleID(): string {
    return this._WaterScheduleID;
  }

  public set WaterScheduleID(value: string) {
    this._WaterScheduleID = value;
  }

  public constructor() {
  }

  public get RainSensorTypes() {
    return HydControlSettings._RainSensorTypes;
  }

  public get SeasonalAdjustments() {
    return this._seasonalAdjustments;
  }

  get RainSensorConnected(): boolean {
    return this._RainSensorConnected;
  }

  set RainSensorConnected(value: boolean) {
    this._RainSensorConnected = value;
  }

  get MasterValveConnected(): boolean {
    return this._MasterValveConnected;
  }

  set MasterValveConnected(value: boolean) {
    this._MasterValveConnected = value;
  }

  get WaterEnabled(): boolean {
    return this._waterEnable;
  }

  set WaterEnabled(value: boolean) {
    this._waterEnable = value;
  }

  public get RainSensorType(): string {
    return this._RainSensorType;
  }

  public set RainSensorType(value: string) {
    this._RainSensorType = value;
  }

  private static readonly _RainSensorTypes = [
    new RainSensorType({value: 'nc', displayValue: 'Normally Closed'}),
    new RainSensorType({value: 'no', displayValue: 'Normally Opened'})
  ];
  private _SchedulesLoaded: string[] = [];
  private _NumZones: any;
  private _Zones: Zone[] = [];
  private _SchedulesMax: number;
  private _FlowSensor: FlowSensor = new FlowSensor();
  private _LostSoilSensorIds: any;
  private _WorkingSoilSensorIds: any;

  private _shadowKeys = ['scheduler', 'waterEnable', 'waterScheduleID', 'masterValveConnected', 'rainSensorConnected',
    'rainSensorType', 'seasonalAdjust', 'soilSensors', 'soilSensorsLost', 'flowSensor', 'schedulesMax',
    'schedulesLoaded', 'numZones', 'zones'];
  private _WaterScheduleID: string;
  private _seasonalAdjustments = [
    {month: 'January', value: 0},
    {month: 'February', value: 0},
    {month: 'March', value: 0},
    {month: 'April', value: 0},
    {month: 'May', value: 0},
    {month: 'June', value: 0},
    {month: 'July', value: 0},
    {month: 'August', value: 0},
    {month: 'September', value: 0},
    {month: 'October', value: 0},
    {month: 'November', value: 0},
    {month: 'December', value: 0},
  ];


  private _waterEnable = true;
  private _MasterValveConnected = false;
  private _RainSensorConnected = false;
  private _RainSensorType: string = HydControlSettings._RainSensorTypes[0].value;

  public loadShadow(shadow) {
    if (shadow === null || shadow === undefined) {
      return;
    }

    if (shadow['scheduler']) {
    }

    if (shadow['waterEnable']) {
      this.WaterEnabled = shadow['waterEnable'];
    }

    if (shadow['waterScheduleID']) {
      this.WaterScheduleID = shadow['waterScheduleID'];
    }
    if (shadow['masterValveConnected']) {
      this.MasterValveConnected = shadow['masterValveConnected'];
    }
    if (shadow['rainSensorConnected']) {
      this.RainSensorConnected = shadow['rainSensorConnected'];
    }
    if (shadow['rainSensorType']) {
      this.RainSensorType = shadow['rainSensorType'];
    }
    if (shadow['seasonalAdjust']) {
      for (let i = 0; i < 12; i++) {
        this.SeasonalAdjustments[i].value = shadow['seasonalAdjust'][i];
      }
    }
    if (shadow['soilSensors']) {
      this.WorkingSoilSensorIds = shadow['soilSensors'];
      /*for(let sensKeys in Object.keys(shadow['soilSensors'])) {
        this.SoilSensors = shadow['soilSensors'];
      }*/
    }
    if (shadow['soilSensorsLost']) {
      this.LostSoilSensorIds = shadow['soilSensorsLost'];
    }
    if (shadow['flowSensor']) {
      this.FlowSensor = new FlowSensor();
      this.FlowSensor.KFactor = shadow['flowSensor']['kFactor'];
      this.FlowSensor.Connected = shadow['flowSensor']['connected'];
      this.FlowSensor.Offset = shadow['flowSensor']['offset'];
    }
    if (shadow['schedulesMax']) {
      this.SchedulesMax = shadow['schedulesMax'];
    }
    if (shadow['schedulesLoaded']) {
      this.SchedulesLoaded = shadow['schedulesLoaded'];
    }
    if (shadow['numZones']) {
      this.NumZones = shadow['numZones'];
    }
    if (shadow['zones']) {
      //this.Zones = shadow['zones'];
      for (let zoneId of Object.keys(shadow['zones'])) {
        let rawZone = shadow['zones'][zoneId];
        let zone = new Zone();
        zone.zoneID = zoneId;
        zone.name = rawZone['name'];
        zone.enabled = rawZone['enabled'];
        zone.auditStatus = rawZone['auditStatus'];
        zone.auditEnable = rawZone['auditEnable'];
        zone.flowMaxThresh = rawZone['flowMaxThresh'];
        zone.flowMinThresh = rawZone['flowMinThresh'];
        zone.skipOnRainSensor = rawZone['skipOnRainSensor'];
        zone.skipReason = rawZone['skipReason'];
        zone.skipReasonStartTime = rawZone['skipReasonStartTime'];
        zone.skipReasonStopTime = rawZone['skipReasonStopTime'];
        zone.soakRun = rawZone['soakRun'];
        zone.soakDelay = rawZone['soakDelay'];
        zone.soilLogic = rawZone['soilLogic'];
        for (let sensIndex of Object.keys(rawZone['soilSensors'])) {
          let rawSens = rawZone['soilSensors'][sensIndex];
          let sens = new SoilSensor();
          sens.sensorID = sensIndex;
          sens.address = rawSens['address'];
          sens.tempThresh = rawSens['tempThresh'];
          sens.depth1Thresh = rawSens['depth1Thresh'];
          sens.depth3Thresh = rawSens['depth3Thresh'];
          sens.depth5Thresh = rawSens['depth5Thresh'];
          zone.soilSensors.push(sens)
        }
        this.Zones.push(zone);
      }
    }
  }

  public toDesiredShadow() {
    const desired = {
      waterEnable:  this.WaterEnabled,
      waterScheduleID: this.WaterScheduleID,
      masterValveConnected: this.MasterValveConnected,
      rainSensorConnected: this.RainSensorConnected,
      rainSensorType: this.RainSensorType,
      seasonalAdjust: [],
      //soilSensors: this.WorkingSoilSensorIds,
      //soilSensorsLost: this.LostSoilSensorIds,
      flowSensor: {
        kFactor: this.FlowSensor.KFactor,
        connected: this.FlowSensor.Connected,
        offset: this.FlowSensor.Offset,
      },
      schedulesMax: this.SchedulesMax,
      schedulesLoaded: this.SchedulesLoaded,
      numZones: this.NumZones,
      zones: {}

    };

    for (let i = 0; i < 12; i++) {
      desired.seasonalAdjust[i] = this.SeasonalAdjustments[i].value;
    }

    for(let zone of this.Zones) {
      const soilSensors = {};
      for(let soilSensor of zone.soilSensors) {
        soilSensors[soilSensor.sensorID] = {
          address: soilSensor.address,
          tempThresh: soilSensor.tempThresh,
          depth1Thresh: soilSensor.depth1Thresh,
          depth3Thresh: soilSensor.depth3Thresh,
          depth5Thresh: soilSensor.depth5Thresh
        }
      }

      desired.zones[zone.zoneID] = {
        name: zone.name,
        enabled: zone.enabled,
        auditStatus: zone.auditStatus,
        auditEnable: zone.auditEnable,
        flowMaxThresh: zone.flowMaxThresh,
        flowMinThresh: zone.flowMinThresh,
        skipOnRainSensor: zone.skipOnRainSensor,
        skipReason: zone.skipReason,
        skipReasonStartTime: zone.skipReasonStartTime,
        skipReasonStopTime: zone.skipReasonStopTime,
        soakRun: zone.soakRun,
        soakDelay: zone.soakDelay,
        soilLogic: zone.soilLogic,
        soilSensors: soilSensors
      }
    }
    return desired;
  }
}

