import { Component } from '@angular/core';
import { Hub } from 'aws-amplify';
import { AuthService } from '../../services/auth.service';
import { Auth } from 'aws-amplify';
import { EnvironmentService } from '../../services/environment.service';

@Component({
  template: ''
})
export class SSOCallbackComponent {
  constructor(
    private authService: AuthService,
    private environmentService: EnvironmentService
  ) {
    Hub.listen('auth', this, 'AuthCodeListener');
  }

  onHubCapsule(capsule) {
    const { channel, payload } = capsule;
    console.log(capsule);
    if (channel === 'auth' && payload.event === 'signIn') {
      window.location.assign(`${this.environmentService.get('baseHref')}devices`);
    }
  }
}
