import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../services/auth.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
  constructor(private router: Router,
              private notificationService: NotificationService,
              private authService: AuthService) { }

  ngOnInit() {
    /**
     * The authenticated state changes from within a child component causing angular to think
     * the app component could be in an unstable state, as the change wasn't top-down.
     * The faux promise bypasses this error check and maintains the app stable.
     * https://blog.angularindepth.com/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error-e3fd9ce7dbb4
     */
    this.authService.logout();
  }
}
