import { Component, Input, SimpleChanges, Inject, ViewChild } from '@angular/core';
import { Device } from '../../models/device';
import { NabTempService } from '../../services/nab-temp.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ApiService } from '../../services/api.service';
import { saveAs } from 'file-saver'

//SATTODO
import { DeleteSessionData } from '../../models/lambda-functions/delete-session';
import { NotificationService } from '../../services/notification.service';
import { NabTemp } from '../../models/nab-temp';
import { ClientStorageKey, ClientStorageService, ClientStorageValueType } from '../../services/client-storage.service';
import { DatePipe } from '@angular/common';

import * as columnify from 'columnify';

interface DialogData {
  clientId: string;
  confirm: boolean;
}

@Component({
  selector: 'app-nab-temp-table',
  styleUrls: ['./nab-temp-table.component.css'],
  templateUrl: './nab-temp-table.component.html'
})
export class NabTempTableComponent {
  private paginator: MatPaginator;
  private sort: MatSort;
  @Input() device: Device;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  displayedColumnsTemp = [
    'date',
    'tempC',
    'tempState'
  ];

  displayedColumnsHumid = [
    'date',
    'humid',
    'humidState'
  ];

  dataSource = new MatTableDataSource<any>([]);
  isLoadingSession = false;
  useCelsius = false;
  lastEvaluatedKey = null;

  constructor (private apiService: ApiService,
               private tempSessionService: NabTempService,
               private notificationService: NotificationService,
               private datePipe: DatePipe,
               private dialog: MatDialog) {}

  ngOnInit() {
    const storedTempPref = ClientStorageService.get(ClientStorageKey.PreferCelsius, ClientStorageValueType.String);
    if (storedTempPref && storedTempPref === 'true') {
      this.useCelsius = true;
    } else if (storedTempPref && storedTempPref === 'false') {
      this.useCelsius = false;
    } else {
      ClientStorageService.put(ClientStorageKey.PreferCelsius, 'false');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.device.currentValue) {
      this.getNabSessionData();
    }
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  temperaturePreferenceUpdated() {
    if (this.useCelsius) {
      ClientStorageService.put(ClientStorageKey.PreferCelsius, 'true');
      const tempData = this.dataSource.data;
      this.dataSource.data = tempData.map(temp => {
        temp.tempC = this.convertTempFToC(temp.tempC);
        return temp;
      });
    } else {
      ClientStorageService.put(ClientStorageKey.PreferCelsius, 'false');
      const tempData = this.dataSource.data;
      this.dataSource.data = tempData.map(temp => {
        temp.tempC = this.convertTempCToF(temp.tempC);
        return temp;
      });
    }
    // this.getSessionData();
  }
  public getNabSessionData() {
    this.isLoadingSession = true;
    this.tempSessionService.getSessionDataForThing(this.device.clientId)
      .then(sessions => {
        if (!this.useCelsius) {
          this.dataSource.data = sessions.Items.map(session => {
            session.tempC = this.convertTempCToF(session.tempC);
            return session;
          });
        } else {
          this.dataSource.data = sessions.Items;
        }

        if ( sessions.LastEvaluatedKey ) {
          this.lastEvaluatedKey = sessions.LastEvaluatedKey;
        } else {
          this.lastEvaluatedKey = null;
        }
      //  console.log("lastEvaluatedKey="+JSON.stringify(this.lastEvaluatedKey));
      //  console.log("datasource.size="+this.dataSource.data.length);
        this.isLoadingSession = false;
      })
      .catch(error => {
        console.error(error);
      });
  }

  changePage(event) {
    if (event.pageIndex * event.pageSize >= event.length - event.pageSize) {
      if (this.lastEvaluatedKey !== null) {
          this.getNextSessionData();
      }  else {
        // console.log("nothing to get");
      }
    }
  }

  async getNextSessionData() {

    this.isLoadingSession = true;

    this.tempSessionService.getNextSessionDataForThing(this.device.clientId, this.lastEvaluatedKey)
      .then(sessions => {
        this.dataSource.data = this.dataSource.data.concat(sessions.Items);

        if ( sessions.LastEvaluatedKey ) {
          this.lastEvaluatedKey = sessions.LastEvaluatedKey;
        } else {
          this.lastEvaluatedKey = null;
        }

        this.isLoadingSession = false;
      })
      .catch(error => {
        console.error(error);
      });

  }

  public dumpNabSessionData() {
    const text = this.dataSource.data
      .sort((a, b) => {
        return this.compare(new Date(a.timestamp), new Date(b.timestamp), true);
      })
      .map(session => {
        session.date = this.datePipe.transform(session.timestamp, 'medium');
        return session;
      });
    let columns = columnify(text, {
      columnSplitter: '|'
    });
    const platform = window.navigator.platform;
    const windows = ['Win32', 'Win64', 'Windows', 'WinCE'];
    if (windows.includes(platform)) {
      columns = columns.replace(/\n/g, '\r\n');
    }
    const blob = new Blob([columns], {type: 'text/plain;carset=utf-8'});
    saveAs(blob, `humidity_${this.device.clientId}.txt`);
  }

  public sortData = (sort: Sort) => {
    const data = this.dataSource.data;
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'date': return this.compare(new Date(a.timestamp), new Date(b.timestamp), isAsc);
        case 'tempC': return this.compare(a.tempC, b.tempC, isAsc);
        case 'tempState': return this.compare(a.tempState, b.tempState, isAsc);
      }
    });
  }


  public temperatureBasedName(baseName) {
    if (this.useCelsius) {
      return `${baseName} (°C)`;
    } else {
      return `${baseName} (°F)`;
    }
  }

  private compare = (a, b, isAsc) => {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private convertTempCToF(celsius) {
    return (celsius * 1.8) + 32;
  }

  private convertTempFToC(fahren) {
    return (fahren - 32) / 1.8;
  }

  private roundTemperature(input) {
    return Math.round(input * 10) / 10 ;
  }

  private formatNumber(input) {
     let output = input.toString();
     if ( output.indexOf('.') <= 0) {
       output = output + '.0';
     }
     return output;
  }

  private roundTemperatureHottestColdest(input) {
    let input2 = this.roundTemperature(input);
    let output;

    if (this.useCelsius) {
      if (input2 === 0) { return 'coldest'; } else {
        if (input2 === 100) { return 'hottest'; } else {
          output = this.formatNumber(this.roundTemperature(input));
          if (output) {
          return output;
          }  }
      }
    } else {
      if (input2 === 32) { return 'coldest'; } else {
        if (input2 === 212) { return 'hottest'; } else { output = this.formatNumber(this.roundTemperature(input));
          return output;
        }
      }
    }
  }

  roundMsDuration(input) {

    let seconds = input / 1000;
    if (input === undefined) {
      seconds = 0.0;
   }
    return Math.round(seconds * 10) / 10;
  }
}
