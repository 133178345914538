import {DashboardControlActions} from '../../core/models/dashboard-control-actions';
import {DashboardAction} from '../../core/models/dashboard-action';

export class HydCommands extends DashboardControlActions {
  shadow: any = {};

  constructor(clientId: string) {
    super();
    this.clientId = clientId;

    this.AddAction(new DashboardAction({name: 'reset', displayName: 'Reset', action: () => this.reset()}));
    this.AddAction(new DashboardAction({name: 'runManual', displayName: 'Run Manual', action: () => this.runManual()}));
    this.AddAction(new DashboardAction({name: 'skipZone', displayName: 'Skip Zone', action: () => this.skipZone() }));
    this.AddAction(new DashboardAction({name: 'cancel', displayName: 'Cancel', action: () => this.cancel()}));
    this.AddAction(new DashboardAction({name: 'refresh', displayName: 'Refresh', action: () => this.refresh()}));
    this.AddAction(new DashboardAction({name: 'zoneAudit', displayName: 'Zone Audit', action: () => this.zoneAudit()}));
    this.AddAction(new DashboardAction({name: 'pause', displayName: 'Pause (10 Seconds)', action: () => this.pause(10) }));
    this.AddAction(new DashboardAction({name: 'pause', displayName: 'Pause (60 Seconds)', action: () => this.pause(60) }));
    this.AddAction(new DashboardAction({name: 'pause', displayName: 'Pause (2 Minutes)', action: () => this.pause(120) }));
    this.AddAction(new DashboardAction({name: 'provisionSoilSensor', displayName: 'Provision Soil Sensor (60 Seconds)', action: () => this.provisionSoilSensor(60) }));
  }

  // Hydra Actions
  /**
   * scheduler: { command: "reset", src: "dashbd" }
   */
  reset() {
    this.shadow.scheduler = {};
    this.shadow.scheduler.command = 'reset';
    this.shadow.scheduler.src = 'dashbd';
    this.updateShadow(this.shadow);
  }

  /**
   * scheduler: { command: "run_manual", src: "dashbd" }
   */
  runManual() {
    this.shadow.scheduler = {};
    this.shadow.scheduler.command = 'run_manual';
    this.shadow.scheduler.src = 'dashbd';
    this.updateShadow(this.shadow);
  }

  /**
   * scheduler: { command: "skip_zone", zoneID: ## src: "dashbd" }
   */
  skipZone() {
    this.shadow.scheduler = {};
    this.shadow.scheduler.command = 'skip_zone';
    this.shadow.scheduler.src = 'dashbd';
    this.updateShadow(this.shadow);
  }

  cancel() {
    this.shadow.scheduler = {};
    this.shadow.scheduler.command = 'cancel';
    this.shadow.scheduler.src = 'dashbd';
    this.updateShadow(this.shadow);
  }

  refresh() {
    this.shadow.scheduler = {};
    this.shadow.scheduler.command = 'refresh';
    this.shadow.scheduler.src = 'dashbd';
    this.updateShadow(this.shadow);
  }

  zoneAudit() {
    this.shadow.scheduler = {};
    this.shadow.scheduler.command = 'zone_audit';
    this.shadow.scheduler.src = 'dashbd';
    this.updateShadow(this.shadow);
  }

  //pauseProperties: PropertyDescriptor[] = [];
  pause(timeout: number) {
    this.shadow.scheduler = {};
    this.shadow.scheduler.command = 'pause';
    this.shadow.scheduler.src = 'dashbd';
    this.shadow.scheduler.timeout = timeout;
    this.updateShadow(this.shadow);
  }

  //provisionSoilSensorProperties: PropertyDescriptor[] = [];
  provisionSoilSensor(timeout: number) {
    this.shadow.scheduler = {};
    this.shadow.scheduler.command = 'provision_soil_sensor';
    this.shadow.scheduler.src = 'dashbd';
    this.shadow.scheduler.timeout = timeout;
  }

  /*
   * scheduler: {
   *   command: "reset",
   *   commandSrc: "dashbd"
   * }
   *
   *
   *
   *
   */

}
