import {Component, Input, OnChanges, OnInit, ViewContainerRef} from '@angular/core';
import {Shadow} from '../../models/shadow';
import {Device} from '../../models/device';
import {MatDialog} from '@angular/material/dialog';
import {DeviceSettingsDialogComponent} from '../device-settings-dialog/device-settings-dialog.component';
import {MessageService} from '../../services/messages.service';
import {NotificationService} from '../../services/notification.service';
import {FirmwareService} from '../../services/firmware.service';
import {AuthService} from '../../services/auth.service';
import {DialogService} from '../../services/dialog.service';
import {UserService} from '../../services/user.service';
import {DashboardControlActions} from '../../core/models/dashboard-control-actions';
import {VakCommands} from '../../models/vak/vak-commands';
import {NabCommands} from '../../models/nab/nab-commands';
import {HydCommands} from '../../models/hyd/hyd-commands';



interface FirmwareVersion {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'device-control',
  styleUrls: ['./device-control.component.css'],
  templateUrl: './device-control.component.html'
})

export class DeviceControlComponent implements OnInit, OnChanges {
  @Input() device: Device;
  shadow: Shadow = new Shadow();
  dashActions: DashboardControlActions = new DashboardControlActions();
  firmwareVersions: string[] = [];
  selectedFirmwareVersion = '';
  message: string;
  isTier2Support = false;
  isTier1Support = false;
  isNaboo = false;
  isVAK = false;

  constructor(
    public dialog: MatDialog,
    private messageService: MessageService,
    private authService: AuthService,
    private firmwareService: FirmwareService,
    private notificationService: NotificationService,
    private viewContainerRef: ViewContainerRef,
    private dialogService: DialogService,
    private userService: UserService
  ) {}

  async ngOnInit() {
  //  this.isTier2Support = await this.authService.hasRole(['TIER_2_SUPPORT']);
    this.isTier2Support = await this.isTier2SupportRole();
    this.isTier1Support = await this.isTier1SupportRole();

    if (this.device && this.device.deviceType) {
      if (this.device.deviceType === 'NAB') {
        this.isNaboo = true;
        this.isVAK = false;
        this.dashActions = new NabCommands(this.device.clientId, this.dialogService, this.viewContainerRef);
      } else if (this.device.deviceType === 'VAK') {
        this.dashActions = new VakCommands(this.device.clientId);
        this.isNaboo = false;
        this.isVAK = true;
      } else if ( this.device.deviceType === 'HYD') {
        this.dashActions = new HydCommands(this.device.clientId);
      }
      console.log(this.device.deviceType);
      this.getFirmwareVersions()
        .then(data => {
          this.firmwareVersions = data;
          if ( this.firmwareVersions.includes('None')) {
            this.firmwareVersions = this.firmwareVersions.filter(item => item !== 'None');
          }
        });
    }
    this.messageService.currentMessage.subscribe(message => this.message = message);
  }

  async ngOnChanges() {
    if (this.device && this.device.deviceType) {
      if (this.device.deviceType === 'NAB') {
        this.isNaboo = true;
        this.isVAK = false;
        this.dashActions = new NabCommands(this.device.clientId, this.dialogService, this.viewContainerRef);
      } else if (this.device.deviceType === 'VAK') {
        this.isNaboo = false;
        this.isVAK = true;
        this.dashActions = new VakCommands(this.device.clientId);
      } else if ( this.device.deviceType === 'HYD') {
        this.dashActions = new HydCommands(this.device.clientId);
      }

      this.getFirmwareVersions()
        .then(data => {
          this.firmwareVersions = data;
          if ( this.firmwareVersions.includes('None')) {
            this.firmwareVersions = this.firmwareVersions.filter(item => item !== 'None');
          }
        });
    }
  }

  async isDeveloperSupportRole() {
    let isDeveloper = false;
    isDeveloper = await this.authService.hasRole(['DEVELOPER']);
    return isDeveloper;
  }

  async isTier2SupportRole() {
    let isTier2 = false;
    isTier2 = await this.authService.hasRole(['TIER_2_SUPPORT']);
    if (! isTier2 ) {
      isTier2 = await this.isDeveloperSupportRole();
    }
    return isTier2;
  }
  async isTier1SupportRole() {
    let isTier1 = false;

    isTier1 = await this.authService.hasRole(['TIER_1_SUPPORT']);
    if (! isTier1 ) {
      isTier1 = await this.isTier2SupportRole();
    }

    return isTier1;
  }


  private getFirmwareVersions() {
    return this.firmwareService.getFirmwaresByDevice(this.device.deviceType);
  }


  upgrade() {
    const engineeringBuildSuffix = 'ENG';
    if (this.selectedFirmwareVersion.includes(engineeringBuildSuffix) ||
      this.selectedFirmwareVersion.includes(engineeringBuildSuffix.toLowerCase())) {
      const confirmMessage = `An Engineering Build has been selected to put on this device. This version has not passed QA and should only be used with the consent of the device owner.`;
      this.dialogService.confirm('Confirm ENG Build Firmware Upgrade', confirmMessage, this.viewContainerRef, 'OK')
        .subscribe(confirmed => {
          if (!confirmed) { return; }
          this.firmwareService.upgradeDevices(this.device.deviceType, [this.device.clientId], this.selectedFirmwareVersion)
            .then(() => {
              this.notificationService.show('Firmware upgrade started.');
            }).catch(e => {
              console.log(e);
            });
        });
    } else {
      this.firmwareService.upgradeDevices(this.device.deviceType, [this.device.clientId], this.selectedFirmwareVersion)
        .then(() => {
          this.notificationService.show('Firmware upgrade started.');
        }).catch(e => {
          console.log(e);
        });
    }
  }

  changeSettings() {
    this.dialog.open(DeviceSettingsDialogComponent, {
      width: '500px',
      data: this.device
    });
  }

  openDeleteDialog(email: string) {
    this.userService.openDeleteDialog(email);
  }


}


