import {Component, Input, SimpleChanges, Inject, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Device} from '../../models/device';
import {DeleteSessionData} from '../../models/lambda-functions/delete-session';
import {ApiService} from '../../services/api.service';
import {NotificationService} from '../../services/notification.service';
import {ClientStorageService, ClientStorageKey} from '../../services/client-storage.service';
import {Router} from '@angular/router';
import {RouteMap} from '../../route-map';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {Session} from '../../models/session';
import {SessionService} from '../../services/session.service';
import {GroupService} from '../../services/group.service';
import {Group} from '../../models/group';
import {PubSub} from 'aws-amplify';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {DeviceService} from '../../services/device.service';
import {ShadowService} from '../../services/shadow.service';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

const Prism = require('prismjs');
require('prismjs/components/prism-json');

interface DialogData {
  clientId: string;
  confirm: boolean;
}

interface Shadow {
  timestamp: number;
  data: any;
  opened: boolean;
}

@Component({
  selector: 'app-developer',
  templateUrl: './developer.component.html',
  styleUrls: ['./developer.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', visibility: 'hidden', display: 'none'})),
      state('expanded', style({height: '*', visibility: 'visible'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class DeveloperComponent {
  private paginator: MatPaginator;
  @Input() device: Device;
  shadows: Shadow[] = [];
  isLoadingDeveloper = false;
  groups: Group[] = [];
  group = '';
  newGroup = '';
  pubSubs = [];
  currentShadow: any;
  currentOpened = false;
  acceptedSubscribed = false;
  documentSubscribed = false;
  devDevice = false;
  crockStream = false;
  // tslint:disable-next-line:max-line-length
  platformLogTypes = ['SHELL', 'WLAN', 'RESET', 'XUC', 'RTR', 'DS', 'PROV'];
  platformLogTypes2 = ['CRYPTO', 'FS', 'SYSTIME', 'SHADOW', 'POWER', 'UPG', 'AWS'];
  platformLogTypes3 = ['HTTP', 'SELF_TEST', 'SYSTEM', 'BUTTON', 'TLM', 'JSON'];
  vakLogTypes = ['MOTOR', 'SPOUT', 'MIXER', 'VALVE'];
  nabLogTypes = ['TOF', 'TEMP', 'FLOW'];
  rapLogTypes = ['SHUTOFF'];
  logDebugState = {
    ALL: false,
    platform: {
      WLAN: false,
      RESET: false,
      XUC: false,
      RTR: false,
      DS: false,
      PROV: false,
      CRYPTO: false,
      FS: false,
      SYSTIME: false,
      SHADOW: false,
      POWER: false,
      UPG: false,
      AWS: false,
      HTTP: false,
      SELF_TEST: false,
      SYSTEM: false,
      BUTTON: false,
      TLM: false,
      JSON: false
    },
    vak: {
      MOTOR: false,
      SPOUT: false,
      MIXER: false,
      VALVE: false
    },
    nab: {
      TOF: false,
      TEMP: false,
      FLOW: false
    },
    rap: {
      SHUTOFF: false
    }
  };

  constructor(public dialog: MatDialog,
              private apiService: ApiService,
              private notificationService: NotificationService,
              private sessionService: SessionService,
              private groupService: GroupService,
              private deviceService: DeviceService,
              private shadowService: ShadowService,
              private router: Router
  ) {
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.device.currentValue) {
      await this.subscribeDevice();
      this.group = changes.device.currentValue.groupId;
      await this.getShadow();
    }
  }

  ngAfterViewInit() {
    this.getGroups();
  }

  ngOnDestroy() {
    if (this.pubSubs.length) {
      this.pubSubs.forEach(pubsub => pubsub.unsubscribe());
      this.pubSubs = [];
      this.acceptedSubscribed = false;
      this.documentSubscribed = false;
    }
  }

  synchronizeShadowSettings(shadow) {
    if(shadow.logDebugState) {
      if(!shadow.logDebugState.includes("ALL")) {
        for(let debugCategoryKeys of Object.keys(this.logDebugState)) {
          let debugCategories = this.logDebugState[debugCategoryKeys];
          for(let debugStateKey of Object.keys(debugCategories)) {
            if(shadow.logDebugState.includes(debugStateKey)) {
              debugCategories[debugStateKey] = true;
            }
          }
        }
      } else {
        this.logDebugState["ALL"] = true;
        for(let debugCategoryKeys of Object.keys(this.logDebugState)) {
          let debugCategories = this.logDebugState[debugCategoryKeys];
          for(let debugStateKey of Object.keys(debugCategories)) {
            debugCategories[debugStateKey] = true;
            if(shadow.logDebugState.includes(debugStateKey)) {
            }
          }
        }
      }
    }
  }

  async getShadow() {
    const shadow = await this.shadowService.getShadow(this.device.clientId);
    this.currentShadow = Prism.highlight(JSON.stringify(shadow.state, undefined, 4), Prism.languages.json, 'json');
    this.devDevice = this.device.shadow.devDevice;

    this.synchronizeShadowSettings(this.device.shadow);

    this.crockStream = this.device.shadow.crockStream;
    if (this.device.shadow.devDevice == undefined) {
      this.devDevice = false;
    }
    if (this.device.shadow.crockStream == undefined) {
      this.crockStream = false;
    }
    // console.log("getShadow().devDevice="+this.devDevice);
  }

  clearShadow() {
    this.shadows = [];
  }

  openDialog(type) {
    const dialog = this.dialog.open(DeveloperConfirmationDialog, {
      width: '500px',
      data: {clientId: this.device.clientId, confirm: false}
    });

    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.deprovisionDevice();
      }
    });
  }

  async deprovisionDevice() {
    try {
      await this.deviceService.deleteDevice(this.device.clientId);
      ClientStorageService.remove(ClientStorageKey.ClientId);
      this.router.navigate([RouteMap.devices.absolute]);
      this.notificationService.show('Device deprovisioned successfully');
    } catch (error) {
      console.log(error);
      this.notificationService.show('There was a problem deprovisioning the device');
    }
  }

  getGroups() {
    this.groupService.listGroups()
      .then(groups => {
        this.groups = groups;
      });
  }

  addDeviceToGroup() {
    const device = {
      clientId: this.device.clientId,
      deviceType: this.device.deviceType
    };
    const devices = [device];

    this.groupService.setDevicesToGroup(devices, this.group)
      .then(() => {
        this.notificationService.show('Device group set successfully!');
      });
  }
  removeDeviceFromGroup() {
    const device = {
      clientId: this.device.clientId,
      deviceType: this.device.deviceType
    };
    const devices = [device];

    this.groupService.setDevicesToGroup(devices, null)
      .then(() => {

        this.group = '';
        this.notificationService.show('Device group removed successfully!');
      });
  }
  sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }
  async subscribeDevice() {
    if (!this.acceptedSubscribed) {
      try {
        this.acceptedSubscribed = true;
        const accepted = PubSub.subscribe(this.awsTopic(this.device.clientId, 'update/accepted')).subscribe({
          next: data => {
            const shadow = {
              timestamp: data.value.timestamp,
              data: Prism.highlight(JSON.stringify(data.value.state, undefined, 4), Prism.languages.json, 'json'),
              opened: false
            };
            this.shadows.push(shadow);
            if (this.shadows.length > 25) { // Keep 25 shadow records for temperature learning
              this.shadows.shift();
            }
          },
          error: error => {
            console.error(error);
            this.acceptedSubscribed = false;
            this.subscribeDevice();
          },
          complete: () => {
            accepted.unsubscribe();
            this.acceptedSubscribed = false;
            console.log('Unsubscribed Successfully');
          }
        });
        this.pubSubs.push(accepted);
      } catch (error) {
        console.error(error);
      }
    }
    if (!this.documentSubscribed) {
      try {
        this.documentSubscribed = true;
        const documents = PubSub.subscribe(this.awsTopic(this.device.clientId, 'update/documents')).subscribe({
          next: data => {
            this.currentShadow = Prism.highlight(JSON.stringify(data.value.current, undefined, 4), Prism.languages.json, 'json');
          },
          error: error => {
            console.error(error);
            this.documentSubscribed = false;
            this.subscribeDevice();
          },
          complete: () => {
            documents.unsubscribe();
            this.documentSubscribed = false;
            console.log('Unsubscribed Successfully');
          }
        });
        this.pubSubs.push(documents);
      } catch (error) {
        console.error(error);
      }
    }
  }


  async shellEnable(event) {
    const desired = {devDevice: event.checked};
    this.devDevice = event.checked;
    const shadowx = await this.shadowService.updateShadow(this.device.clientId, desired);
    // console.log("toggling.shellEnable()="+event.checked);
  }

  async debugLoggingEnable(event, argType) {
    const desired = {logDebug: undefined};
    let myArg = argType;
    if (event.checked) {
      myArg = argType;
      if (argType === 'ALL') {
        this.logDebugState.ALL = true;

        const platformKeys = Object.keys(this.logDebugState.platform || {});
        for (const key of platformKeys) {
          this.logDebugState.platform[key] = false;
        }

        const deviceSpecificKeys = Object.keys(this.logDebugState[this.device.deviceType.toLowerCase()] || {});
        for (const key of deviceSpecificKeys) {
          this.logDebugState[this.device.deviceType.toLowerCase()][key] = true;
        }
      }
    } else {
      myArg = '~' + argType;
      if (argType === 'ALL') {
        this.logDebugState.ALL = false;

        const platformKeys = Object.keys(this.logDebugState.platform || {});
        for (const key of platformKeys) {
          this.logDebugState.platform[key] = false;
        }

        const deviceSpecificKeys = Object.keys(this.logDebugState[this.device.deviceType.toLowerCase()] || {});
        for (const key of deviceSpecificKeys) {
          this.logDebugState[this.device.deviceType.toLowerCase()][key] = false;
        }
      }
    }
    desired.logDebug = myArg;
    const shadowx = await this.shadowService.updateShadow(this.device.clientId, desired);
    this.notificationService.show(`Sent desired = {'logDebug: ${myArg}} to shadow`);
  }

  async crockStreamEnable(event) {
    const desired = {crockStream: event.checked};
    const shadowx = await this.shadowService.updateShadow(this.device.clientId, desired);
    this.notificationService.show(`Crock Streaming Set to ${event.checked}`);
  }

  private awsTopic(clientId, topic): string {
    return `$aws/things/${clientId}/shadow/${topic}`;
  }

  async setDesiredNull() {
    const shadow = await this.shadowService.getShadow(this.device.clientId);
    const desiredShadow = shadow.state.desired;
    const shadowKeys = Object.keys(desiredShadow || {});
    for (const key of shadowKeys) {
      desiredShadow[key] = null;
    }
    const shadowx = await this.shadowService.updateShadow(this.device.clientId, desiredShadow);
  }
}

@Component({
  selector: 'confirmation-dialog',
  templateUrl: 'confirmation-dialog.html'
})
export class DeveloperConfirmationDialog {
  constructor(
    public dialogRef: MatDialogRef<DeveloperConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
