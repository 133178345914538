import { InjectionToken } from '@angular/core';

export const RouteMap = {
  login: {
    relative: 'login',
    absolute: '/login'
  },
  logout: {
    relative: 'logout',
    absolute: '/logout'
  },
  signup: {
    relative: 'signup',
    absolute: '/signup'
  },
  pendingApproval: {
    relative: 'pending-approval',
    absolute: '/pending-approval'
  },
  devices: {
    relative: 'devices',
    absolute: '/devices'
  },
  users: {
    relative: 'users',
    absolute: '/users'
  },
  user: {
    relative: 'users/:federatedIdentity',
    absolute: '/users/:federatedIdentity'
  },
  firmware: {
    relative: 'firmware',
    absolute: '/firmware'
  },
  eventsForClient: {
    relative: 'events/:clientId',
    absolute: '/events/:clientId'
  },
  events: {
    relative: 'events',
    absolute: '/events'
  },
  device: {
    relative: 'device',
    absolute: '/device'
  },
  resetPassword: {
    relative: 'resetPassword',
    absolute: '/resetPassword'
  },
  admin: {
    relative: 'admin',
    absolute: '/admin'
  },
  ssoAuthing: {
    relative: 'ssoAuthing',
    absolute: '/ssoAuthing'
  },
  sso: {
    relative: 'sso',
    absolute: '/sso'
  }
};

export const ROUTE_MAP = new InjectionToken('routeMap');
