import { Component, Input, ViewChild, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {  MatPaginator } from '@angular/material/paginator';
import {  MatSort, Sort, MatSortHeader } from '@angular/material/sort';
import {  MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Event } from '../../models/event';
import { Device } from '../../models/device';
import { EventService } from '../../services/event.service';
import { saveAs } from 'file-saver';
import { DatePipe } from '@angular/common';
import { NotificationService } from '../../services/notification.service';

import { Observable } from 'rxjs';


import * as columnify from 'columnify';
import { Article } from '../../models/article';
import { async } from '@angular/core/testing';

interface FilterType {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-events-table',
  styleUrls: ['./events-table.component.css'],
  templateUrl: './events-table.component.html'
})
export class EventsTableComponent {
  private paginator: MatPaginator;
  private sort: MatSort;
  @Input() clientId: string;
  @Input() device: Device;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }
  displayedColumns: string[] = ['date', 'id', 'severity', 'msgPrivate', 'article'];
  dataSource: MatTableDataSource<Event> = new MatTableDataSource([]);
  eventFilterTypes: FilterType[] = [
    {value: 'all', viewValue: 'All'},
    {value: 'critical', viewValue: 'Critical Only'},
    {value: 'criticalwarnings', viewValue: 'Critical & Warnings'},
    {value: 'id', viewValue: 'ID'}
  ];
  filterType = 'all';
  filterName = '';
  originalData: Event[];
  start: Date;
  end: Date;
  isLoadingEvents = false;
  documentState = null;
  hasMoreValues = true;
  filter = '';
  query = '';
  offset = false;
  offsetAmount = 1;

  constructor(private eventService: EventService,
              private notificationService: NotificationService,
              private datePipe: DatePipe) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.device.currentValue) {
      this.search();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.filter = filterValue;
    this.offset = false;
    this.offsetAmount = 1;
    this.buildQuery();
  }

  eventsTextDump() {
    const text = this.dataSource.data
      .sort((a, b) => {
        return this.compare(new Date(a.createdAt), new Date(b.createdAt), true);
      })
      .map(event => {
        return {
          date: this.datePipe.transform(parseInt(event.createdAt, 10), 'medium'),
          id: event.eventId,
          severity: event.severity,
          msgPrivate: event.msgPrivate
        };
      });
    let columns = columnify(text);
    //Replacing LF for CRLF for Windows folks not using a DEV text editor
    const platform = window.navigator.platform;
    const windowsOs = ['Win32', 'Win64', 'Windows', 'WinCE'];
    if (windowsOs.includes(platform)) {
      columns = columns.replace(/\n/g, '\r\n');
    }
    const blob = new Blob([columns], {type: 'text/plain'});
    saveAs(blob, `events_${this.device.clientId}.txt`);
  }

  changeFilter(event) {
    this.documentState = null;
    this.hasMoreValues = true;
    this.filterType = event;
    if (this.filterType === 'id') {
      this.filterName = 'Number';
    } else if (this.filterType === 'text') {
      this.filterName = 'Text';
    } else {
      this.filterName = '';
    }
  }

  dateChanged = (picker: string, event: MatDatepickerInputEvent<Date>) => {
    this.documentState = null;
    if (picker === 'start') {
      this.start = event.value;
      this.start.setHours(0, 0, 0, 0);
    } else if (picker === 'end') {
      this.end = event.value;
      this.end.setHours(23, 59, 59, 999);
    }
    if (this.start != null && this.end != null) {
      if (this.end < this.start) {
        this.start = new Date(this.end.getTime());
        this.start.setHours(0, 0, 0, 0);
      }
    }
  }

  search() {
    this.offset = false;
    this.offsetAmount = 1;
    this.buildQuery();
    this.getEvents();
  }

  async getEvents() {
    this.isLoadingEvents = true;
    try {
      let events;
      switch (this.filterType) {
        case 'all':
          if(this.documentState != null) {
            this.documentState = await this.eventService.getEventLogsDuid(this.device.clientId, this.documentState.LastEvaluatedKeys, new Date(this.start).getTime(), new Date(this.end).getTime());
          } else {
            this.documentState = await this.eventService.getEventLogsDuid(this.device.clientId, null, new Date(this.start).getTime(), new Date(this.end).getTime());
          }
          events = this.documentState.Items;
          break;
        case 'critical':
          if(this.documentState != null) {
            this.documentState = await this.eventService.getEventLogsDuidBySeverities(['critical'], this.device.clientId, this.documentState.LastEvaluatedKeys, new Date(this.start).getTime(), new Date(this.end).getTime());
          } else {
            this.documentState = await this.eventService.getEventLogsDuidBySeverities(['critical'], this.device.clientId, null, new Date(this.start).getTime(), new Date(this.end).getTime());
          }
          events = this.documentState.Items;
          break;
        case 'criticalwarnings':
          if(this.documentState != null) {
            this.documentState = await this.eventService.getEventLogsDuidBySeverities(['critical', 'warning'], this.device.clientId, this.documentState.LastEvaluatedKeys, new Date(this.start).getTime(), new Date(this.end).getTime());
          } else {
            this.documentState = await this.eventService.getEventLogsDuidBySeverities(['critical', 'warning'], this.device.clientId, null, new Date(this.start).getTime(), new Date(this.end).getTime());
          }
          events = this.documentState.Items;
          break;
        case 'id':
          if(this.documentState != null) {
            this.documentState = await this.eventService.getEventLogsDuidById(parseInt(this.filter), this.device.clientId, this.documentState.LastEvaluatedKeys, new Date(this.start).getTime(), new Date(this.end).getTime());
          } else {
            this.documentState = await this.eventService.getEventLogsDuidById(parseInt(this.filter), this.device.clientId, null, new Date(this.start).getTime(), new Date(this.end).getTime());
          }
          events = this.documentState.Items;
          break;
        default: {
          const msg = "Unknown option found for filter type.";
          console.error(msg);
          throw new Error(msg);
        }
      }

      if(!this.documentState.LastEvaluatedKeys || Object.keys(this.documentState.LastEvaluatedKeys).length == 0) {
        this.hasMoreValues = false;
      }

      //const events = await this.eventService.deviceEventsForQuery(this.query);
      if (this.offset) {
        this.dataSource.data = this.dataSource.data.concat(events);
      } else {
        this.paginator.pageIndex = 0;
        this.dataSource.data = events;
      }
      this.isLoadingEvents = false;
    } catch (error) {
      this.notificationService.show(error.message);
      this.dataSource.data = [];
      this.isLoadingEvents = false;
    }
  }

  flatEventsWithArticles(events: Event[]) {
    let flat = {};
    events.forEach(event => {
      const hash = event.createdAt + '_' + event.eventId;
      const tempArticle: Article = {
        articleId: event.articleId,
        eventId: event.eventId,
        sku: event.sku,
        name: event.name,
        url: event.url,
        isPrivate: undefined
      };

      if (flat[hash] && !flat[hash].manyArticles.some(article => article.articleId === tempArticle.articleId)) {
        flat[hash].manyArticles.push(tempArticle);
      } else {
        flat[hash] = {
          clientId: event.clientId,
          eventId: event.eventId,
          severity: event.severity,
          msgPrivate: event.msgPrivate,
          timeStamp: event.timeStamp,
          createdAt: event.createdAt,
          args: event.args,
          manyArticles: [tempArticle]
        };
      }
    });
    return Object.keys(flat).map(key => flat[key]);
  }

  buildQuery() {
    this.query = `SELECT data.clientId, data.eventId, data.severity, data.msgPrivate, `;
    this.query += ` UNIX_TIMESTAMP(data.createdAt) as createdAt, `;
    this.query += ` article.articleId, article.sku, article.name, article.url FROM data`;
    this.query += ` LEFT JOIN article_lookup ON data.eventId = article_lookup.eventId`;
    this.query += ` LEFT JOIN article ON article_lookup.articleId = article.articleId`;
    this.query += ` WHERE data.clientId = '${this.device.clientId}'`;

    switch (this.filterType) {
      case 'all':
        break;
      case 'errors':
        this.query += ` AND data.severity = 'Error'`;
        break;
      case 'errorswarnings':
        this.query += ` AND (data.severity = 'Error' OR data.severity = 'Warn')`;
        break;
      case 'id':
        this.query += ` AND data.eventId = '${this.filter}'`;
        break;
      case 'text':
        this.query += ` AND (data.msgPrivate LIKE '%${this.filter}%')`;
    }
    if (this.start != null) {
      this.query += ` AND data.createdAt > '${this.start.toISOString()}'`;
    }
    if (this.end != null) {
      this.query += ` AND data.createdAt < '${this.end.toISOString()}'`;
    }
    this.query += ` ORDER BY data.createdAt DESC`;
    this.query += ' LIMIT 1001';

  }

  sortData = (sort: Sort) => {
    const data = this.dataSource.data;
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id': return this.compare(a.eventId, b.eventId, isAsc);
        case 'severity': return this.compare(a.severity, b.severity, isAsc);
        case 'msgPrivate': return this.compare(a.msgPrivate, b.msgPrivate, isAsc);
        case 'date': return this.compare(new Date(a.createdAt), new Date(b.createdAt), isAsc);
        default: return 0;
      }
    });
  }

  compare = (a, b, isAsc) => {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  changePage(event) {
    if (event.pageIndex * event.pageSize >= event.length - event.pageSize && this.hasMoreValues) {
      this.offset = true;
      this.offsetAmount++;
      this.query = this.query.replace(/ OFFSET \d+$/, '');
      this.query += ` OFFSET ${this.offsetAmount - 1}000`;
      this.getEvents();
    }
  }
}
