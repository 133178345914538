import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import Amplify from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';

if (environment.production) {
  enableProdMode();
}

Amplify.configure({
  Auth: environment['isChina'] ? {
    identityPoolId: environment.identityPoolId,
    region: environment['cnCognitoRegion'], //for China, only cn-northwest-1 has the Cognito Identity Pool service, and will use Authing to replace Cognito User Pool
  } 
  : 
  {
    identityPoolId: environment.identityPoolId,
    region: environment.region,
    userPoolId: environment.userPoolId,
    userPoolWebClientId: environment.userPoolClientId,
    oauth: {
      domain: environment.oauthDomain,
      scope: environment.oauthScope,
      redirectSignIn: environment.oauthRedirectSignIn,
      redirectSignOut: environment.oauthRedirectSignOut,
      responseType: environment.oauthResponseType
    }
  },
});

Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: environment.aws_pubsub_region,
  aws_pubsub_endpoint: environment.aws_pubsub_endpoint
}));

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
