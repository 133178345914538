
import {Component, Input, OnInit, ChangeDetectorRef, ViewChild, HostListener, SimpleChanges} from '@angular/core';
import { CommonModule } from '@angular/common';
import {Device} from '../../../models/device';
import { NotificationService } from '../../../services/notification.service';
import {DeviceService} from '../../../services/device.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Shadow} from '../../../models/shadow';
import {EventsTableComponent} from '../../events-table/events-table.component';
import {UsageTableComponent} from '../../usage-table/usage-table.component';
import {AuthService} from '../../../services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ViewEncapsulation } from '@angular/core';
import { EnvironmentService} from '../../../services/environment.service';

@Component({
  selector: 'app-status-hyd-table',
  templateUrl: './status-hyd-table.html',
  styleUrls: ['./status-hyd-table.css'],

  // encapsulation none allows for resizing of the mat-tab widths.
  encapsulation: ViewEncapsulation.None
})

export class StatusHydTableComponent implements OnInit {
  @Input() device: Device;

  constructor(
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private environment: EnvironmentService,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    private sanitizer: DomSanitizer) {
  }

  @ViewChild(EventsTableComponent) eventsTable: EventsTableComponent;
  @ViewChild(UsageTableComponent) usageComponent: UsageTableComponent;
  isSticky = false;
  isLoadingDevice = false;
  isLoadingShadow = false;
  shadow: Shadow;
  clientId: string;
  shadowData = {
    hydraKeys: []
  };

  settings = {
    dropEnabled: false,
    crockMonitorOn: false
  };

  temperatureKeys = ['assemblyAirTemp', 'safetyLimitTemp', 'childLimitTemp', 'defaultTemp', 'temperature', 'learnedMinTemp', 'learnedMaxTemp'];
  hydraKeys = ['scheduler', 'waterEnable', 'waterScheduleID', 'masterValveConnected', 'rainSensorConnected', 'rainSensorType', 'seasonalAdjust', 'soilSensors', 'soilSensorsLost', 'flowSensor', 'hydraOverview', 'schedulesMax', 'schedulesLoaded', 'numZones', 'zones'];
  isDeveloper = false;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 64;
  }

async ngOnInit() {
    // Working around https://github.com/angular/material2/issues/5593
    this.changeDetector.detectChanges();
    await this.updateDevice();
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnChanges(changes: SimpleChanges) {
    if (changes.device.currentValue) {
    }
  }

  private async updateDevice() {
    this.isLoadingShadow = true;
    try {
      const shadowKeys = Object.keys(this.device.shadow || {});
      for (const key of shadowKeys) {
       if (this.hydraKeys.includes(key)) {
         this.shadowData.hydraKeys.push(key);
       }
      }
      this.isLoadingDevice = false;
      this.isLoadingShadow = false;
      console.log('hydra Keys', this.shadowData.hydraKeys);
    } catch (error) {
      console.log(error);
    }
  }

  private prettyPrintKey(key: string): string {
    // Replace ToF with sensor
    // Replace MM with mm.
    // Replace Bck Tst with Backup Test
    if (key === 'crockBackup') {
      key = 'crockHasBackup';
    }
    if (key.includes('Tof')) {
      key = key.replace('Tof', 'Sensor');
    }
    if (key.includes('BckTst')) {
      key = key.replace('BckTst', 'Backup Test');
    }
    if (!key.includes('FW')) {
      key = key
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) => str.toUpperCase());
      if (key.includes('Tof Distance')) {
        key  = 'Sensor Distance' + ' (Not updated unless Updates ON)';
      }
      if (key.includes('M M')) {
        key = key.replace('M M', '');
      }
      return key;
    } else {
      if (key.includes('latch')) {
        key = 'sensorFW';
      }
      key = key.replace('FW', 'Version');
      return key
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) => str.toUpperCase());
    }
  }

  private formatDisplay(shadow, key) {
    if (key === 'wifiRssi') {
      return this.formatRssi(shadow[key]);
    } else if (key.includes('learnedMinTemp') && shadow[key] === -1) {// Learned min temp won't be known if LYH hasn't been completed.
      return 'Unknown';
    } else if (key.includes('learnedMaxTemp') && shadow[key] === -1) {// Learned max temp won't be known if LYH hasn't been completed.
      return 'Unknown';
    } else if (this.temperatureKeys.includes(key) && !isNaN(shadow[key])) {
      return this.formatTemperature(shadow[key]);
    } else if (key === 'batteryPercentage') {
      if (shadow[key] === -1) {
        return 'N/A';
      } else if (shadow[key] <= 10) {
        return '<10%';
      } else {
        return shadow[key] + '%';
      }
    } else if (key.includes('Timeout')) {
      return shadow[key] / 60 + ' min';
    } else if (key.includes('Rate')) {
      return shadow[key] + '%';
    } else if (key.includes('Diameter') || key.includes('crockTofHeight')) {
      return shadow[key] + ' mm';
    } else {
      return shadow[key];
    }
  }

  private formatTemperature(tempC) {
    return `${Math.round(tempC * 10) / 10} °C (${this.celsiusToFahrenheit(tempC)} °F)`;
  }

  private celsiusToFahrenheit(tempC) {
    return Math.round(tempC * 1.8 + 32);
  }

  private formatRssi(value) {
    if (value >= -55) {
      return `${value}   (GREAT)`;
    } else if (value < -55 && value >= -65) {
      return `${value}   (GOOD)`;
    } else if (value < -65 && value >= -70) {
      return `${value}   (ACCEPTABLE)`;
    } else if (value < -70 && value >= -75) {
      return `${value}   (POOR)`;
    } else {
      return `${value}   (BAD)`;
    }
  }

  private rssiClass(value, connected) {
    if (connected && value >= -55) {
      return `rssi-great`;
    } else if (connected && value < -55 && value >= -65) {
      return `rssi-good`;
    } else if (connected && value < -65 && value >= -70) {
      return `rssi-acceptable`;
    } else if (connected && value < -70 && value >= -75) {
      return `rssi-poor`;
    } else {
      return `rssi-bad`;
    }
  }


}
