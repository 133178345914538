import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { NabHumid } from '../models/nab-humid';
import { GetSessionDataV2 } from '../models/lambda-functions/get-session-dataV2';
import { NabHumidResults } from '../models/nab-humid-results';

@Injectable()
export class NabHumidService {
  constructor (private apiService: ApiService) {}

  getSessionDataForThing(clientId: string): Promise<NabHumidResults> {
    const getSessionData = new GetSessionDataV2({
      duid: clientId,
      limit: 1001,
      usageType: 'humidData'
    });
    return this.apiService.invokeAsPromise(getSessionData)
      .then(response => JSON.parse(response.body));
  }

  async getNextSessionDataForThing(clientId: string, lastEvaluatedKey: string): Promise<NabHumidResults> {
    const getSessionData = new GetSessionDataV2({
      clientId: clientId,
      limit: 1001,
      lastEvaluatedKey: lastEvaluatedKey
    });
    return this.apiService.invokeAsPromise(getSessionData)
      .then(response => response.body);
  }
}
