import {DashboardControlActions} from '../../core/models/dashboard-control-actions';
import {Shadow, ShadowCommand} from '../shadow';
import {DashboardAction} from '../../core/models/dashboard-action';

export class VakCommands extends DashboardControlActions {
  shadow: Shadow = new Shadow();

  constructor(clientId: string) {
    super();
    this.clientId = clientId;
    this.AddAction(new DashboardAction({name: 'selfTest', displayName: 'Run Self Test', action: () => this.selfTest()}));
    this.AddAction(new DashboardAction({name: 'reboot', displayName: 'Reboot', action: () => this.reboot()}));
    this.AddAction(new DashboardAction({name: 'runWater', displayName: 'Run Water', action: () => this.turnOn()}));
    this.AddAction(new DashboardAction({name: 'stopWater', displayName: 'Stop Water', action: () => this.turnOff()}));
    this.AddAction(new DashboardAction({name: 'forceOff', displayName: 'Force Off', action: () => this.forceOff()}));
    this.AddAction(new DashboardAction({name: 'locate', displayName: 'Locate', action: () => this.locate()}));
  }

  selfTest() {
    this.shadow.command = ShadowCommand.Test;
    this.shadow.commandSrc = 'dashbd';
    this.updateShadow(this.shadow);
  }

  reboot() {
    this.shadow.command = ShadowCommand.Reboot;
    this.shadow.commandSrc = 'dashbd';
    this.updateShadow(this.shadow);
  }

  turnOn() {
    this.shadow.command = ShadowCommand.Run;
    this.shadow.commandSrc = 'dashbd';
    this.updateShadow(this.shadow);
  }

  turnOff() {
    this.shadow.command = ShadowCommand.Stop;
    this.shadow.commandSrc = 'dashbd';
    this.updateShadow(this.shadow);
  }

  forceOff() {
    this.shadow.command = ShadowCommand.ForceOff;
    this.shadow.commandSrc = 'dashbd';
    this.updateShadow(this.shadow);
  }

  locate() {
    this.shadow.command = ShadowCommand.Locate;
    this.shadow.commandSrc = 'dashbd';
    this.updateShadow(this.shadow);
  }
}
