import { Component, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject } from 'rxjs';







import { AuthService } from '../../services/auth.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent {
  @Input() headline: string;
  @Input() successUrl: string;
  @Input() signupUrl: string;
  @Input() passwordResetUrl: string;
  @Input() username = '';
  @Input() adminRequired: boolean;
  password = '';
  isAuthenticating = false;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    public router: Router,
    private notificationService: NotificationService) {
  }

  public onSubmit = async ($event) => {
    $event.preventDefault();
    this.isAuthenticating = true;
    this.notificationService.show('Logging in...');

    try {
      this.isAuthenticating = false;
      this.notificationService.show('Welcome');
      this.router.navigate([this.successUrl]);
    } catch (error) {
      this.isAuthenticating = false;
      this.notificationService.show(error.message);
    }
  }
}
