import { Component, Input, SimpleChanges, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { Device } from '../../../models/device';
import { SessionServiceV2 } from '../../../services/session-v2.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ApiService } from '../../../services/api.service';
import { DeleteSessionData } from '../../../models/lambda-functions/delete-session';
import { NotificationService } from '../../../services/notification.service';
import { ClientStorageKey, ClientStorageService, ClientStorageValueType } from '../../../services/client-storage.service';
import { DatePipe } from '@angular/common';

import * as columnify from 'columnify';
import {SoilSensor} from '../../../models/hyd/soil-sensor';
import {HawkSession} from '../../../models/hyd/hwk-session';
import {HydSessionResults} from '../../../models/hyd/hyd-session-results';
import {HwkSessionResults} from '../../../models/hyd/hwk-session-results';
import { saveAs } from 'file-saver'

interface DialogData {
  clientId: string;
  confirm: boolean;
  message: string;
}

interface SessionData {
  date: any;
  id: string;
  signalStrength: number;
  batteryPercent: number;
  temperature: number;
  moisture1Inch: number;
  moisture3Inch: number;
  moisture5Inch: number;
  restartCount: number;
  retryCount: number;
}

@Component({
  selector: 'app-session-hwk-table',
  styleUrls: ['./session-hwk-table.component.css'],
  templateUrl: './session-hwk-table.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SessionHwkTableComponent {
  private paginator: MatPaginator;
  private sort: MatSort;
  @Input() device: Device;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  displayedColumns = [
    'date',
    'id',
    'signalStrength',
    'batteryPercent',
    'temperature',
    'moisture1Inch',
    'moisture3Inch',
    'moisture5Inch',
    'restartCount',
    'retryCount'
  ];

  dataSource = new MatTableDataSource<SessionData>([]);
  isLoadingSession = false;
  useCelsius = false;
  lastEvaluatedKey = null;

  constructor (private apiService: ApiService,
               private sessionService: SessionServiceV2,
               private notificationService: NotificationService,
               private datePipe: DatePipe,
               private dialog: MatDialog) {}

  ngOnInit() {
    const storedTempPref = ClientStorageService.get(ClientStorageKey.PreferCelsius, ClientStorageValueType.String);
    if (storedTempPref && storedTempPref === 'true') {
      this.useCelsius = true;
    } else if (storedTempPref && storedTempPref === 'false') {
      this.useCelsius = false;
    } else {
      ClientStorageService.put(ClientStorageKey.PreferCelsius, 'false');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.device.currentValue) {
      this.getSessionData();
    }
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public getSessionData() {
    this.isLoadingSession = true;
    this.sessionService.getSessionDataForThing<HwkSessionResults>(this.device.clientId)
      .then(sessions => {
        let res: HwkSessionResults = sessions;
        this.dataSource.data = this.toSessionData(res.Items);
        if ( sessions.LastEvaluatedKey ) {
          this.lastEvaluatedKey = sessions.LastEvaluatedKey;
        } else {
          this.lastEvaluatedKey = null;
        }
        this.isLoadingSession = false;
      })
      .catch(error => {
        console.error(error);
      });
  }

  changePage(event) {

    if (event.pageIndex * event.pageSize >= event.length - event.pageSize) {
      if (this.lastEvaluatedKey !== null) {
          this.getNextSessionData();
      }  else {
        // console.log("nothing to get");
      }
    }
  }

  getNextSessionData() {

    this.isLoadingSession = true;

    this.sessionService.getNextSessionDataForThing<HwkSessionResults>(this.device.clientId, this.lastEvaluatedKey)
      .then(sessions => {
        for (const item of sessions.Items) {
          this.dataSource.data.push({

            date: item.timestamp,
            id: item.id,
            signalStrength: item.signalStrength,
            batteryPercent: item.batteryPercent,
            temperature: item.temperature,
            moisture1Inch: item.moisture1Inch,
            moisture3Inch: item.moisture3Inch,
            moisture5Inch: item.moisture5Inch,
            restartCount: item.restartCount,
            retryCount: item.retryCount
          });
        }

        if ( sessions.LastEvaluatedKey ) {
          this.lastEvaluatedKey = sessions.LastEvaluatedKey;
        } else {
          this.lastEvaluatedKey = null;
        }

        this.isLoadingSession = false;
      })
      .catch(error => {
        console.error(error);
      });

  }

  public dumpSessionData() {
    const text = this.dataSource.data
      .sort((a, b) => {
        return this.compare(new Date(a.date), new Date(b.date), true);
      })
      .map(session => {
        session.date= this.datePipe.transform(session.date, 'medium');

        //Reorder columns to match displayedColumns so the text file 'looks right'. DT-248
        return {
          date: session.date,
          id: session.id,
          signalStrength: session.signalStrength,
          batteryPercent: session.batteryPercent,
          temperature: session.temperature,
          moisture1Inch: session.moisture1Inch,
          moisture3Inch: session.moisture3Inch,
          moisture5Inch: session.moisture5Inch,
          restartCount: session.restartCount,
          retryCount: session.retryCount
        };
      });

    let columns = columnify(text, {
      columnSplitter: ' '
    });
    const platform = window.navigator.platform;
    const windows = ['Win32', 'Win64', 'Windows', 'WinCE'];
    if (windows.includes(platform)) {
      columns = columns.replace(/\n/g, '\r\n');
    }
    const blob = new Blob([columns], {type: 'text/plain;carset=utf-8'});
    saveAs(blob, `session_${this.device.clientId}.txt`);
  }

  public sortData = (sort: Sort) => {
    const data = this.dataSource.data;
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'date': return this.compare(new Date(a.date), new Date(b.date), isAsc);
        case 'event': return this.compare(a.id, b.id, isAsc);
        case 'status': return this.compare(a.signalStrength, b.signalStrength, isAsc);
        case 'requestedDuration': return this.compare(a.batteryPercent, b.batteryPercent, isAsc);
        case 'actualDuration': return this.compare(a.temperature, b.temperature, isAsc);
        case 'scheduleID': return this.compare(a.moisture1Inch, b.moisture1Inch, isAsc);
        case 'zoneID': return this.compare(a.moisture3Inch, b.moisture3Inch, isAsc);
        case 'skipReason': return this.compare(a.moisture5Inch, b.moisture5Inch, isAsc);
        case 'solenoidCurrent': return this.compare(a.restartCount, b.restartCount, isAsc);
        case 'volume': return this.compare(a.retryCount, b.retryCount, isAsc);
      }
    });
  }


  private compare = (a, b, isAsc) => {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  private openDialog() {
    const dialog = this.dialog.open(SessionConfirmationDialog, {
      width: '500px',
      data: { clientId: this.device.clientId, confirm: false }
    });

    dialog.afterClosed().subscribe(result => {
      if (result) { this.deleteSessionData(); }
    });
  }

  private deleteSessionData() {
    const deleteSessionData = new DeleteSessionData({
      clientId: this.device.clientId
    });

    this.apiService.invokeAsPromise(deleteSessionData)
      .then(() => {
        this.dataSource.data = [];
        this.notificationService.show('Device session data removed successfully');
      })
      .catch((error) => {
        console.error(error);
        this.notificationService.show('There was a problem deleting the session data for this device');
      });
  }

  private formatNumber(input) {
     let output = input.toString();
     if ( output.indexOf('.') <= 0) {
       output = output + '.0';
     }
     return output;
  }


  roundMsDuration(input) {

    let seconds = input / 1000;
    if (input === undefined) {
      seconds = 0.0;
   }
   return Math.round(seconds * 10) / 10;
  }

  private toSessionData(items: HawkSession[]): SessionData[]  {
    return items.map(session => {
      return {
        date: session.timestamp,
        id: session.id,
        signalStrength: session.signalStrength,
        batteryPercent: session.batteryPercent,
        temperature: session.temperature,
        moisture1Inch: session.moisture1Inch,
        moisture3Inch: session.moisture3Inch,
        moisture5Inch: session.moisture5Inch,
        restartCount: session.restartCount,
        retryCount: session.retryCount
      } as SessionData;
    });
  }
}

@Component({
  selector: 'confirmation-dialog',
  templateUrl: 'confirmation-dialog.html'
})

export class SessionConfirmationDialog {
  constructor(
    public dialogRef: MatDialogRef<SessionConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick() {
    this.dialogRef.close();
  }
}

