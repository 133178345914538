import { Injectable } from "@angular/core";
import { ApiEndpointsService, ApiService } from "../../api-endpoints.service";
import { environment } from "../../../../../environments/environment";
import { ApiHttpService } from "../../api-http.service";


const urlStage = {
    'dev': 'sla418vcr0',
    'qa': 'qxbw98ibz3',
    'prod': 'fefdu27dfk'
};
/**
 * Reference: https://betterprogramming.pub/angular-api-calls-the-right-way-264198bf2c64
 */
@Injectable() 
export class AlertsEndpointsService extends ApiEndpointsService{


    constructor(){
        super(`https://${urlStage[environment.stage]}.execute-api.${environment.region}.amazonaws.com/${environment.stage}/v3`);
    }

    /**
     * Serverless function: GetAlertActions
     * @returns The actions for alerts.
     */
    public getAlertActions() : string {
        return this.createUrl(`alerts/actions`)
    }

    public getAlerts(clientId:string) {
        return this.createUrl(`events/alerts?device=${clientId}`)
    }
}

@Injectable() 
export class AlertsApiService extends ApiService {
    constructor(
        private alertsEndpointService: AlertsEndpointsService,
        private apiHttpService: ApiHttpService){
        super();
        
    }
    public async getAlertActions() : Promise<any> {
        const url = this.alertsEndpointService.getAlertActions();
        const res = await this.apiHttpService.get(url, await this.buildAuthHeaders());
        const ares = res['alertActions'];
        return ares;
    }

    public async getAlerts(clientId:string) : Promise<any> {
        const url = this.alertsEndpointService.getAlerts(clientId);
        const res = await this.apiHttpService.get(url, await this.buildAuthHeaders());
        const ares = res;
        return ares['items'];
    }

}

