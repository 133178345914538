import { Component, Input, SimpleChanges, Inject, ViewChild } from '@angular/core';
import { Injectable } from '@angular/core';

import { User } from '../models/user';

import { GetUser } from '../models/lambda-functions/get-user';

import { ApiService } from './api.service';

import { DeleteUser } from '../models/lambda-functions/delete-user';
import { NotificationService } from './notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';

interface UserDialogData {
  username: string;
  confirm: boolean;
}

@Injectable()
export class UserService {

  constructor(
    private apiService: ApiService,
    private notificationService: NotificationService,
    private dialog: MatDialog ) {}


  async getUser(username: string, federatedIdentity: string = null): Promise<User> {
    const getUser = new GetUser({username: username, federatedIdentity: federatedIdentity});
    const response = await this.apiService.invokeAsPromise(getUser);
    if (response.statusCode !== 200) {
      return response.body;
    }
    const user = response.body;
    user.devices = user.devices.map(device => {
      device.username = user.username;
      return device;
    });
    return user;
  }


  async deleteUser(email: string) {
    const deleteUser= new DeleteUser({
      "username": email
    });
    //console.log("deleteUser.email=" + email);

    try {
       const response = await this.apiService.invokeAsPromise(deleteUser);

       //console.log("response.statusCode="+ response.statusCode);
       //console.log("response.body.status="+ response.body.status);

       if (response.statusCode !== 200) {
          console.log("Delete Response: " + response.body);
          return false;
       } else {
         return true;
       }
    } catch (error) {
      console.log(error);
      throw("Unable to delete user properly");
    }

  }

  async deleteUserHandler(email: string) {
    try {
     // email = "thubeny@moen.com";
     // console.log("deleteUserHandler.email= " + email);

      const response = await this.deleteUser(email);

      //  ClientStorageService.remove(ClientStorageKey.ClientId);
    //  this.router.navigate([RouteMap.devices.absolute]);

      if (response === true) {
         this.notificationService.show('User deleted successfully');
      } else {
        this.notificationService.show('Unable to delete user properly');
      }
    } catch (error) {
      console.log(error);
      this.notificationService.show('Unable to delete user properly');
    }

  }

  openDeleteDialog(email: string) {
    const dialog = this.dialog.open(UserConfirmationDialog, {
      width: '500px',
      data: { username: email, confirm: false }
    });

    dialog.afterClosed().subscribe(async result => {
      if (result) {
         // console.log("going to delete");
          await this.deleteUserHandler(email);
          window.location.reload();
      } /*else {
        console.log("not going to delete");
      }*/
    });
  }
}


@Component({
  selector: 'user-confirmation-dialog',
  templateUrl: './user-confirmation-dialog.html'
})
export class UserConfirmationDialog {
  constructor(
    public dialogRef: MatDialogRef<UserConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: UserDialogData
  ) {}

  onNoClick() {
    this.dialogRef.close();
  }
}
