import {SoilSensor} from './soil-sensor';

export class AuditStatusValue {
  public constructor(init?: Partial<AuditStatusValue>) {
    Object.assign(this, init);
  }
  displayValue: string;
  value: string;
}

export class SoilLogicValue {
  public constructor(init?: Partial<SoilLogicValue>) {
    Object.assign(this, init);
  }
  displayValue: string;
  value: string;
}

export class Zone {
  public get AuditStatusValues() {
    return Zone._AuditStatusValues;
  }

  public get SoilLogicValues() {
    return Zone._SoilLogicValues;
  }

  private static readonly _AuditStatusValues = [
    new AuditStatusValue({ value: 'unknown', displayValue: 'Unknown'}),
    new AuditStatusValue({ value: 'normal', displayValue: 'Normal'}),
    new AuditStatusValue({ value: 'noaudit', displayValue: 'No Audit'}),
    new AuditStatusValue({ value: 'fault', displayValue: 'Fault'})
  ];
  private static readonly _SoilLogicValues= [
    new SoilLogicValue({ value: 'any', displayValue: 'Any'}),
    new SoilLogicValue({ value: 'all', displayValue: 'All'})
  ];

  zoneID: string;
  name: string;
  enabled: boolean;
  auditStatus: string = Zone._AuditStatusValues[0].value;
  auditEnable: boolean;
  flowMaxThresh: number;
  flowMinThresh: number;
  skipOnRainSensor: boolean;
  skipReason: string = "Why skip?";
  skipReasonStartTime: string;
  skipReasonStopTime: string;
  soakRun: number;
  soakDelay: number;
  soilLogic: string = Zone._SoilLogicValues[0].value;
  soilSensors: SoilSensor[] = [];
}

