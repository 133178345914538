import { Injectable } from '@angular/core';

export enum ClientStorageValueType {
  Object,
  String,
  Number
}

export enum ClientStorageKey {
  LastRoute,
  IdToken,
  UserProfile,
  ExpiresAt,
  AccessToken,
  FirmwareReminders,
  User,
  ClientId,
  QueryType,
  Query,
  FirmwareSearch,
  PreferCelsius,
  ExistingDevice
}

@Injectable()
export class ClientStorageService {
  // TypeScript's enums are number based so we need to define the real values here
  private static getKey(key: ClientStorageKey): string {
    switch (key) {
      case ClientStorageKey.IdToken:
        return 'id_token';
      case ClientStorageKey.LastRoute:
        return 'last_route';
      case ClientStorageKey.UserProfile:
        return 'user_profile';
      case ClientStorageKey.ExpiresAt:
        return 'expires_at';
      case ClientStorageKey.AccessToken:
        return 'access_token';
      case ClientStorageKey.FirmwareReminders:
        return 'firmware_reminders';
      case ClientStorageKey.ClientId:
        return 'client_id';
      case ClientStorageKey.QueryType:
        return 'query_type';
      case ClientStorageKey.Query:
        return 'query';
      case ClientStorageKey.FirmwareSearch:
        return 'firmware_search';
      case ClientStorageKey.PreferCelsius:
        return 'prefer_celsius';
      case ClientStorageKey.ExistingDevice:
        return 'existing_device';
    }
  }

  static get(key: ClientStorageKey, type: ClientStorageValueType = ClientStorageValueType.Object): any {
    const strKey = ClientStorageService.getKey(key);
    var value = localStorage.getItem(strKey);

    if (strKey === ClientStorageService.getKey(ClientStorageKey.ExistingDevice)
       || strKey === ClientStorageService.getKey(ClientStorageKey.Query)
       || strKey === ClientStorageService.getKey(ClientStorageKey.QueryType)
       || strKey === ClientStorageService.getKey(ClientStorageKey.ClientId)
      ) {
       const value2 = sessionStorage.getItem(strKey);
       if (value2 != null) {
         value = value2;
       } else {
         console.log("not found in sessionStorage, using localStorage");
       }
    } 

    switch (type) {
      case ClientStorageValueType.Object:
        return JSON.parse(value);
      case ClientStorageValueType.Number:
        return parseFloat(value);
      case ClientStorageValueType.String:
        return value;
    }
  }

  static put(key: ClientStorageKey, value: any|number|string) {
    const strKey = ClientStorageService.getKey(key);

    if (strKey === ClientStorageService.getKey(ClientStorageKey.ExistingDevice)
       || strKey === ClientStorageService.getKey(ClientStorageKey.Query)
       || strKey === ClientStorageService.getKey(ClientStorageKey.QueryType)
       || strKey === ClientStorageService.getKey(ClientStorageKey.ClientId)
    ) {
      switch (typeof value) {
        case 'object':
          sessionStorage.setItem(strKey, JSON.stringify(value));
          break;
        case 'number':
          sessionStorage.setItem(strKey, `${value}`);
          break;
        case 'string':
          sessionStorage.setItem(strKey, value);
          break;
      }
    } 
    switch (typeof value) {
      case 'object':
        localStorage.setItem(strKey, JSON.stringify(value));
        break;
      case 'number':
        localStorage.setItem(strKey, `${value}`);
        break;
      case 'string':
        localStorage.setItem(strKey, value);
        break;
    }
  }

  static remove(key: ClientStorageKey) {
    localStorage.removeItem(ClientStorageService.getKey(key));
    sessionStorage.removeItem(ClientStorageService.getKey(key));
      
  }
}
