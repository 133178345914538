import {Component, OnInit, ChangeDetectorRef, ViewChild, HostListener, SimpleChanges} from '@angular/core';
import {Device} from '../../models/device';
import { NotificationService } from '../../services/notification.service';
import {DeviceService} from '../../services/device.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Shadow} from '../../models/shadow';
import {PubSub} from 'aws-amplify';
import {ClientStorageService, ClientStorageKey, ClientStorageValueType} from '../../services/client-storage.service';
import {EventsTableComponent} from '../../components/events-table/events-table.component';
import {DeveloperComponent} from '../developer/developer.component';
import {format} from 'url';
import {UsageTableComponent} from '../../components/usage-table/usage-table.component';
import {AuthService} from '../../services/auth.service';
import { DomSanitizer } from '@angular/platform-browser'
import { ViewEncapsulation } from '@angular/core'
import { EnvironmentService} from '../../services/environment.service';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.css'],

  // encapsulation none allows for resizing of the mat-tab widths.
  encapsulation: ViewEncapsulation.None
})
export class DeviceComponent implements OnInit {

  constructor(
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private environment: EnvironmentService,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    private sanitizer: DomSanitizer) {
  }

  @ViewChild(EventsTableComponent) eventsTable: EventsTableComponent;
  @ViewChild(DeveloperComponent) developerComponent: DeveloperComponent;
  @ViewChild(UsageTableComponent) usageComponent: UsageTableComponent;
  isSticky = false;
  isLoadingDevice = false;
  isLoadingShadow = false;
  device: Device;
  shadow: Shadow;
  shadowString: String;
  clientId: string;

  settings = {
    dropEnabled: false,
    crockMonitorOn: false
  };

// tslint:disable-next-line:max-line-length
  generalKeys = ['firmwareVersion', 'connected', 'lastConnect', 'wifiRssi', 'command', 'commandSrc', 'beeperVolume', 'beeperPiezoEnable', 'beeperSirenEnable', 'beeperEnable', 'sensorDisable'];
  displayKeys = ['dispBrightness', 'dispInactivityTimeout', 'dispUnits', 'dispLanguage', 'dispUtcOffset', 'dispQuickPresetIds'];
  occupancyKeys = ['occupancy'];
  handleKeys = ['handleFW', 'handleReverse', 'spoutInactivtyTimeout'];
  sensorKeys = ['latchFW', 'ledBrightness'];
  gestureSensorKeys = ['gestureFW'];
  powerKeys = ['powerSource', 'batteryPercentage', 'batterySavingLevel'];
  outletsKeys = ['outletTotal', 'outletNumSimul', 'outletTempC', 'outletIcons'];
  valveKeys = ['valveTotal', 'state', 'command', 'commandSrc', 'presetID', 'volume', 'flowRate', 'temperature', 'purge', 'isFreezing', 'trickleFlowRate',
    'learnedMinTemp', 'learnedMaxTemp', 'defaultTemp', 'defaultFlowRate', 'maxFlowRate', 'purgeTimeout', 'handleTimeout', 'sensorTimeout',
    'voiceTimeout', 'safetyLimitTemp', 'safetyModeEnabled', 'childLimitTemp', 'childModeEnabled', 'systemSafeTemp', 'timeRemaining', 'timerExpired',
    'assemblyAirTemp', 'userCalibrated', 'reverseHotCold'];
  temperatureKeys = ['assemblyAirTemp', 'safetyLimitTemp', 'childLimitTemp', 'defaultTemp', 'temperature', 'learnedMinTemp', 'learnedMaxTemp'];
  nabooCrockKeys = [ 'crockDiameterMM', 'crockBackup', 'crockBckTst', 'crockState', 'crockTofHeight'];
  nabooEnvKeys = ['tempLowThreshold', 'tempHighThreshold', 'humidLowThreshold', 'humidHighThreshold'];
  nabooSensorKeys = ['crockTofDistance'];
  nabooDropletKeys = ['level', 'trend', 'floodRisk', 'primaryState', 'backupState'];
  nabooAlertKeys = ['alerts'];
  isDeveloper = false;
  deviceType = '';
  userAccount: any = null;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 64;
  }

  async ngOnInit() {
    // Working around https://github.com/angular/material2/issues/5593
    this.changeDetector.detectChanges();
    try {
      this.isDeveloper = await this.authService.hasRole(['DEVELOPER']);
    } catch(err) {
      console.error("Auth Error:", err);
      this.router.navigate(['/devices']);
      throw err;
    }
    const existingDevice = ClientStorageService.get(ClientStorageKey.ExistingDevice, ClientStorageValueType.Object);
    this.clientId = this.route.snapshot.queryParams.clientId;
    this.deviceType = this.route.snapshot.queryParams.deviceType;

    if (this.clientId) {
      this.getDevice();
    } else if (existingDevice) {
      this.clientId = existingDevice.clientId;
      this.deviceType = existingDevice.deviceType;
      this.getDevice();
    } else {
      this.router.navigate(['/devices']);
    }
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnChanges(changes: SimpleChanges) {
    if (changes.device.currentValue) {
    }
  }

  async refreshDevice() {
    await this.updateDevice();
    this.syncDevice();
  }

  private syncDevice() {
    const shadow = {
      state: {
        desired: {
          command: 'upload',
          commandSrc: 'dashbd'
        }
      }
    };
    PubSub.publish(this.awsTopic(this.clientId, 'update'), shadow);
  }

  private async updateDevice() {
    this.isLoadingShadow = true;
    const storedDevice = {
      clientId: this.clientId,
      deviceType: this.deviceType
    };
    ClientStorageService.put(ClientStorageKey.ExistingDevice, storedDevice);

    try {
      const device = await this.deviceService.getDeviceByClientId(this.clientId);
      this.userAccount = device.user;
      this.device = device;
      this.isLoadingDevice = false;
      this.isLoadingShadow = false;
    } catch (error) {
      console.log(error);
    }
  }

  private getDevice() {
    this.updateDevice();
  }

  private awsTopic(deviceId, topic): string {
    return `$aws/things/${deviceId}/shadow/${topic}`;
  }
}
