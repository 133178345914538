import { LambdaFunction } from '../lambda-function';

export class DeleteSessionData implements LambdaFunction {
  functionName: string;
  payload: object;

  constructor(payload: object) {
    this.functionName = '';
    this.payload = payload;
  }
}
