import { LambdaFunction } from './../lambda-function';
import { environment } from '../../../environments/environment';

export class AddDeviceToGroup implements LambdaFunction {
  functionName: string;
  payload: object;

  constructor(payload: object) {
    this.functionName = `device-api-${environment.stage}-update`;
    this.payload = payload;
  }
}
