export class Shadow {
  firmwareVersion: string;
  connected: boolean;
  lastConnect: number;
  wifiRssi: number;
  command: string;
  commandSrc: string;
  beeperVolume: number;
  beeperEnable: boolean;
  sensorDisable: number;
  dispBrightness: number;
  dispInactivityTimeout: number;
  dispUnits: string;
  dispLanguage: string;
  dispUtcOffset: string;
  dispQuickPresetIDs: number[];
  occupancy: number;
  handleFW: string;
  handleReverse: boolean;
  spoutInactivityTimeout: number;
  latchFW: string;
  ledBrightness: number;
  powerSource: string;
  batteryLifeRemaining: number;
  batteryPercentage: number;
  batterySavingLevel: string;
  outletTotal: number;
  outletNumSimul: number;
  outletTempC: number;
  outletIcons: string[];
  outletRunState: boolean[];
  valveTotal: number;
  state: State;
  presetID: string;
  volume: number;
  flowRate: number;
  temperature: number;
  purge: number;
  freezeEnable: boolean;
  isFreezing: boolean;
  trickleFlowRate: number;
  learnedMinTemp: number;
  learnedMaxTemp: number;
  defaultTemp: string;
  defaultFlowRate: number;
  maxFlowRate: number;
  purgeTimeout: number;
  handleTimeout: number;
  sensorTimeout: number;
  voiceTimeout: number;
  safetyLimitTemp: number;
  safetyModeEnabled: boolean;
  childLimitTemp: number;
  childModeEnabled: boolean;
  systemSafeTemp: number;
  timeRemaining: number;
  timerExpired: boolean;
  assemblyAirTemp: number;
  userCalibrated: boolean;
  reverseHotCold: boolean;
  wifiNoPoll: boolean;
  devDevice: boolean;
  gestureMode: string;
  // new additions for naboo
  beeperPiezoEnable: boolean;
  beeperSirenEnable: boolean;
  crockDiameterMM: number;
  crockBackup: string;
  crockBckTst: string;
  crockState: string;
  crockTofDistance: number;
  crockStream: boolean;
  crockTofHeight: number;
  droplet: string[];
  tempLowThreshold: number;
  tempHighThreshold: number;
  humidLowThreshold: number;
  humidHighThreshold: number;
  crockCommand: string;
  crockCommandSrc: string;
  alerts: object;
}

export enum State {
  Idle = 'idle',
  Preheating = 'preheating',
  Purging = 'purging',
  Purged = 'purged',
  Dispensing = 'dispensing',
  Running = 'running',
  Faulted = 'faulted',
  Offline = 'offline'
}

export enum ShadowCommand {
  Trickle = 'trickle',
  FlowCalibration = 'flow_cal',
  Run = 'run',
  Stop = 'stop',
  Dispense = 'dispense',
  Preheat = 'preheat',
  Upload = 'upload',
  Reboot = 'reboot',
  ForceOff = 'force_off',
  Test = 'test',
  Locate = 'locate',
  Scheduled = 'scheduled'
}

export enum ShadowCommandNaboo {
  Reset = 'reset',
  ResetBackup = 'rst_backup',
  ResetPrimary = 'rst_primary'
}

export enum ShadowCommandSource {
  src = 'dashbd'
}
