import { LambdaFunction } from '../lambda-function';
import { environment } from '../../../environments/environment';

export class ListDevices implements LambdaFunction {
  functionName: string;
  payload: object;

  constructor(payload: object) {
    this.functionName = `device-api-${environment.stage}-list`;
    this.payload = payload;
  }
}

export class UpdateDeviceAttributes implements LambdaFunction {
  functionName: string;
  payload: object;

  constructor(payload: object) {
    this.functionName = `fbgpg_device_v1_update_attribute_${environment.stage}`;
    this.payload = payload;
  }
}

export class GetSkuDetails implements LambdaFunction {
  functionName: string;
  payload: object;

  constructor(payload: object) {
    this.functionName = `smartwater-app-sku-api-${environment.stage}-get`;
    this.payload = payload;
  }
}