import { Component, OnInit, ViewContainerRef, HostListener } from '@angular/core';
//import { MatTableDataSource, Sort } from '@angular/material';
import { DeviceService } from '../../services/device.service';
import { NotificationService } from '../../services/notification.service';
import { DialogService } from '../../services/dialog.service';
import { RouteMap } from '../../route-map';
import { Router } from '@angular/router';
import { Device } from '../../models/device';
import { ClientStorageKey, ClientStorageService, ClientStorageValueType } from '../../services/client-storage.service';

//import * as firmwaresJson from '../../services/mockdata/firmwares.json';
import { GroupService } from '../../services/group.service';
import { Group } from '../../models/group';
import { User } from '../../models/user';
import { FirmwareService } from '../../services/firmware.service';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';

interface Search {
  deviceType: string;
  group: string;
}

@Component({
  selector: 'app-firmware',
  templateUrl: './firmware.component.html',
  styleUrls: ['./firmware.component.css']
})
export class FirmwareComponent implements OnInit {
  displayedColumns: string[] = [
    'select',
    'clientId',
    'model',
    'firmwareVersion',
    'email',
    'nickname',
    'offline',
    'action'
  ];
  isSticky = false;
  isLoadingDevices = false;
  deviceTypes: string[];
  firmwareVersions: string[];
  dataSource: MatTableDataSource<Device> = new MatTableDataSource([]);
  RouteMap = RouteMap;
  selectedDevices: string[] = [];
  renderedDevices: string[] = [];
  groups: Group[];
  conditions: string[] = ['=', '!=', '<', '>', '<=', '>='];
  search: Search = {
    deviceType: 'VAK',
    group: '',
  };
  groupUpgradeFirmware = '';
  fleetCondition = '';
  fleetSearchVersion = '';
  fleetDesiredVersion = '';
  fleetSearchLimit = '';

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 64;
  }

  constructor(
    private deviceService: DeviceService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private viewContainerRef: ViewContainerRef,
    private groupService: GroupService,
    private firmwareService: FirmwareService,
    private router: Router
  ) { }

  ngOnInit() {
    this.deviceTypes = this.deviceService.deviceTypes;
    this.getFirmwareVersions(this.search.deviceType);
    this.getGroups();
  }

  sortData(sort: Sort) {
    if (!sort.active) {
      return;
    }

    const tempData = this.dataSource.data;
    const isAsc = sort.direction === 'asc';
    this.dataSource.data = tempData.sort((a, b) => {
      return this.safeSort(sort.active, a, b, isAsc);
    });
  }

  safeSort(column: string, a: any, b: any, isAsc: boolean) {
    if (a && b) {
      if (column === 'clientId' && a.clientId && b.clientId) {
        return this.compare(a.clientId, b.clientId, isAsc);
      } else if (column === 'model' && a.model && b.model) {
        return this.compare(a.model, b.model, isAsc);
      } else if (column === 'firmwareVersion' && a.firmwareVersion && b.firmwareVersion) {
        return this.compare(a.firmwareVersion, b.firmwareVersion, isAsc);
      } else if (column === 'nickname' && a.nickname && b.nickname) {
        return this.compare(a.nickname, b.nickname, isAsc);
      } else if (column === 'email' && a.user && a.user.username && b.user && b.user.username) {
        return this.compare(a.user.username, b.user.username, isAsc);
      } else if (column === 'home' && a.location && a.location.nickname && b.location && b.location.nickname) {
        return this.compare(a.location.nickname, b.location.nickname, isAsc);
      } else if (column === 'room' && a.room && a.room.nickname && b.room && b.room.nickname) {
        return this.compare(a.room.nickname, b.room.nickname, isAsc);
      } else if (column === 'offline' && (a.connected !== undefined || a.connected !== null)
        && (b.connected !== undefined || b.connected !== null)) {
        return this.compare(a.connected.toString(), b.connected.toString(), isAsc);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  compare(a: number | String, b: number | String, isAsc: boolean) {
    if (a && b) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }

  async getFirmwareVersions(deviceTypeId: string) {
    try {
      const firmwares = await this.firmwareService.getFirmwaresByDevice(deviceTypeId);
      this.firmwareVersions = firmwares;
      if ( this.firmwareVersions.includes("None")) {
        this.firmwareVersions = this.firmwareVersions.filter(item => item !== 'None');
      }
    } catch (error) {
      this.notificationService.show('There was an error loading firmware versions');
    }
  }

  // getLatestFirmwareVersion(deviceTypeId: string): void {
  //   this.firmwareService.getLatestFirmware(deviceTypeId)
  //   .then(data => {
  //     this.latestFirmwareVersion = data;
  //   })
  //   .catch(() => {
  //     this.notificationService.show('There was an error loading the latest firmware version.');
  //   });
  // }

  // setLatestFirmwareVersion(): void {
  //   this.firmwareService.setLatestFirmware(this.selectedDeviceType, this.selectedLatestFirmwareVersion)
  //   .then(() => {
  //     this.notificationService.show(`Successfully set latest firmware version to ${this.selectedLatestFirmwareVersion}!`);
  //     this.getLatestFirmwareVersion(this.selectedDeviceType);
  //   })
  //   .catch(() => {
  //     this.notificationService.show('There was an error when setting the latest firmware version, please try again later.');
  //   });
  // }

  getGroups() {
    this.groupService.listGroups()
      .then(groups => {
        const consumer = {
          createdAt: 0,
          deviceType: '',
          groupId: '',
          nickname: 'Consumer'
        };
        groups.unshift(consumer);
        this.groups = groups;
      });
  }

  async getDevicesByGroup() {
    this.isLoadingDevices = true;
    try {
      const devices = await this.deviceService.listDevicesByGroup([], this.search.deviceType, this.search.group);
      this.renderedDevices = devices.map(device => device.clientId);
      this.dataSource.data = devices;
      this.selectedDevices = [];
      this.isLoadingDevices = false;
    } catch (error) {
      this.notificationService.show('There was an error loading devices.');
    }
  }

  selectDevice(device) {
    if (this.selectedDevices.includes(device)) {
      const index = this.selectedDevices.indexOf(device);
      this.selectedDevices.splice(index, 1);
    } else {
      this.selectedDevices.push(device);
    }
  }

  setSelectedDevices() {
    if (this.selectedDevices.length === this.renderedDevices.length) {
      this.selectedDevices = [];
    } else {
      this.selectedDevices = this.renderedDevices.slice();
    }
  }

  goToDevice(device) {
    this.router.navigate([RouteMap.device.absolute], {queryParams: {clientId: device.clientId}});
  }

  updateFirmware(): void {
    const confirmMessage = `<p>You're about to update <strong>${this.selectedDevices.length}</strong> device(s)
      to firmware version <strong>${this.groupUpgradeFirmware}</strong></p>
      <p>Do you want to continue?</p>`;
    this.dialogService.confirm('Confirm Firmware Upgrade', confirmMessage, this.viewContainerRef, 'Continue')
    .subscribe(confirmed => {
      if (!confirmed) { return; }

      this.firmwareService.upgradeDevices(this.search.deviceType, this.selectedDevices, this.groupUpgradeFirmware)
      .then(() => {
        this.selectedDevices = [];
        this.groupUpgradeFirmware = '';
        this.notificationService.show('Firmware upgrade started.');
      });
    });
  }

  fleetUpdateFirmware() {
    const engineeringBuildSuffix = 'ENG';
    if (this.fleetDesiredVersion.includes(engineeringBuildSuffix) ||
      this.fleetDesiredVersion.includes(engineeringBuildSuffix.toLowerCase())) {
      const confirmMessage = `Engineering builds are not available for fleet deployment.`;
      this.dialogService.cancel('Illegal Action', confirmMessage, this.viewContainerRef);
    } else {
      this.deviceService.listDevicesByFirmware([], this.search.deviceType, this.fleetSearchVersion, this.fleetCondition, this.fleetSearchLimit)
        .then(devices => {
          console.log(devices);
          let count = devices.length;

          const confirmMessage = `<p>You're about to update <strong>${this.fleetSearchLimit ? this.fleetSearchLimit : count}</strong> devices that are ${this.fleetCondition} firmware version ${this.fleetSearchVersion}</p><p>Do you want to continue?</p>`;
          this.dialogService.confirm('Confirm Firmware Upgrade', confirmMessage, this.viewContainerRef, 'Continue')
            .subscribe(confirmed => {
              if (!confirmed) { return; }

              this.firmwareService.doUpgradeVersion(this.search.deviceType, this.fleetSearchVersion, this.fleetDesiredVersion, this.fleetCondition, this.fleetSearchLimit)
                .then(response => {
                  console.log(response);
                  this.notificationService.show('Firmware upgrade started.');
                });
            });
        });
    }
  }
}
