import { Injectable } from "@angular/core";
import { ApiEndpointsService, ApiService } from "../../api-endpoints.service";
import { environment } from "../../../../../environments/environment";
import { ApiHttpService } from "../../api-http.service";


const urlStage = {
    'dev': '9gfv6gri38',
    'qa': 'beylela0q0',
    'prod': 'bouqbyq990'
};
/**
 * Reference: https://betterprogramming.pub/angular-api-calls-the-right-way-264198bf2c64
 */
@Injectable() 
export class DeviceEndpointsService extends ApiEndpointsService{


    constructor(){
        super(`https://${urlStage[environment.stage]}.execute-api.${environment.region}.amazonaws.com/${environment.stage}`);
    }


    /**
     * Serverless function: fbgpg_device_v1_sku_detail_get
     * @param sku
     * @returns The url for the call.
     */
    public getSku(sku:string) : string {
        return this.createUrl(`sku/get/${sku}`)
    }

    /**
     * Serverless function: fbgpg_device_v1_skus_detail_bulkUpload
     * @returns The url for the call.
     */
    public bulkUpload() : string {
        return this.createUrl(`skus/bulkUpload`)
    }

    /**
     * Serverless function: fbgpg_device_v1_skus_detail_get
     * @returns The url for the call.
     */
    public getSkus() : string {
        return this.createUrl(`skus/get`)
    }
    /**
     * Serverless function: fbgpg_device_v1_sku_detail_put
     * @returns The url for the call.
     */
    public putSku() : string {
        return this.createUrl(`sku/put`)
    }
}

@Injectable() 
export class DeviceApiService extends ApiService { 

    constructor(
        private deviceEndpointService: DeviceEndpointsService,
        private apiHttpService: ApiHttpService){
        super();
    }

    public async getSku(sku: string) : Promise<Sku>{
        const url = this.deviceEndpointService.getSku(sku);
        const res = await this.apiHttpService.get(url, await this.buildAuthHeaders());
        return res[0];
    }

    public async getSkus() : Promise<Sku[]> { 
        const url = this.deviceEndpointService.getSkus();
        const res = await this.apiHttpService.get(url, await this.buildAuthHeaders());
        return res[0];
    }
    public async bulkUpload(skus: Sku[]) {
        const url = this.deviceEndpointService.bulkUpload();
        const res = await this.apiHttpService.post(url, skus, await this.buildAuthHeaders());
        return res;
    }
    public async putSku(sku: Sku) {
        const url = this.deviceEndpointService.putSku();
        const res = await this.apiHttpService.put(url, sku, await this.buildAuthHeaders());
        return res;
    }
}

class Sku {
    sku: string;
    description: string;
    deviceType: string;
}