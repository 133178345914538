import {Component, OnInit, Input} from '@angular/core';
import {DeviceService} from '../../services/device.service';
import {FirmwareService} from '../../services/firmware.service';
import {NotificationService} from '../../services/notification.service';
import {EventService} from '../../services/event.service';
import {GroupService} from '../../services/group.service';
import {Group} from '../../models/group';
import {MatTableDataSource} from '@angular/material/table';
import {Article} from '../../models/article';

import {stringify} from 'querystring';
import {$} from 'protractor';
import { csvToJson } from 'convert-csv-to-json'
import { DeviceApiService } from '../../core/services/endpoints/v1/device-endpoints.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  stableFirmwareVersion: string;
  firmwareVersion: string;
  firmwareVersions: string[] = [];
  deviceType = 'VAK';
  deviceTypes: string[] = [];
  errorId: any = '';
  articleDataSource: MatTableDataSource<Article> = new MatTableDataSource([]);
  articleColumns = ['eventId', 'articleId', 'sku', 'name', 'url', 'delete'];
  groupColumns: string[] = ['deviceType', 'nickname', 'description'];
  displayArticles = false;
  searchedArticles = [];
  retrievedArticles = [];
  isDatabaseExecuting = false;

  newGroup = '';
  groups: Group[] = [];
  groupDeviceType = 'VAK';
  groupDescription = '';

  constructor(
    private deviceService: DeviceService,
    private firmwareService: FirmwareService,
    private notificationService: NotificationService,
    private eventService: EventService,
    private groupService: GroupService,
    private deviceApiService: DeviceApiService
  ) {
    this.deviceTypes = this.deviceService.deviceTypes;
  }

  ngOnInit() {
    this.getFirmwareVersions();
    this.getStableFirmware();
    this.articleDataSource.data = [];
  }

  async onDeviceTypeChanged() {
    await Promise.all([
      this.getStableFirmware(),
      this.getFirmwareVersions()
    ]);
  }

  async getStableFirmware() {
    try {
      this.stableFirmwareVersion = await this.firmwareService.getLatestFirmware(this.deviceType);
      console.log(this.stableFirmwareVersion);
    } catch (error) {
      console.log(error);
    }
  }

  async setStableFirmware() {
    try {
      if (this.firmwareVersion === 'None') {
        this.firmwareVersion = this.firmwareVersion.toUpperCase();
      }
      await this.firmwareService.setLatestFirmware(this.deviceType, this.firmwareVersion);
      this.notificationService.show('Stable Firmware version has been set');
      this.getStableFirmware();
    } catch (error) {
      console.log(error);
    }
  }

  async getFirmwareVersions() {
    try {
      this.firmwareVersions = await this.firmwareService.getFirmwaresByDevice(this.deviceType);
    } catch (error) {
      console.log(error);
      this.firmwareVersions = [];
    }
  }

  async manageErrorCode() {
    this.searchedArticles = [];
    this.retrievedArticles = [];
    this.articleDataSource.data = [];
    try {
      if (this.errorId !== '' && !isNaN(this.errorId)) {
        this.isDatabaseExecuting = true;
        try {
          this.isDatabaseExecuting = false;
          const articles = await this.eventService.getArticlesByEventId(this.errorId, environment['isChina'] ? 'zh_CN' : undefined);
          if (articles.length > 0) {
            articles.forEach((value) => {
              const temp = Object.assign({}, value);
              this.searchedArticles.push(temp);
            });
            this.retrievedArticles = articles;
            this.articleDataSource.data = this.retrievedArticles;
            this.displayArticles = true;
          } else {
            await this.addEmptyArticle();
          }
        } catch(err) {

        } finally {
          this.isDatabaseExecuting = false;
        }
      } else if (isNaN(this.errorId)) {
        this.notificationService.show('Error code must be a number.');
      } else {
        this.notificationService.show('Please enter an error code first.');
      }
    } catch (error) {
      console.log(error);
    }
  }

  async addEmptyArticle() {
    this.retrievedArticles.push({eventId: this.errorId});
    this.articleDataSource.data = this.retrievedArticles;
    this.displayArticles = true;
  }

  async updateArticles() {
    await this.scrubEventIds();
    const updates = this.retrievedArticles;
    const old = this.searchedArticles;

    this.isDatabaseExecuting = true;
    for (let i = 0; i < old.length; i++) { // Updates
      if (this.articleIsValid(updates[i]) && await this.articlesAreEquivalent(old[i], updates[i]) === false) {
        await this.eventService.updateArticle(updates[i]);
      }
    }

    if (updates.length > old.length) { // Inserts
      for (let j = old.length; j < updates.length; j++) {
        if (this.articleIsValid(updates[j]) && await this.articleIsUnique(updates[j], updates)) {
          console.log('New Article');
          console.log(updates[j]);
          await this.insertNewArticle(updates[j]);
        }
      }
    }
    this.isDatabaseExecuting = false;
    await this.manageErrorCode();
  }

  async scrubEventIds() {
    this.retrievedArticles = this.retrievedArticles.map(value => {
      value.eventId = parseInt(value.eventId);
      return value;
    });
    this.articleDataSource.data = this.retrievedArticles;
  }

  articleIsValid(article) {
    return article.articleId && article.articleId !== ''
      && !isNaN(article.eventId) && article.url && article.url !== '';
  }

  async articlesAreEquivalent(old, updated) {
    const oldProps = Object.getOwnPropertyNames(old);
    const newProps = Object.getOwnPropertyNames(updated);
    if (oldProps.length !== newProps.length) {
      return false;
    }
    for (let i = 0; i < oldProps.length; i++) {
      const propName = oldProps[i];
      if (old[propName] !== updated[propName]) {
        return false;
      }
    }
    return true;
  }

  async articleIsUnique(article, list) {
    let count = 0;
    for (let i = 0; i < list.length; i++) {
      if (await this.articlesAreEquivalent(article, list[i])) {
        count++;
      }
    }
    return count <= 1;
  }

  async insertNewArticle(article) {
    try {
      await this.eventService.insertArticle(article);
      await this.eventService.linkArticleToEvent(article.articleId, article.eventId);
    } catch(err) {
      console.error(err);
    }
  }

  async deleteArticle(index) {
    const deleteArticle = this.retrievedArticles[index];
    try {
      this.isDatabaseExecuting = true;
      await this.eventService.deleteArticle(deleteArticle, deleteArticle.eventId);
      this.retrievedArticles.splice(index, 1);
      this.articleDataSource.data = this.retrievedArticles;
    } catch(err) {
      console.error(err);
    } finally{
      this.isDatabaseExecuting = false;
    }
  }

  escapeApostrophe(input) {
    if (input && input.includes('\'')) {
      return input.replace(/'+/g, '\\\'');
    } else {
      return input;
    }
  }
  async createNewGroup() {
    this.groupService.createGroup(this.newGroup, this.groupDeviceType, this.groupDescription)
      .then(() => {
        this.newGroup = '';
        this.groupDescription = '';
        this.notificationService.show('Group created successfully!');
        this.getGroups();
      });
  }

  getGroups() {
    this.groupService.listGroups()
      .then(groups => {
        this.groups = groups;
      });
  }

  ngAfterViewInit() {
    this.getGroups();
  }

  /**
   * Convert csv to json and upload to database.
   * Inserts new skus and updates existing.
   * Necessary Headers: sku, description, deviceType
   * @param event
   */
  async bulkUploadSkus(event) {
    const file = event.target.files[0];
    const fr = new FileReader();
    fr.onload = () => this.convertSkuCSVToJson(fr.result.toString());
    fr.readAsText(file);
  }

  /** Convert sku csv to json object
   * Import sku csv file and convert to json object.
  */
  async convertSkuCSVToJson(csv) {
    const lines = csv.split('\r\n');
    const result = [];
    const headers = lines[0].split(",");
    console.log(csv);
    for (let i = 1; i < lines.length; i++) {
      const obj = {};
      let currentline;
      if(lines[i].includes("\"")) {
        currentline = lines[i].split(/(?:^|,)(?=[^"]|(")?)"?(((1?)[^"]*|[^,"]*))"?(?=,|$)/).filter(c=>(c!=='' && c!=='"' && c!== undefined));
      } else {
        currentline = lines[i].split(",");
      }
      currentline = currentline.filter((v, i, a) => a.indexOf(v) === i);
      console.log(currentline);
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }
      if(obj['deviceType']) {
        result.push(obj);
      }
    }
    console.log(result);
    await this.uploadSkuJsonToDynamo(result);
    return result;
  }

  /** Upload sku json to dynamo
   * Properties: sku, description, deviceType
  */
  async uploadSkuJsonToDynamo(skuJson) {
    this.deviceApiService.bulkUpload(skuJson);
  }

  async bulkUploadEvents(event) {
    this.isDatabaseExecuting = true;
    const file = event.target.files[0];
    const filename = file.name;
    this.bulkUploadEventsFile(event);
  } // end of bulkUploadFile

  /**
   * This method should upload a csv of articles to the common table and map events to articles.
   * @param event
   */
  async bulkUploadArticles(event) {
    throw new Error("Not Implemented Exception");
  } // end of bulkUploadArticles

  async bulkUploadEventsFile(event) {
    this.isDatabaseExecuting = true;
    const file = event.target.files[0];
    const filename = file.name;
    const fr = new FileReader();

    fr.onload = () => this.processEventsCsv(fr.result.toString());
    fr.readAsText(file);

  } // end of bulkUploadEvents

  async processEventsCsv(result) {
    try {
      // let uploaditems = JSON.parse("{"+result+"}");
      const uploaditems = JSON.parse(result);
      console.log(uploaditems);
      const upload = uploaditems.items;
      try {
        await this.eventService.insertEvents(result);
      } catch (e) {
        console.error(e);
        throw e;
      }

      this.isDatabaseExecuting = false;
      this.notificationService.show(`Event database uploaded successfully!`);


    } catch (e) {
      this.notificationService.show('Error Uploading Data to Cloud. Please check your file format.');
    }
  }
}
