import { Component, Input, SimpleChanges, Inject, ViewChild } from '@angular/core';
import { Device } from '../../models/device';
import { StatisticsService } from '../../services/statistics.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ApiService } from '../../services/api.service';
import { NotificationService } from '../../services/notification.service';
//import { Session } from '../../models/session';
import { ClientStorageKey, ClientStorageService, ClientStorageValueType } from '../../services/client-storage.service';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver'

import * as columnify from 'columnify';

interface DialogData {
  clientId: string;
  confirm: boolean;
}

@Component({
  selector: 'app-statistics-table',
  styleUrls: ['./statistics-table.component.css'],
  templateUrl: './statistics-table.component.html'
})
export class StatisticsTableComponent {
  private paginator: MatPaginator;
  private sort: MatSort;
  @Input() device: Device;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  columnNames = {
    'sensorActivations': "Sensor Activations",
    'handleActivations': "Handle Activations",
    'displayActivations': "Display Activations",
    'cloudActivations': "Cloud Activations",
    'runCycles': "Run Cycles",
    'meteredCycles': "Metered Cycles",
    'purges': "Purges",
    'hotDuration': "Hot Duration",
    'coldDuration': "Cold Duration",
    'mixDuration': "Mix Duration",
    'purgeTimeouts': "Purge Timeouts",
    'handleTimeouts': "Handle Timeouts",
    'displayTimeouts': "Display Timeouts",
    'sensorTimeouts': "Sensor Timeouts",
    'dispenseActivate': "Dispense Activate",
    'voiceTimeouts': "Voice Timeouts",
    'safetyLimited': "Safety Limited",
    'meteredVolMl': "Metered Vol Ml",
    'purgedVolMl': "Purged Vol Ml",
    'totalVolMl': "Total Vol Ml",
    'levelGreenMin': "Level Green Min",
    'levelYellowMin': "Level Yellow Min",
    'levelRedMin': "Level Red Min",
    'levelAvgEmptyRate': "Level Avg Empty Rate",
    'maxAvgFlowUiPerSec': "Max Avg Flow Ui Per Sec",
    'powerMVoltage': "Power M Voltage",
    'batteryPercent': "Battery Percent",
    'powerAwakeMPercent': "Power Awake Percent",
    'powerRunTime': "Power Run Time",
    'minTempC': "Min Temp",
    'targetTempC': "Target Temp",
    'avgTempC': "Avg Temp",
    'maxTempC': "Max Temp"
  };
  displayedColumns = [
    'sensorActivations',
    'handleActivations',
    'displayActivations',
    'cloudActivations',
    'runCycles',
    'meteredCycles',
    'purges',
    'hotDuration',
    'coldDuration',
    'mixDuration',
    'purgeTimeouts',
    'handleTimeouts',
    'displayTimeouts',
    'sensorTimeouts',
    'dispenseActivate',
    'voiceTimeouts',
    'safetyLimited',
    'meteredVolMl',
    'purgedVolMl',
    'totalVolMl',
    'levelGreenMin',
    'levelYellowMin',
    'levelRedMin',
    'levelAvgEmptyRate',
    'maxAvgFlowUiPerSec',
    'avgTempC',
    'maxTempC'
  ];


  dataSource = new MatTableDataSource<any>([]);
  isLoadingStatistics = false;
  statistics = { };
  statKeys = [];
  useCelsius = false;
  hasStats = true;

  constructor (private apiService: ApiService,
               private statisticsService: StatisticsService,
               private notificationService: NotificationService,
               private datePipe: DatePipe,
               private dialog: MatDialog) {}

  ngOnInit() {
    const storedTempPref = ClientStorageService.get(ClientStorageKey.PreferCelsius, ClientStorageValueType.String);
    if (storedTempPref && storedTempPref === 'true') {
      this.useCelsius = true;
    } else if (storedTempPref && storedTempPref === 'false') {
      this.useCelsius = false;
    } else {
      ClientStorageService.put(ClientStorageKey.PreferCelsius, 'false');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.device.currentValue) {
      this.getStatisticsData();
    }
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  temperaturePreferenceUpdated() {
    if (this.useCelsius) {
      ClientStorageService.put(ClientStorageKey.PreferCelsius, 'true');
      const tempData = this.dataSource.data;
      this.dataSource.data = tempData.map(temp => {
        temp.avgTempC = this.convertTempFToC(temp.avgTempC);
        temp.minTempC = this.convertTempFToC(temp.minTempC);
        temp.maxTempC = this.convertTempFToC(temp.maxTempC);
        temp.targetTempC = this.convertTempFToC(temp.targetTempC);
        return temp;
      });
    } else {
      ClientStorageService.put(ClientStorageKey.PreferCelsius, 'false');
      const tempData = this.dataSource.data;
      this.dataSource.data = tempData.map(temp => {
        temp.avgTempC = this.convertTempCToF(temp.avgTempC);
        temp.minTempC = this.convertTempCToF(temp.minTempC);
        temp.maxTempC = this.convertTempCToF(temp.maxTempC);
        temp.targetTempC = this.convertTempCToF(temp.targetTempC);
        return temp;
      });
    }
  }

  public getStatisticsData() {
    this.isLoadingStatistics = true;
    let x =
    this.statisticsService.getStatistics(this.device.clientId)
      .then(stats => {
        if(stats) {
          if (!this.useCelsius) {
            if(stats.avgTempC) {
              stats.avgTempC = this.convertTempCToF(stats.avgTempC);
            }
            if(stats.minTempC) {
              stats.minTempC = this.convertTempCToF(stats.minTempC);
            }
            if(stats.maxTempC) {
              stats.maxTempC = this.convertTempCToF(stats.maxTempC);
            }
            if(stats.targetTempC) {
              stats.targetTempC = this.convertTempCToF(stats.targetTempC);
            }
          }
          this.statistics = stats;
          let ignoreKeys = ['duid'];
          this.statKeys = Object.keys(stats).filter(item=> !ignoreKeys.some(ignore => ignore==item));
          this.isLoadingStatistics = false;
        } else {
          this.hasStats = false;
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  public dumpUsageData() {
    const text = this.dataSource.data
      .sort((a, b) => {
        return this.compare(new Date(a.timestamp), new Date(b.timestamp), true);
      })
      .map(session => {
        session.date = this.datePipe.transform(session.timestamp * 1000, 'medium');
        return session;
      });
    let columns = columnify(text, {
      columnSplitter: '|'
    });
    const platform = window.navigator.platform;
    const windows = ['Win32', 'Win64', 'Windows', 'WinCE'];
    if (windows.includes(platform)) {
      columns = columns.replace(/\n/g, '\r\n');
    }
    const blob = new Blob([columns], {type: 'text/plain;carset=utf-8'});
    saveAs(blob, `usage_${this.device.clientId}.txt`);
  }

  public sortData = (sort: Sort) => {
    const data = this.dataSource.data;
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'date': return this.compare(new Date(a.timestamp), new Date(b.timestamp), isAsc);
        case 'source': return this.compare(a.source, b.source, isAsc);
        case 'presetId': return this.compare(a.presetId, b.presetId, isAsc);
        case 'sessionEndReason': return this.compare(a.sessionEndReason, b.sessionEndReason, isAsc);
        case 'durationMs': return this.compare(a.durationMs, b.durationMs, isAsc);
        case 'targetTempC': return this.compare(a.targetTempC, b.targetTempC, isAsc);
        case 'temperatureAvg': return this.compare(a.avgTempC, b.avgTempC, isAsc);
        case 'temperatureMin': return this.compare(a.minTempC, b.minTempC, isAsc);
        case 'temperatureMax': return this.compare(a.maxTempC, b.maxTempC, isAsc);
        case 'timeToTargetTempC': return this.compare(a.timeToTargetTemp, b.timeToTargetTemp, isAsc);
        case 'purgeVolUl': return this.compare(a.purgeVolUl, b.purgeVolUl, isAsc);
        case 'totalVolUl': return this.compare(a.totalVolUl, b.totalVolUl, isAsc);
        case 'maxFlowUlPerSec': return this.compare(a.maxFlowUlPerSec, b.maxFlowUlPerSec, isAsc);
      }
    });
  }

  public temperatureBasedName(baseName) {
    if (this.useCelsius) {
      return `${baseName} (°C)`;
    } else {
      return `${baseName} (°F)`;
    }
  }

  private compare = (a, b, isAsc) => {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private convertTempCToF(celsius) {
    return (celsius * 1.8) + 32;
  }

  private convertTempFToC(fahren) {
    return (fahren - 32) / 1.8;
  }

  private roundTemperature(input) {
    return Math.round(input * 10) / 10 ;
  }

  private formatNumber(input) {
     let output = input.toString();
     if ( output.indexOf('.') <= 0) {
       output = output + '.0';
     }
     return output;
  }

  private roundTemperatureHottestColdest(input) {
    let input2 = this.roundTemperature(input);
    let output;

    if (this.useCelsius) {
      if (input2 === 0) { return 'coldest'; } else {
        if (input2 === 100) { return 'hottest'; } else {
          output = this.formatNumber(this.roundTemperature(input));
          if (output) {
          return output;
          }  }
      }
    } else {
      if (input2 === 32) { return 'coldest'; } else {
        if (input2 === 212) { return 'hottest'; } else { output = this.formatNumber(this.roundTemperature(input));
          return output;
        }
      }
    }
  }
  displayLabel(key) {
    let propLabel : any | string = this.columnNames[key];
    if(!propLabel){
      //console.error("Missing Key Label: "+key);
      propLabel = key;
    }
    if(propLabel.toLowerCase().includes("temp")) {
      propLabel = this.temperatureBasedName(propLabel);
    }
    return propLabel;
  }

  roundMsDuration(input) {

    let seconds = input / 1000;
    if (input === undefined) {
      seconds = 0.0;
   }
    return Math.round(seconds * 10) / 10;

  }
}

