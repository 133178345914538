import { LambdaFunction } from '../lambda-function';
import { environment } from '../../../environments/environment';

export class GetStatistics implements LambdaFunction {
  functionName: string;
  payload: object;

  constructor(payload: object) {
    this.functionName = `fbgpg_statistics_v1_get_${environment.stage}`;
    this.payload = payload;
  }
}