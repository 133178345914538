import { Injectable } from '@angular/core';
import { AckAlert, RemoteAckAlert, LocalAckAlert } from '../models/lambda-functions/ack-alert';
import { SupAlert } from '../models/lambda-functions/sup-alert';
import { AlertResults } from '../models/alert-results';
import { Alert } from '../models/alert';
import { AlertData } from '../models/lambda-functions/alert-functions';
import { ApiService } from './api.service';


@Injectable()
export class AlertService {

  constructor(
    private apiService: ApiService,
  ) { }

  async acknowledgeAlert(clientId: string, alertId: string) {
    // payload  = { alertEventId: '2905', duid: '100069001' }
    const ackAlert = new AckAlert({
      pathParameters: {
        alertEventId: alertId,
        duid: clientId}
    });
    const response = await this.apiService.invokeAsPromise(ackAlert);
    if (response.statusCode !== 200) {
      console.log('Function: acknowledgeAlert');
      console.log(`Status Code:  ${response.statusCode}`);
      console.log(`Response: ${response.body}`);
      return response.body;
    }
    return response.body;
  }

  async localAck(clientId: string, alertId: string) {
    // payload  = { alertEventId: '2905', duid: '100069001' }
    const ackAlert = new LocalAckAlert({
      pathParameters: {
        alertEventId: alertId,
        duid: clientId}
    });
    const response = await this.apiService.invokeAsPromise(ackAlert);
    if (response.statusCode !== 200) {
      console.log('Function: remote acknowledgeAlert');
      console.log(`Status Code:  ${response.statusCode}`);
      console.log(`Response: ${response.body}`);
      return response.body;
    }
    return response.body;
  }

  async remoteAck(clientId: string, alertId: string) {
    // payload  = { alertEventId: '2905', duid: '100069001' }
    const ackAlert = new RemoteAckAlert({
      pathParameters: {
        alertEventId: alertId,
        duid: clientId}
    });
    const response = await this.apiService.invokeAsPromise(ackAlert);
    if (response.statusCode !== 200) {
      console.log('Function: remote acknowledgeAlert');
      console.log(`Status Code:  ${response.statusCode}`);
      console.log(`Response: ${response.body}`);
      return response.body;
    }
    return response.body;
  }

  async suppressAlert(clientId: string, alertId: string, duration: string) {
    // payload  = { alertEventId: '2905', duid: '100069001' }
    const supAlert = new SupAlert({
      pathParameters: {
        alertEventId: alertId,
        duid: clientId},
      body: {
        duration: duration
      }
    });
    const response = await this.apiService.invokeAsPromise(supAlert);
    if (response.statusCode !== 200) {
      console.log('Function: supressAlert');
      console.log(`Status Code:  ${response.statusCode}`);
      console.log(`Response: ${response.body}`);
      return response.body;
    }
    return response.body;
  }
  getAlertDataForThing(clientId: string): Promise<AlertResults> {
    const getAlertData = new AlertData({
      queryStringParameters: {
        duid: clientId,
        limit: 1001
      }
    });
    return this.apiService.invokeAsPromise(getAlertData)
      .then(response => JSON.parse(response.body));
  }

  async getNextAlertDataForThing(clientId: string, lastEvaluatedKey: string): Promise<AlertResults> {
    const getAlertData = new AlertData({
      duid: clientId,
      limit: 1001,
      lastEvaluatedKey: lastEvaluatedKey
    });
    return this.apiService.invokeAsPromise(getAlertData)
      .then(response => JSON.parse(response.body));
  }


}
