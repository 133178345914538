import { Component, ViewChild, HostListener } from '@angular/core';
//import { MatTableDataSource, MatPaginator, MatSort, MatDatepickerInputEvent, Sort } from '@angular/material';
import { Event } from '../../models/event';
import { EventService } from '../../services/event.service';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../services/notification.service';

import { DatePipe } from '@angular/common';
import * as columnify from 'columnify';
import {MatTableDataSource} from '@angular/material/table/';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import { saveAs } from 'file-saver'

interface FilterType {
  id: string;
  value: string;
}

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent {
  dataSource: MatTableDataSource<Event> = new MatTableDataSource();
  displayedColumns: string[] = ['clientId', 'date', 'id', 'severity', 'msgPrivate', 'article' ];
  thingName: string = '';
  isSticky = false;
  isLoadingEvents: boolean = false;
  info: boolean = false;
  debug: boolean = false;
  warning: boolean = false;
  error: boolean = false;
  id = '';
  message = '';
  offset = false;
  offsetAmount = 1;
  start: Date;
  end: Date;
  filter = '';
  filterType = 'all';
  modelFilter = 'vak';
  filterName = '';
  query = '';
  documentState = null;
  queryItems: string[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 64;
  }
  deviceFilterTypes: FilterType[] = [
    {id: 'vak', value: 'VAK'},
    {id: 'nab', value: 'NAB'}
  ];
  eventFilterTypes: FilterType[] = [
    {id: 'critical', value: 'Critical Only'},
    {id: 'criticalwarnings', value: 'Critical & Warnings'},
    {id: 'id', value: 'ID'}
  ];
  eventFilter = 0;
  dataSourceOriginal: Event[];

  constructor(
    private eventService: EventService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private datePipe: DatePipe) {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  search() {
    this.offset = false;
    this.offsetAmount = 1;
    this.buildQuery();
    this.getEvents();
  }

  changeFilter(event) {
    this.documentState = null;
    this.filterType = event;
    if (this.filterType === 'id') {
      this.filterName = 'Number';
    } else if (this.filterType === 'text') {
      this.filterName = 'Text';
    } else {
      this.filterName = '';
    }
  }

  changeDeviceFilter(event) {
    this.documentState = null;
    this.modelFilter = event;
  }

  dateChanged = (picker: string, event: MatDatepickerInputEvent<Date>) => {
    this.documentState = null;
    if (picker === 'start') {
      this.start = event.value;
      this.start.setHours(0, 0, 0, 0);
    } else if (picker === 'end') {
      this.end = event.value;
      this.end.setHours(23, 59, 59, 999);
    }
    if (this.start != null && this.end != null) {
      if (this.end < this.start) {
        this.start = new Date(this.end.getTime());
        this.start.setHours(0, 0, 0, 0);
      }
    }
  }

  async getEvents() {
    this.isLoadingEvents = true;
    try {
      //const events = await this.eventService.getEventsForQuery(this.query);
      let events;
      switch (this.filterType) {
        case 'all':
          if(this.documentState != null) {
            this.documentState = await this.eventService.getEventLogsDevice(this.modelFilter, this.documentState.LastEvaluatedKeys, new Date(this.start).getTime(), new Date(this.end).getTime());
          } else {
            this.documentState = await this.eventService.getEventLogsDevice(this.modelFilter, null, new Date(this.start).getTime(), new Date(this.end).getTime());
          }
          events = this.documentState.Items;
          delete this.documentState.Items;
          break;
        case 'critical':
          if(this.documentState != null) {
            this.documentState = await this.eventService.getEventLogsDeviceBySeverities(['critical'], this.modelFilter, this.documentState.LastEvaluatedKeys, new Date(this.start).getTime(), new Date(this.end).getTime());
          } else {
            this.documentState = await this.eventService.getEventLogsDeviceBySeverities(['critical'], this.modelFilter, null, new Date(this.start).getTime(), new Date(this.end).getTime());
          }
          events = this.documentState.Items;
          delete this.documentState.Items;
          break;
        case 'criticalwarnings':
          if(this.documentState != null) {
            this.documentState = await this.eventService.getEventLogsDeviceBySeverities(['critical', 'warning'], this.modelFilter, this.documentState.LastEvaluatedKeys, new Date(this.start).getTime(), new Date(this.end).getTime());
          } else {
            this.documentState = await this.eventService.getEventLogsDeviceBySeverities(['critical', 'warning'], this.modelFilter, null, new Date(this.start).getTime(), new Date(this.end).getTime());
          }
          events = this.documentState.Items;
          delete this.documentState.Items;
          break;
        case 'id':
          if(this.documentState != null) {
            this.documentState = await this.eventService.getEventLogsDeviceById(parseInt(this.filter), this.modelFilter, this.documentState.LastEvaluatedKeys, new Date(this.start).getTime(), new Date(this.end).getTime());
          } else {
            this.documentState = await this.eventService.getEventLogsDeviceById(parseInt(this.filter), this.modelFilter, null, new Date(this.start).getTime(), new Date(this.end).getTime());
          }
          events = this.documentState.Items;
          delete this.documentState.Items;
          break;
        default: {
          const msg = "Unknown option found for filter type.";
          console.error(msg);
          throw new Error(msg);
        }
      }
      if (this.offset) {
        this.dataSource.data = this.dataSource.data.concat(events);
      } else {
        this.paginator.pageIndex = 0;
        this.dataSource.data = events;
      }
      this.isLoadingEvents = false;
    } catch(error) {
      this.notificationService.show(error.message);
      this.dataSource.data = [];
      this.isLoadingEvents = false;
    }
  }

  sortData = (sort: Sort) => {
    const data = this.dataSource.data;
    if (!sort.active || sort.direction == '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a,b) => {
      let isAsc = sort.direction == 'asc';
      switch (sort.active) {
        case 'id': return this.compare(a.eventId, b.eventId, isAsc);
        case 'clientId': return this.compare(a.clientId, b.clientId, isAsc);
        case 'msgPrivate': return this.compare(a.msgPrivate, b.msgPrivate, isAsc);
        case 'date': return this.compare(new Date(a.timeStamp), new Date(b.timeStamp), isAsc);
        default: return 0;
      }
    });
  }

  compare = (a, b, isAsc) => {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  buildQuery() {
    //Reset the state of the page with respect to exclusive start keys and dynamo db on filter value changed.
    this.documentState = null;




    this.query = 'SELECT clientId, eventId, severity, msgPrivate, UNIX_TIMESTAMP(createdAt) as createdAt FROM data';
    this.queryItems = [];
    if (this.start != null) {
      this.queryItems.push(`createdAt > '${this.start.toISOString()}'`);
    }
    if (this.end != null) {
      this.queryItems.push(`createdAt < '${this.end.toISOString()}'`);
    }
    if (this.filterType === 'critical') {
      this.queryItems.push(`severity = 'Critical'`);
    } else if (this.filterType === 'criticalwarnings') {
      this.queryItems.push(`severity = 'Critical' OR severity = 'Warn'`);
    } else if (this.filterType === 'id' && this.filter) {
      this.queryItems.push(`eventId = '${this.filter}'`)
    } else if (this.filterType === 'text' && this.filter) {
      this.queryItems.push(`msgPrivate LIKE '%${this.filter}%'`);
    } else {
      this.queryItems.push(`severity = 'Critical' OR severity = 'Warn'`);
    }
    if (this.queryItems.length > 0) {
      this.query += ' WHERE ';
    }
    this.query += this.queryItems.join(' AND ');
    this.query += ' ORDER BY createdAt DESC';
    this.query += ' LIMIT 1001';

  }

  changePage(event) {
    if (event.pageIndex * event.pageSize >= event.length - event.pageSize) {
      this.offset = true;
      this.offsetAmount ++;
      this.query = this.query.replace(/ OFFSET \d+$/, '');
      this.query += ` OFFSET ${this.offsetAmount -1}000`;
      this.getEvents();
    }
  }

  eventsTextDump() {
    const text = this.dataSource.data
      .sort((a, b) => {
        return this.compare(new Date(a.createdAt), new Date(b.createdAt), true);
      })
      .map(event => {
        return {
          serialNum: event.clientId,
          date: this.datePipe.transform(parseInt(event.createdAt, 10), 'medium'),
          id: event.eventId,
          severity: event.severity,
          msgPrivate: event.msgPrivate
        };
      });
    let columns = columnify(text);
    //Replacing LF for CRLF for Windows folks not using a DEV text editor
    const platform = window.navigator.platform;
    const windowsOs = ['Win32', 'Win64', 'Windows', 'WinCE'];
    if (windowsOs.includes(platform)) {
      columns = columns.replace(/\n/g, '\r\n');
    }
    const blob = new Blob([columns], {type: 'text/plain'});
    saveAs(blob, `events_across_devices_log.txt`);
  }
}
