import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { AuthService } from '../../services/auth.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.css']
})
export class SignupFormComponent {
  @Input() successUrl: string;
  @Input() loginUrl: string;
  @Input() enableTAC: boolean;
  @Input() agreesToTAC: boolean;
  @Output() termsAndConditionsChecked = new EventEmitter();
  @ViewChild('signup') public signup: NgForm;
  submitted = false;
  user = {
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirm: '',
    email: '',
    phone: ''
  };

  constructor(private authService: AuthService,
              private router: Router,
              private notificationService: NotificationService) {
  }

  public clickOnCheckbox = ($event) => {
    $event.preventDefault();
    this.termsAndConditionsChecked.emit();
  }

  onSubmit($event) {
    $event.preventDefault();
    this.submitted = true;
  }
}
