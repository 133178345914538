export class DashboardAction {
  public name: string;
  public action: any;
  public displayName: string;

  //public inputs: PropertyDescriptor[];
  public constructor(init?: Partial<DashboardAction>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}

//export class PropertyDescriptor { }
