import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouteMap } from '../route-map';
import { Observable } from 'rxjs';
import { Device } from '../models/device';
import { API, Auth } from 'aws-amplify';
import { Lambda, Response } from 'aws-sdk';
import { LambdaFunction } from '../models/lambda-function';
import { LambdaError } from '../models/lambda-error';
import { AuthService } from '../services/auth.service';






import { ClientStorageKey, ClientStorageService, ClientStorageValueType } from './client-storage.service';
import { EnvironmentService } from './environment.service';
import { NotificationService } from './notification.service';

@Injectable()
export class ApiService {
  private stage: string;

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private environmentService: EnvironmentService,
    private authService: AuthService
  ) {
    this.stage = this.environmentService.get('stage');
  }

  private processPromise = (response: Promise<any>): Promise<any> => {
    return response
      .then(this.checkForError)
      .then(this.getJSON);
  }

  invokeAsPromise = (lambdaFunction: LambdaFunction): Promise<any> => {
    return this.processPromise(this.invoke(lambdaFunction));
  }

  private async invoke(lambdaFunction: LambdaFunction): Promise<any> {
    try {
      const credentials = await Auth.currentCredentials();
      const creds = Auth.essentialCredentials(credentials);
      let functionName = lambdaFunction.functionName;
      if (!functionName.includes('fbgpg') && !functionName.includes('smartwater-app-')) {
        functionName = 'smartwater-dashboard-' + functionName;
      }
      const lambda = new Lambda({
        region: this.environmentService.get('region'),
        apiVersion: '2015-03-31',
        credentials: creds
      });
      const params = {
        FunctionName: functionName,
        InvocationType: 'RequestResponse',
        LogType: 'None',
        Payload: JSON.stringify(lambdaFunction.payload)
        // Payload: lambdaFunction.payload
      };
      // console.log('prototype===>>', Object.prototype.toString.call(params));
      // console.log('params===>>', params);
      const r = await lambda.invoke(params).promise();
      return r;
    } catch (error) {
      // console.error('lambdaFunction===>>', lambdaFunction);
      // console.error('error===>>', error);
      this.router.navigate([RouteMap.logout.absolute]);
    }
  }

  private getJSON = (response: any): Promise<any> => {
    return JSON.parse(response['Payload']);
  }

  private getData = (response: any): Promise<any> => {
    return new Promise(resolve => {
      resolve(response.data);
    });
  }

  private checkForError = (response: Promise<any>): Promise<any> => {
    if (response['FunctionError']) {
      this.normalizeError(response);
    }
    return response;
  }

  private normalizeError = (response: any) => {
    const payload = JSON.parse(response['Payload']);
    throw new LambdaError(payload.errorType, payload.errorMessage);
  }
}
