
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { Component, OnInit, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';




@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {
  query = new FormControl();
  @Output() queryChanged: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.query.valueChanges.pipe(
      debounceTime(250),
      distinctUntilChanged(),)
      .subscribe(() => {
        this.queryChanged.emit(this.query.value);
      });
  }
}
