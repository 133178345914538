import {Shadow} from '../../models/shadow';
import {PubSub} from 'aws-amplify';
import {DashboardAction} from './dashboard-action';

export class DashboardControlActions {
  protected get clientId(): string {
    return this._clientId;
  }

  protected set clientId(value: string) {
    this._clientId = value;
  }

  _actions: DashboardAction[] = [];
  get actions(): DashboardAction[] {
    return [...this._actions];
  }

  private _clientId: string;

  protected AddAction(action: DashboardAction) {
    this._actions.push(action);
  }

  protected updateShadow(desiredShadow: Shadow) {
    const desired = {
      state: {
        desired: desiredShadow
      }
    };
    // console.log("updating shadow" + JSON.stringify(desired));
    PubSub.publish(`$aws/things/${this.clientId}/shadow/update`, desired);
  }
}
