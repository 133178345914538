import { Component, ViewEncapsulation } from '@angular/core';
import { Input, OnInit } from '@angular/core';
import { FlowSensor } from '../../../models/hyd/flow-sensor';

@Component({
  selector: 'app-flow-sensor-hyd-table',
  templateUrl: './flow-sensor-hyd-table.html',
  styleUrls: ['./flow-sensor-hyd-table.css'],

  // encapsulation none allows for resizing of the mat-tab widths.
  encapsulation: ViewEncapsulation.None
})
export class FlowSensorHydTableComponent implements OnInit {
  @Input() flowSensor: FlowSensor;

  constructor() {
  }

  ngOnInit(): void {
    console.log(this.flowSensor);
  }
}



