import { LambdaFunction } from './../lambda-function';
import { environment } from '../../../environments/environment';


export class GetSessionDataV2 implements LambdaFunction {
  functionName: string;
  payload: object;

  constructor(payload: object) {
    this.functionName = `fbgpg_usage_v1_get_by_duid_${environment.stage}`;
    this.payload = payload;
  }
}
