import { Injectable } from "@angular/core";
import { UrlBuilder } from "../../shared/classes/url-builder";
import { QueryStringParameters } from "../../shared/classes/query-string-parameters";
import { environment } from '../../../environments/environment';
import { Auth } from 'aws-amplify';
import { HttpHeaders } from '@angular/common/http';

export abstract class ApiEndpointsService{
    apiUrl:string;
    constructor(apiUrl: string) {    
        this.apiUrl = apiUrl;
    }

    protected createUrl(action:string) : string {
        const urlBuilder: UrlBuilder = new UrlBuilder(this.apiUrl, action);
        return urlBuilder.toString();
    }

    protected createUrlWithQueryParameters(action: string, queryStringHandler?:(queryStringParameters:QueryStringParameters)=> void) : string {
        const urlBuilder: UrlBuilder = new UrlBuilder(this.apiUrl, action);
        if(queryStringHandler) {
            queryStringHandler(urlBuilder.queryString);
        }
        return urlBuilder.toString();
    }

    protected createUrlWithPathVariables(action: string, pathVariables: any[] = []): string{
        let encodedPathVariablesUrl: string = '';
        for(const pathVariable of pathVariables) {
            if(pathVariable !== null) {
                encodedPathVariablesUrl += `/${encodeURIComponent(pathVariable.toString())}`;
            }
        }
        const urlBuilder: UrlBuilder = new UrlBuilder(this.apiUrl, `${action}${encodedPathVariablesUrl}`);
        return urlBuilder.toString();
    }
}

export abstract class ApiService {
    public async buildAuthHeaders() {
        const user = await Auth.currentAuthenticatedUser();
        const jwtToken = user.signInUserSession.accessToken.jwtToken;

        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwtToken}`
            })
        }
       
    }
}