import { LambdaFunction } from './../lambda-function';
import { environment } from '../../../environments/environment';

export class ListFirmware implements LambdaFunction {
  functionName: string;
  payload: object;

  constructor(payload: object) {
    this.functionName = `firmware-api-${environment.stage}-list`;
    this.payload = payload;
  }
}
